import React from 'react';
import {useTranslation} from 'react-i18next';
import {ProductCategoryNewProxy, TechMapCategoryNewProxy} from '@me-team/host/main/graphql/types';

interface RenderServiceCategoryProps {
  category: ProductCategoryNewProxy | TechMapCategoryNewProxy;
  isArchive: boolean;
  archiveColor?: string;
}

const RenderProductCategoryName: React.FC<RenderServiceCategoryProps> = ({category, isArchive, archiveColor = 'text-secondary'}) => {
  const {t} = useTranslation();

  if (!category) return null;

  const isSubCategory = !!category?.parentCategory?.name;
  const categoryName = category?.parentCategory?.name ? category?.parentCategory?.name : category?.name;
  const subCategoryName = category?.parentCategory?.name ? category?.name : '';
  const mainTextColor  = isArchive ? archiveColor : 'text-dark';
  const subTextColor  = isArchive ? archiveColor : 'text-grey';

  return  (
    <div>
      <span className={`${mainTextColor} d-block`}>
        {isSubCategory ? subCategoryName : categoryName}
      </span>
      {
        isSubCategory
          ?
          <span className={`fs-7 ${subTextColor}`}>
            {`(${categoryName})`}
          </span>
          : null
      }
      {isArchive ? <span className='ms-1 fs-7 text-grey'>{`(${t('Archive')})`}</span> : null}
    </div>
  )
};

export default RenderProductCategoryName;