/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetEmployeesByBranchIdDocument = gql`
    query GetEmployeesByBranchId($branchId: ID!, $online: Boolean!) {
  employees(branchId: $branchId, online: $online) {
    id
    name
    surname
    allowDefault
    reviewCount
  }
}
    `;

/**
 * __useGetEmployeesByBranchIdQuery__
 *
 * To run a query within a React component, call `useGetEmployeesByBranchIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesByBranchIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesByBranchIdQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      online: // value for 'online'
 *   },
 * });
 */
export function useGetEmployeesByBranchIdQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesByBranchIdQuery, GetEmployeesByBranchIdQueryVariables> & ({ variables: GetEmployeesByBranchIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesByBranchIdQuery, GetEmployeesByBranchIdQueryVariables>(GetEmployeesByBranchIdDocument, options);
      }
export function useGetEmployeesByBranchIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesByBranchIdQuery, GetEmployeesByBranchIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesByBranchIdQuery, GetEmployeesByBranchIdQueryVariables>(GetEmployeesByBranchIdDocument, options);
        }
export function useGetEmployeesByBranchIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesByBranchIdQuery, GetEmployeesByBranchIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesByBranchIdQuery, GetEmployeesByBranchIdQueryVariables>(GetEmployeesByBranchIdDocument, options);
        }
export type GetEmployeesByBranchIdQueryHookResult = ReturnType<typeof useGetEmployeesByBranchIdQuery>;
export type GetEmployeesByBranchIdLazyQueryHookResult = ReturnType<typeof useGetEmployeesByBranchIdLazyQuery>;
export type GetEmployeesByBranchIdSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesByBranchIdSuspenseQuery>;
export type GetEmployeesByBranchIdQueryResult = Apollo.QueryResult<GetEmployeesByBranchIdQuery, GetEmployeesByBranchIdQueryVariables>;
export type GetEmployeesByBranchIdQueryVariables = Types.Exact<{
  branchId: Types.Scalars['ID']['input'];
  online: Types.Scalars['Boolean']['input'];
}>;


export type GetEmployeesByBranchIdQuery = { __typename?: 'bookingQuery', employees: Array<{ __typename?: 'EmployeeBookingProxy', id: number, name: string, surname: string, allowDefault: boolean, reviewCount: number }> };
