import React from 'react';
import {OperationRow} from '../types';
import {StorageReportItemOperationProxy, StorageReportType} from '@me-team/host/main/graphql/types';
import {formatDateDMY} from '@me-team/host/src/utils/utils';

interface RenderOperationRowProps {
  operationData: StorageReportItemOperationProxy;
  reportType: StorageReportType;
  hasBorder: string;
}

const RenderOperationRow: React.FC<RenderOperationRowProps> = ({
                                                                 operationData,
                                                                 reportType,
                                                                 hasBorder
                                                               }) => {
  const dataForArrivalAndWriteOffOperations: OperationRow[] = [
    {key: "first", operationData: ""},
    {key: "transactionDate", operationData: <span>{formatDateDMY(operationData.date)}</span>},
    {key: "quantity", operationData: <span>{operationData.quantity}</span>},
    {key: "warehouseType", operationData: <span>{operationData.warehouse?.type?.name}</span>},
    {key: "warehouse", operationData: <span>{operationData.warehouse?.name}</span>},
    {
      key: "performer",
      operationData: <div>{operationData.employee?.name} {operationData.employee?.surname}</div>
    },
    {key: "last", operationData: ""},
  ]
  const dataForArrivalWriteOffOperations: OperationRow[] = [
    {key: "first", operationData: ""},
    {key: "transactionDate", operationData: <span>{formatDateDMY(operationData.date)}</span>},
    {key: "transactionType", operationData: <span>{operationData.type}</span>},
    {key: "quantity", operationData: <span>{operationData.quantity}</span>},
    {key: "warehouseType", operationData: <span>{operationData.warehouse?.type?.name}</span>},
    {key: "warehouse", operationData: <span>{operationData.warehouse?.name}</span>},
    {
      key: "performer",
      operationData: <div>{operationData.employee?.name} {operationData.employee?.surname}</div>
    },
    {key: "last", operationData: ""},
  ]
  const dataForMovementOperations: OperationRow[] = [
    {key: "transactionDate", operationData: <span>{formatDateDMY(operationData.date)}</span>},
    {key: "quantity", operationData: <span>{operationData.quantity}</span>},
    {
      key: "initialWarehouseType",
      operationData: <span>{operationData.warehouse?.type?.name}</span>
    },
    {key: "initialWarehouse", operationData: <span>{operationData.warehouse?.name}</span>},
    {
      key: "destinationWarehouseType",
      operationData: <span>{operationData.destinationWarehouse?.type?.name}</span>
    },
    {
      key: "destinationWarehouse",
      operationData: <span>{operationData.destinationWarehouse?.name}</span>
    },
    {
      key: "performer",
      operationData: <div>{operationData.employee?.name} {operationData.employee?.surname}</div>
    },
  ]

  let columns: OperationRow[] = [];
  switch (reportType) {
    case StorageReportType.Arrival:
    case StorageReportType.WriteOff:
      columns = dataForArrivalAndWriteOffOperations;
      break;
    case StorageReportType.ArrivalWriteOff:
      columns = dataForArrivalWriteOffOperations;
      break;
    case StorageReportType.Movement:
      columns = dataForMovementOperations;
      break;
    default:
      columns = [];
  }

  return (
    <>
      {columns?.map((column) => (
        <td
          key={column.key}
          className={`align-middle text-start py-6 px-2  ${column.key === 'transactionDate' ? 'ps-4' : ''} ${column.key !== 'first' && column.key !== 'last' && hasBorder}`}
        >
          {column.operationData}
        </td>
      ))}
    </>
  );
};

export default RenderOperationRow;