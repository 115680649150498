import * as Yup from 'yup';
import {TFunction} from 'i18next';
export const getTextColorAvailabilityClass = (status: string, isArchive: boolean) => {
    if (isArchive) {
        return 'text-secondary';
    }
    switch (status) {
        case 'In stock':
            return 'text-green';
        case 'Out of stock':
            return 'text-grey';
        case 'Not added':
            return 'text-dark';
        default:
            return 'text-secondary';
    }
};
export const writeOffSchema = (t: TFunction) =>
    Yup.object().shape({
        quantity: Yup.number().required(t('validation.required')),
        unit: Yup.number().required(t('validation.required')),
        writeOffReason: Yup.number().required(t('validation.required')),
    });

export const formatRemnantsForTooltip = (alternativeValue: number, alternativeUnitName: string, stockQuantity: string) => {
    return `1pc = ${alternativeValue}${alternativeUnitName} (Total: ${stockQuantity})`;
};