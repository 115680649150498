import React, {useState} from 'react'
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {ModuleInputType, ModuleProxy} from "@me-team/host/main/graphql/types";
import Form from 'react-bootstrap/Form';
import default_module_img from '@me-team/host/src/images/default_module_img.png'
import {useTranslation} from "react-i18next";
import styles from '../ModulesListStyles.module.scss'
import {ModulesTagName, SubModulesTagName} from "../../../../../utils/enums";
import {useParams} from "react-router-dom";
import ModulesService from "../../../../../services/ModulesService/ModulesService";

type OnlineBookingModuleProps = {
    modulesList: ModuleProxy[]
    updateModules: (companyId: number, updates: ModuleInputType[]) => Promise<void>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}


const OnlineBookingModule: React.FC<OnlineBookingModuleProps> = (props) => {
    const {currentCompanyId} = useParams();
    const {t: translate} = useTranslation();
    const ONLINE_BOOKING_MODULE: ModuleProxy = props.modulesList.find((module: ModuleProxy) => module.name === ModulesTagName.BOOKING);
    const LOGBOOK_MODULE: ModuleProxy = props.modulesList.find((module: ModuleProxy) => module.name === ModulesTagName.LOGBOOK);
    const PERSONAL_MODULE: ModuleProxy = props.modulesList.find((module: ModuleProxy) => module.name === ModulesTagName.PERSONNEL);
    const SERVICES_MODULE: ModuleProxy = props.modulesList.find((module: ModuleProxy) => module.name === ModulesTagName.SERVICES);
    const CLIENTS_MODULE: ModuleProxy = props.modulesList.find((module: ModuleProxy) => module.name === ModulesTagName.CLIENTS);
    const basePath : string = process.env.REACT_APP_POS_URL;
    const imageSRC : string = ONLINE_BOOKING_MODULE.image ? `${basePath}/build/images/modules/${ONLINE_BOOKING_MODULE.image}` : default_module_img;
    const [isMainSwitchDisable, setIsMainSwitchDisable] = useState(false);
    const [isChildModuleDisabled, setIsChildModuleDisabled] = useState(false);

    const onToggleOnlineBookingHandler = async () => {
        setIsMainSwitchDisable(true);
        const updates: ModuleInputType[] = [
            {id: ONLINE_BOOKING_MODULE.id, isActive: !ONLINE_BOOKING_MODULE.active}
        ];

        if (ONLINE_BOOKING_MODULE.active) {
            await props.updateModules(Number(currentCompanyId), updates);
            setIsMainSwitchDisable(false);
            return;
        }

        const modulesToCheck = [PERSONAL_MODULE, LOGBOOK_MODULE, SERVICES_MODULE, CLIENTS_MODULE];
        modulesToCheck.forEach(module => {
            if (!module.active) {
                updates.push({ id: module.id, isActive: true });
            }
        });

        const serviceProvidesModule = ModulesService.getChildModule(SERVICES_MODULE, SubModulesTagName.SERVICE_PROVIDES);
        if (!serviceProvidesModule.active) {
            updates.push({ id: serviceProvidesModule.id, isActive: true });
        }

        if (updates.length >= 2) {
            props.setIsLoading(true);
        }

        await props.updateModules(Number(currentCompanyId), updates);
        props.setIsLoading(false);
        setIsMainSwitchDisable(false);
    }

    const onToggleReviewsHandler = async (childModule: ModuleProxy) => {
        setIsChildModuleDisabled(true);
        await props.updateModules(Number(currentCompanyId), [
            { id: childModule.id, isActive: !childModule.active }
        ]);
        setIsChildModuleDisabled(false);
    }

    return (
        <div className={'border rounded p-4 mt-4'}>
            <Row>
                <Col xl={2} xxl={2} className="mb-3 mb-xl-0">
                    <div className={!ONLINE_BOOKING_MODULE.active ? 'opacity-75' : ""}>
                        <img className={`${styles.customImagesSize} d-block`} src={imageSRC} alt={ONLINE_BOOKING_MODULE.name}/>
                    </div>
                </Col>
                <Col xl={5} xxl={6} className="mb-3 mb-xl-0">
                    <div className={!ONLINE_BOOKING_MODULE.active ? 'opacity-75' : ""}>
                        <h5 className={'fw-normal mb-2 fs-24'}>{translate(ONLINE_BOOKING_MODULE.name)}</h5>
                        <p className={'mb-0 text-grey'}>{ONLINE_BOOKING_MODULE.description}</p>
                    </div>
                </Col>
                <Col xs={12} sm={6}  md={6} xl={2} xxl={2} className={'d-flex justify-content-start justify-content-sm-center d-xl-block mb-3 mb-sm-0'}>
                    <Form className={!ONLINE_BOOKING_MODULE.active ? 'opacity-75' : ""}>
                        <ul className="list-unstyled">
                            {ONLINE_BOOKING_MODULE.childModules.map((childModule: ModuleProxy, index: number) => (

                                <li
                                    className={`d-flex align-items-center ${
                                        index === ONLINE_BOOKING_MODULE.childModules.length - 1 ? '' : 'mb-3'
                                    }`}
                                    key={childModule.id}
                                >
                                    <Form.Check
                                        type="switch"
                                        label={translate(childModule.name)}
                                        checked={childModule.active}
                                        disabled={!ONLINE_BOOKING_MODULE.active || isChildModuleDisabled}
                                        className="mb-0 custom-module-switch"
                                        onChange={() => onToggleReviewsHandler(childModule)}
                                    />
                                    {!ONLINE_BOOKING_MODULE.active && (
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id={`tooltip-${childModule.id}`}>
                                                    {translate('Will become available after connecting the \"Online Booking\" module.')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className="bi bi-question-circle ms-2 text-grey cursor-pointer"></i>
                                        </OverlayTrigger>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </Form>
                </Col>
                <Col xs={12} sm={6}  md={6} xl={3} xxl={2} className={'d-flex flex-column justify-content-between align-items-center align-items-xl-start'}>
                    <Form className={'d-flex align-items-center'}>
                        <Form.Check
                            type="switch"
                            label={ONLINE_BOOKING_MODULE.active ? translate('Enabled') : translate('Disabled')}
                            checked={ONLINE_BOOKING_MODULE.active}
                            disabled={isMainSwitchDisable}
                            className="mb-0 custom-module-switch"
                            onChange={onToggleOnlineBookingHandler}
                        />
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id={`tooltip-${ONLINE_BOOKING_MODULE.id}`}>
                                    {translate('You can disable/enable the module functionality at any time without canceling your subscription.')}
                                </Tooltip>
                            }
                        >
                            <i className="bi bi-question-circle ms-2 text-grey cursor-pointer"></i>
                        </OverlayTrigger>
                    </Form>
                    <h5 className={'mt-3 mt-sm-0'}>{translate('Free')}</h5>
                </Col>
            </Row>
        </div>
    )
}

export default OnlineBookingModule