import React, {useEffect, useRef, useState} from 'react';
import {
  EmployeesOption,
  ReportsFilter,
  StorageReportTypeOption,
  StringOption,
  WarehouseOption
} from './types';
import {Button, Col, Row} from 'react-bootstrap';
import {CategoryPickerCategory, CategoryPickerComponent} from '@me-pos/category-picker';
import i18n from '@me-team/host/src/i18n';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {CategoryInterface} from '../../TechnologicalMaps/types';
import ErrorService from '../../../services/ErrorService';
import transformProductCategories from '@me-team/host/src/utils/transformProductCategories';
import {
  DateRangeCalendar,
  ReactSelect,
  Spinner,
  TextInputWithAutoComplete
} from '@services/ui-components';
import moment from 'moment';
import {
  ProductCategoryNewProxy
} from '@me-team/host/main/graphql/types';
import {useGetEmployeesByBranchIdQuery} from '@me-team/host/booking/reports/graphql/reports.hooks';
import {
  getEmployeesOptions,
  getProductParametersOptions,
  getStorageReportTypeOptions,
  getWarehousesOptions,
  mergeAndSortItems
} from './constants';
import {apolloClientBooking} from '@me-team/host/src/apollo/apollo-client';
import {
  useGetBrandsSkUsBarcodesStoragesForReportsQuery,
  useGetCategoriesForReportsQuery
} from '@me-team/host/main/reports/graphql/reports.hooks';

interface ReportsFilterWrapperProps {
  setFilters: React.Dispatch<React.SetStateAction<ReportsFilter>>;
}

const ReportsFilterWrapper: React.FC<ReportsFilterWrapperProps> = ({setFilters}) => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const dropdownRef = useRef(null);
  const EPOCH_DATE = '1970-01-01'

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [selectedStorageReportType, setSelectedStorageReportType] = useState<StorageReportTypeOption>(null);
  const [selectedBrand, setSelectedBrand] = useState<StringOption>(null);
  const [selectedSkuBarcode, setSelectedSkuBarcode] = useState<string>('');
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<WarehouseOption>(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<EmployeesOption>(null);

  const [selectedCategory, setSelectedCategory] = useState<CategoryInterface[]>(null);
  const [currentIdCatFilter, setCurrentIdCatFilter] = useState({
    categoryId: null,
    subCategoryId: null
  });
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [currentCatNameFilter, setCurrentCatNameFilter] = useState<string>('');

  const initialFiltersRange = {
    startDate: moment(new Date(null)).format('YYYY-MM-DD'),
    endDate: moment(new Date(null)).format('YYYY-MM-DD')
  }
  const [dateRangeFilter, setDateRangeFilter] = useState(initialFiltersRange)

  const isValidDate = (date?: string) => !!date && date !== EPOCH_DATE;
  const isDateRange = isValidDate(dateRangeFilter?.startDate) && isValidDate(dateRangeFilter?.endDate);
  const isMainFilters = isDateRange && !!selectedStorageReportType;

  const {
    data: branchProductCategoriesData,
    loading: branchProductCategoriesLoading
  } = useGetCategoriesForReportsQuery({
    variables: {branchId: +currentBranchId},
    skip: !currentBranchId,
    context: {
      errorType: 'local'
    },
    onCompleted: (data) => {
      const normalizedItems: ProductCategoryNewProxy[] = data.branchProductCategories;
      setSelectedCategory(
        transformProductCategories(normalizedItems, null, null)
      );
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const {
    data: brandsSKUsBarcodesStoragesData,
    loading: brandsSKUsBarcodesStoragesLoading
  } = useGetBrandsSkUsBarcodesStoragesForReportsQuery({
    variables: {
      branchId: +currentBranchId,
    },
    skip: !currentBranchId,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const {data: employeesData, loading: employeesLoading} = useGetEmployeesByBranchIdQuery({
    variables: {
      branchId: String(currentBranchId),
      online: false,
    },
    skip: !currentBranchId,
    client: apolloClientBooking,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const storageReportTypeOptions: StorageReportTypeOption[] = getStorageReportTypeOptions(t)
  const brandsOptions: StringOption[] = getProductParametersOptions(brandsSKUsBarcodesStoragesData?.branchBrands)
  const skuBarcodeSuggestionsList: string[] = mergeAndSortItems(brandsSKUsBarcodesStoragesData?.branchSKUs, brandsSKUsBarcodesStoragesData?.branchBarcodes)
  const warehousesOptions: WarehouseOption[] = getWarehousesOptions(brandsSKUsBarcodesStoragesData?.user?.currentBranch?.storages)
  const employeesOptions: EmployeesOption[] = getEmployeesOptions(employeesData?.employees)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLInputElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target) && target.id !== 'reports-categoryId-field') {
        setIsPickerOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCategoryIdChange = (categoryId: number) => {
    setCurrentIdCatFilter({categoryId, subCategoryId: null});
  };
  const handleSubCategoryIdChange = (categoryId: number, subCategoryId: number) => {
    setCurrentIdCatFilter((prevState) => ({
      ...prevState,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
    }));
  };

  const handleApplyFilter = () => {
    setFilters({
      startDate: dateRangeFilter.startDate,
      endDate: dateRangeFilter.endDate,
      reportType: selectedStorageReportType.value,
      categoryId: currentIdCatFilter?.subCategoryId ? currentIdCatFilter?.subCategoryId : currentIdCatFilter?.categoryId,
      brand: selectedBrand?.value ? selectedBrand?.value : null,
      skuBarcode: selectedSkuBarcode ? selectedSkuBarcode : null,
      warehouseId: selectedWarehouseId?.value ? selectedWarehouseId?.value : null,
      employeeId: selectedEmployeeId?.value,
    });
    setIsPickerOpen(false);
  };

  const resetSelectedCategories = (categories: CategoryInterface[]) => {
    return categories?.map((category) => ({
      ...category,
      isPicked: false,
      isSubcategoryPiked: false,
      subcategoryList: category?.subcategoryList?.map((subcategory) => ({
        ...subcategory,
        isPicked: false,
      })),
    }));
  };

  const handleClearFilter = () => {
    setFilters({
      startDate: null,
      endDate: null,
      reportType: null,
      categoryId: null,
      brand: null,
      skuBarcode: null,
      warehouseId: null,
      employeeId: null,
    });
    setDateRangeFilter(initialFiltersRange)
    setSelectedStorageReportType(null);
    setSelectedBrand(null);
    setSelectedSkuBarcode('');
    setSelectedWarehouseId(null);
    setSelectedEmployeeId(null);
    setCurrentCatNameFilter('');
    setSelectedCategory(null);
    setCurrentIdCatFilter({categoryId: null, subCategoryId: null})
    if (selectedCategory) {
      setSelectedCategory(resetSelectedCategories(selectedCategory));
    }
    setIsPickerOpen(false);
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleApplyFilter();
    }
  };

  if (branchProductCategoriesLoading || brandsSKUsBarcodesStoragesLoading || employeesLoading || !currentCompanyId || !currentBranchId) return <Spinner/>
  return (
    <>
      <Row className='gx-3 mb-md-3'>
        <Col md={6}>
          <div className='position-relative'>
            <DateRangeCalendar
              id='reports-rangeDate-field'
              name="dateRangeFilter"
              dateRangeFilter={dateRangeFilter}
              setDateRangeFilter={(value) => {
                setDateRangeFilter(value)
              }}
              disableValidation={true}
              subLabel='Operation Date Range'
            />
          </div>
        </Col>
        <Col md={6}>
          <ReactSelect
            id="reports-reportType-field"
            value={selectedStorageReportType}
            options={storageReportTypeOptions}
            placeholder={t('Report Type')}
            onChange={(selectedOption: StorageReportTypeOption) => setSelectedStorageReportType(selectedOption)}
            isSearchable={true}
            onKeyDown={handleSearchKeyDown}
          />
        </Col>
      </Row>
      <Row className='gx-3'>
        <Col lg={10}>
          <Row className='gx-3 h-100'>
            <Col lg={3}>
              <div className='position-relative mb-3 mb-lg-0'>
                <input
                  type='text'
                  id='reports-categoryId-field'
                  className='form-control px-4 cursor-pointer'
                  placeholder={t('Category')}
                  value={currentCatNameFilter}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsPickerOpen((prev) => !prev);
                  }}
                  readOnly
                  disabled={!isMainFilters}
                />
                <i
                  className={`bi bi-chevron-down position-absolute top-50 end-0 text-dark me-2 pointer-event-none transition-transform-icon ${isPickerOpen ? 'rotate-180-icon' : ''}`}></i>
                <div
                  ref={dropdownRef}
                  className='dropdown position-absolute opacity-1 z-2 top-10  start-0 end-0 mt-2 bg-white shadow'>
                  {branchProductCategoriesData && selectedCategory && (
                    <CategoryPickerComponent
                      isOpen={isPickerOpen}
                      locale={i18n.language}
                      onSearchChange={(searchVealue: string) => {
                      }}
                      onChangeCategory={(currentCategory: CategoryPickerCategory) => {
                        if (currentCategory.isPicked) {
                          setCurrentCatNameFilter(currentCategory?.name)
                          handleCategoryIdChange(currentCategory?.id);
                          setIsPickerOpen(false);
                        } else {
                          setCurrentCatNameFilter('')
                        }
                      }}
                      onChangeSubcategory={(currentSubcategory, currentCategory) => {
                        if (currentSubcategory?.isPicked) {
                          setCurrentCatNameFilter(currentSubcategory?.name)
                          handleSubCategoryIdChange(currentCategory?.id, currentSubcategory?.id);
                          setIsPickerOpen(false);
                        } else {
                          setCurrentCatNameFilter('')
                        }
                      }}
                      serviceCategories={selectedCategory}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <ReactSelect
                id="reports-brand-field"
                value={selectedBrand}
                options={brandsOptions}
                placeholder={t('Brand')}
                onChange={(selectedOption: StringOption) => setSelectedBrand(selectedOption)}
                isSearchable={true}
                onKeyDown={handleSearchKeyDown}
                disabled={!isMainFilters}
              />
            </Col>
            <Col lg={2}>
              <div className='position-relative mb-3 mb-lg-0'>
                <TextInputWithAutoComplete
                  id="reports-skuBarcode-field"
                  placeholder={t('SKU/Barcode')}
                  value={selectedSkuBarcode}
                  onChange={setSelectedSkuBarcode}
                  suggestions={skuBarcodeSuggestionsList}
                  isDisabled={!isMainFilters}
                />
              </div>
            </Col>
            <Col lg={3}>
              <ReactSelect
                id="reports-warehouseId-field"
                value={selectedWarehouseId}
                options={warehousesOptions}
                formatOptionLabel={(option) => (
                  <div>
                    <span>{option.label}</span>
                    {option.deleted && <span className='text-grey ms-1'>({t('archive')})</span>}
                  </div>
                )}
                placeholder={t('Warehouse Name')}
                onChange={(selectedOption: WarehouseOption) => setSelectedWarehouseId(selectedOption)}
                isSearchable={true}
                onKeyDown={handleSearchKeyDown}
                disabled={!isMainFilters}
              />
            </Col>
            <Col lg={2}>
              <ReactSelect
                id="reports-employeeId-field"
                value={selectedEmployeeId}
                options={employeesOptions}
                placeholder={t('Employee')}
                onChange={(selectedOption: EmployeesOption) => setSelectedEmployeeId(selectedOption)}
                isSearchable={true}
                onKeyDown={handleSearchKeyDown}
                disabled={!isMainFilters}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={2}>
          <Row className='gx-3 h-100'>
            <Col
              lg={6}
              className='mb-3 mb-lg-0'>
              <Button
                type='button'
                variant='outline-dark'
                className='border-grey w-100 h-100 px-1 fw-normal'
                onClick={handleClearFilter}
              >
                {t('clear')}
              </Button>
            </Col>
            <Col
              lg={6}
              className='mb-3 mb-lg-0'>
              <Button
                variant='primary'
                className='w-100 h-100 px-1 fw-normal'
                onClick={handleApplyFilter}
                disabled={!isMainFilters}
              >
                {t('Generate')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default ReportsFilterWrapper;