import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from '@services/ui-components';
import PersonalModule from './PersonalModule/PersonalModule';
import {
    useGetModulesListForTariffQuery,
} from '@me-team/host/main/Modules/graphql/modules.hooks';
import ServiceModule from './ServiceModule/ServiceModule';
import ClientsModule from './ClientsModule/ClientsModule';
import LogbookModule from './LogbookModule/LogbookModule';
import OnlineBookingModule from './OnlineBookingModule/OnlineBookingModule';
import WarehousesModule from './WarehousesModule/WarehousesModule';
import {
    DependentModulesInterface,
    DisableModuleDataInterface,
    PaidInfoInterface
} from '../../../../interfaces/interfaces';
import {ModuleInputType, ModuleProxy} from '@me-team/host/main/graphql/types';
import {useUpdateModulesWithRefetch} from "../../../../hooks/useUpdateModuleRelation";
import {SimpleModal} from "@services/ui-components/src/SimpleModal/SimpleModal";
import {Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import ModulesService from "../../../../services/ModulesService/ModulesService";
import {PreloaderComponent} from "@services/ui-components/src/PreloaderComponent/PreloaderComponent";
import {ModulesTagName, SubModulesTagName} from "../../../../utils/enums";

type ModuleListProps = {
    paidInfo: PaidInfoInterface;
};

const initialDisableModalData: DisableModuleDataInterface = {
    isModalOpen: false,
    moduleName: '',
    moduleId: null,
    dependentModules: [],
};

const ModulesList: React.FC<ModuleListProps> = ({ paidInfo }) => {
    const { currentCompanyId } = useParams();
    const {t: translate} = useTranslation();
    const { data, loading, refetch } = useGetModulesListForTariffQuery({
        fetchPolicy: 'network-only',
        variables: { companyId: Number(currentCompanyId) }
    });

    const [disableModalData, setDisableModalData] = useState<DisableModuleDataInterface>(initialDisableModalData);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const updateModules = useUpdateModulesWithRefetch(refetch);

    const openDisableModal = (modalData : DisableModuleDataInterface) => {
        setDisableModalData(modalData);
    }

    const onCloseDisabledModalHandler = () => {
        setDisableModalData(initialDisableModalData)
    }
    const onSubmitDisabledModal = async () => {
        setIsLoading(true);
        const isLogbook : boolean = disableModalData.moduleName === ModulesTagName.LOGBOOK;

        let filteredDependentModules: DependentModulesInterface[] = disableModalData.dependentModules.filter((dependentModule) => {
            return dependentModule.name !== SubModulesTagName.REVIEWS && dependentModule.name !== SubModulesTagName.STATISTIC;
        });

        if (isLogbook) {
            filteredDependentModules = filteredDependentModules.filter((dependentModule) => dependentModule.name !== ModulesTagName.LOGBOOK);
        }

        const updates: ModuleInputType[] = [
            { id: disableModalData.moduleId, isActive: false },
            ...filteredDependentModules.map((dependentModule : DependentModulesInterface) => dependentModule.inputForRequest),
        ];
        await updateModules(Number(currentCompanyId), updates);
        onCloseDisabledModalHandler();
        setIsLoading(false);
    }

    if (loading) return <Spinner />;
    if (!data) return null;

    const modulesList: ModuleProxy[] = data.user.company.modules;

    return (
        <>
            <PersonalModule modulesList={modulesList} updateModules={updateModules} openDisableModal={openDisableModal}/>
            <ServiceModule modulesList={modulesList} updateModules={updateModules} openDisableModal={openDisableModal}/>
            <ClientsModule modulesList={modulesList}  updateModules={updateModules} openDisableModal={openDisableModal}/>
            <LogbookModule modulesList={modulesList} updateModules={updateModules} openDisableModal={openDisableModal} setIsLoading={setIsLoading} />
            <OnlineBookingModule modulesList={modulesList} updateModules={updateModules} setIsLoading={setIsLoading}/>
            {!!paidInfo.planPrice?.length && (
                <WarehousesModule modulesList={modulesList} paidInfo={paidInfo} updateModules={updateModules}/>
            )}
            <SimpleModal isOpen={disableModalData.isModalOpen}
                         onClose={onCloseDisabledModalHandler}
                         title={translate('Disabling dependent modules')}
                         description={ModulesService.getDescForDependModal(disableModalData, translate)}
            >
                <Container>
                    <Row>
                        <Col xs={6} className={"ps-0"}>
                            <Button variant="outline-primary" className={"w-100 fw-normal"}
                                    onClick={onCloseDisabledModalHandler}>
                                {translate('Cancel')}
                            </Button>
                        </Col>
                        <Col xs={6} className={"pe-0"}>
                            <Button variant="primary" className={"w-100 fw-normal"}
                                    onClick={onSubmitDisabledModal}>
                                {translate('Disable all')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </SimpleModal>
            {isLoading && <PreloaderComponent />}
        </>
    );
};

export default ModulesList;
