/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetModulesListForTariffDocument = gql`
    query GetModulesListForTariff($companyId: Int!) {
  user {
    company(id: $companyId) {
      modules {
        id
        name
        active(companyId: $companyId)
        description
        image
        parentModule {
          id
          name
          active(companyId: $companyId)
          isPaid(companyId: $companyId)
          isPaymentRequired
        }
        childModules {
          id
          name
          active(companyId: $companyId)
          isPaymentRequired
          isPaid(companyId: $companyId)
        }
        isPaymentRequired
        isPaid(companyId: $companyId)
        monthlyTariffPlan
        yearlyTariffPlan
      }
    }
  }
}
    `;

/**
 * __useGetModulesListForTariffQuery__
 *
 * To run a query within a React component, call `useGetModulesListForTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesListForTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesListForTariffQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetModulesListForTariffQuery(baseOptions: Apollo.QueryHookOptions<GetModulesListForTariffQuery, GetModulesListForTariffQueryVariables> & ({ variables: GetModulesListForTariffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModulesListForTariffQuery, GetModulesListForTariffQueryVariables>(GetModulesListForTariffDocument, options);
      }
export function useGetModulesListForTariffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModulesListForTariffQuery, GetModulesListForTariffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModulesListForTariffQuery, GetModulesListForTariffQueryVariables>(GetModulesListForTariffDocument, options);
        }
export function useGetModulesListForTariffSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetModulesListForTariffQuery, GetModulesListForTariffQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModulesListForTariffQuery, GetModulesListForTariffQueryVariables>(GetModulesListForTariffDocument, options);
        }
export type GetModulesListForTariffQueryHookResult = ReturnType<typeof useGetModulesListForTariffQuery>;
export type GetModulesListForTariffLazyQueryHookResult = ReturnType<typeof useGetModulesListForTariffLazyQuery>;
export type GetModulesListForTariffSuspenseQueryHookResult = ReturnType<typeof useGetModulesListForTariffSuspenseQuery>;
export type GetModulesListForTariffQueryResult = Apollo.QueryResult<GetModulesListForTariffQuery, GetModulesListForTariffQueryVariables>;
export const GetPaidFeaturesForModuleDocument = gql`
    query getPaidFeaturesForModule($companyId: Int!) {
  user {
    company(id: $companyId) {
      paidFeatures {
        id
        name
        isYearly
        dateOfEnd
        price
        dateOfLastPayment
        currency
      }
    }
  }
}
    `;

/**
 * __useGetPaidFeaturesForModuleQuery__
 *
 * To run a query within a React component, call `useGetPaidFeaturesForModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaidFeaturesForModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaidFeaturesForModuleQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetPaidFeaturesForModuleQuery(baseOptions: Apollo.QueryHookOptions<GetPaidFeaturesForModuleQuery, GetPaidFeaturesForModuleQueryVariables> & ({ variables: GetPaidFeaturesForModuleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaidFeaturesForModuleQuery, GetPaidFeaturesForModuleQueryVariables>(GetPaidFeaturesForModuleDocument, options);
      }
export function useGetPaidFeaturesForModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaidFeaturesForModuleQuery, GetPaidFeaturesForModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaidFeaturesForModuleQuery, GetPaidFeaturesForModuleQueryVariables>(GetPaidFeaturesForModuleDocument, options);
        }
export function useGetPaidFeaturesForModuleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPaidFeaturesForModuleQuery, GetPaidFeaturesForModuleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPaidFeaturesForModuleQuery, GetPaidFeaturesForModuleQueryVariables>(GetPaidFeaturesForModuleDocument, options);
        }
export type GetPaidFeaturesForModuleQueryHookResult = ReturnType<typeof useGetPaidFeaturesForModuleQuery>;
export type GetPaidFeaturesForModuleLazyQueryHookResult = ReturnType<typeof useGetPaidFeaturesForModuleLazyQuery>;
export type GetPaidFeaturesForModuleSuspenseQueryHookResult = ReturnType<typeof useGetPaidFeaturesForModuleSuspenseQuery>;
export type GetPaidFeaturesForModuleQueryResult = Apollo.QueryResult<GetPaidFeaturesForModuleQuery, GetPaidFeaturesForModuleQueryVariables>;
export const UpdateModuleRelationDocument = gql`
    mutation updateModuleRelation($companyId: Int!, $input: ModuleInputType!) {
  updateCompanyModuleRelation(companyId: $companyId, input: $input) {
    id
    name
  }
}
    `;
export type UpdateModuleRelationMutationFn = Apollo.MutationFunction<UpdateModuleRelationMutation, UpdateModuleRelationMutationVariables>;

/**
 * __useUpdateModuleRelationMutation__
 *
 * To run a mutation, you first call `useUpdateModuleRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleRelationMutation, { data, loading, error }] = useUpdateModuleRelationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModuleRelationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleRelationMutation, UpdateModuleRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleRelationMutation, UpdateModuleRelationMutationVariables>(UpdateModuleRelationDocument, options);
      }
export type UpdateModuleRelationMutationHookResult = ReturnType<typeof useUpdateModuleRelationMutation>;
export type UpdateModuleRelationMutationResult = Apollo.MutationResult<UpdateModuleRelationMutation>;
export type UpdateModuleRelationMutationOptions = Apollo.BaseMutationOptions<UpdateModuleRelationMutation, UpdateModuleRelationMutationVariables>;
export const GeneratePaymentUrlForModuleDocument = gql`
    mutation generatePaymentUrlForModule($companyId: Int!, $input: GeneratePaymentUrlInput!) {
  generatePaymentUrl(companyId: $companyId, input: $input)
}
    `;
export type GeneratePaymentUrlForModuleMutationFn = Apollo.MutationFunction<GeneratePaymentUrlForModuleMutation, GeneratePaymentUrlForModuleMutationVariables>;

/**
 * __useGeneratePaymentUrlForModuleMutation__
 *
 * To run a mutation, you first call `useGeneratePaymentUrlForModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePaymentUrlForModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePaymentUrlForModuleMutation, { data, loading, error }] = useGeneratePaymentUrlForModuleMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePaymentUrlForModuleMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePaymentUrlForModuleMutation, GeneratePaymentUrlForModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePaymentUrlForModuleMutation, GeneratePaymentUrlForModuleMutationVariables>(GeneratePaymentUrlForModuleDocument, options);
      }
export type GeneratePaymentUrlForModuleMutationHookResult = ReturnType<typeof useGeneratePaymentUrlForModuleMutation>;
export type GeneratePaymentUrlForModuleMutationResult = Apollo.MutationResult<GeneratePaymentUrlForModuleMutation>;
export type GeneratePaymentUrlForModuleMutationOptions = Apollo.BaseMutationOptions<GeneratePaymentUrlForModuleMutation, GeneratePaymentUrlForModuleMutationVariables>;
export const GenerateManageUrlForModuleDocument = gql`
    mutation generateManageUrlForModule($paidFeatureId: Int!) {
  generateManageUrl(paidFeatureId: $paidFeatureId)
}
    `;
export type GenerateManageUrlForModuleMutationFn = Apollo.MutationFunction<GenerateManageUrlForModuleMutation, GenerateManageUrlForModuleMutationVariables>;

/**
 * __useGenerateManageUrlForModuleMutation__
 *
 * To run a mutation, you first call `useGenerateManageUrlForModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateManageUrlForModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateManageUrlForModuleMutation, { data, loading, error }] = useGenerateManageUrlForModuleMutation({
 *   variables: {
 *      paidFeatureId: // value for 'paidFeatureId'
 *   },
 * });
 */
export function useGenerateManageUrlForModuleMutation(baseOptions?: Apollo.MutationHookOptions<GenerateManageUrlForModuleMutation, GenerateManageUrlForModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateManageUrlForModuleMutation, GenerateManageUrlForModuleMutationVariables>(GenerateManageUrlForModuleDocument, options);
      }
export type GenerateManageUrlForModuleMutationHookResult = ReturnType<typeof useGenerateManageUrlForModuleMutation>;
export type GenerateManageUrlForModuleMutationResult = Apollo.MutationResult<GenerateManageUrlForModuleMutation>;
export type GenerateManageUrlForModuleMutationOptions = Apollo.BaseMutationOptions<GenerateManageUrlForModuleMutation, GenerateManageUrlForModuleMutationVariables>;
export type GetModulesListForTariffQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetModulesListForTariffQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', modules?: Array<{ __typename?: 'ModuleProxy', id: number, name?: string | null, active: boolean, description?: string | null, image?: string | null, isPaymentRequired: boolean, isPaid: boolean, monthlyTariffPlan?: string | null, yearlyTariffPlan?: string | null, parentModule?: { __typename?: 'ModuleProxy', id: number, name?: string | null, active: boolean, isPaid: boolean, isPaymentRequired: boolean } | null, childModules?: Array<{ __typename?: 'ModuleProxy', id: number, name?: string | null, active: boolean, isPaymentRequired: boolean, isPaid: boolean } | null> | null }> | null } | null } | null };

export type GetPaidFeaturesForModuleQueryVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetPaidFeaturesForModuleQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', paidFeatures?: Array<{ __typename?: 'PaidFeatureProxy', id: number, name?: Types.Tariff | null, isYearly: boolean, dateOfEnd?: string | null, price: number, dateOfLastPayment: string, currency?: Types.Currency | null }> | null } | null } | null };

export type UpdateModuleRelationMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ModuleInputType;
}>;


export type UpdateModuleRelationMutation = { __typename?: 'Mutation', updateCompanyModuleRelation: { __typename?: 'CompanyProxy', id: number, name: string } };

export type GeneratePaymentUrlForModuleMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.GeneratePaymentUrlInput;
}>;


export type GeneratePaymentUrlForModuleMutation = { __typename?: 'Mutation', generatePaymentUrl: string };

export type GenerateManageUrlForModuleMutationVariables = Types.Exact<{
  paidFeatureId: Types.Scalars['Int']['input'];
}>;


export type GenerateManageUrlForModuleMutation = { __typename?: 'Mutation', generateManageUrl: string };
