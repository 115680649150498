import React from 'react';
import {Formik} from 'formik';
import {
  TechMapProductInput,
  TechnologicalMapProductRelationProxy,
} from '@me-team/host/main/graphql/types';
import {Button} from 'react-bootstrap';
import {ReactSelect, TooltipCustom} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import QuantityInput from './inputs/QuantityInput';
import {Options} from '@me-team/host/src/utils/type';

export interface InitialVariables {
  relationId: number;
  input: TechMapProductInput;
}

interface EditableProductFieldsProps {
  initialValues: InitialVariables;
  onEdit: (values: InitialVariables) => Promise<void>;
  onCancel: () => void;
  unitGroup: string;
  product: TechnologicalMapProductRelationProxy;
}

const EditableProductFields: React.FC<EditableProductFieldsProps> = ({
                                                                       initialValues,
                                                                       unitGroup,
                                                                       product,
                                                                       onEdit,
                                                                       onCancel
                                                                     }) => {
  const {t} = useTranslation();
  const UNIT_PC_ID = 5

  const unitOptions: Options[] = [
    ...product.unit.unitGroup.units,
    ...(product.unit.id === UNIT_PC_ID
      ? product.product.alternativeUnit?.unitGroup.units || []
      : []),
  ].map((unit) => ({
    value: unit.id,
    label: unit.name,
  })) || [];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onEdit}
    >
      {({values, setFieldValue, handleSubmit}) => (
        <>
          <td className='align-middle py-3 px-2 line-height-140'>
            <QuantityInput
              unitGroup={unitGroup}
              id='productsTechMap-requiredQuantity-field'
              name='input.requiredQuantity'
            />
          </td>
          <td className='align-middle py-3 px-2 line-height-140'>
            <div style={{ height: '50px', minWidth: '85px' }}>
              <ReactSelect
                id='productsTechMap-unit-field'
                name='input.unit'
                placeholder=''
                options={unitOptions}
                value={unitOptions?.find((option: Options) => option.value === values.input.unit)}
                onChange={(selectedOption: Options) => setFieldValue('input.unit', selectedOption.value)}
                isSearchable={false}
                menuPortalTarget={document.body}
              />
            </div>
          </td>
          <td className='align-middle ps-2 pe-0 py-3 line-height-140'>
            <div className='d-flex align-items-center justify-content-end gap-2'>
              <TooltipCustom
                placement='bottom'
                text={t('Save')}
              >
                <Button
                  variant='outline-green'
                  onClick={() => handleSubmit()}
                  className='btn-square d-flex align-items-center justify-content-center p-1 bg-additional-green'
                  style={{width: '40px', height: '40px'}}
                >
                  <i className='bi bi-check-lg fs-20 text-green'></i>
                </Button>
              </TooltipCustom>
              <TooltipCustom
                placement='bottom'
                text={t('Cancel')}
              >
                <Button
                  variant='outline-secondary'
                  className='btn-square d-flex align-items-center justify-content-center p-1'
                  style={{width: '40px', height: '40px'}}
                  onClick={onCancel}
                >
                  <i className='bi bi-x-lg fs-16 text-dark'></i>
                </Button>
              </TooltipCustom>
            </div>
          </td>
        </>
      )}
    </Formik>
  );
};

export default EditableProductFields;