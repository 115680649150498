import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface IBreadcrumb {
    paths: Array<{
        path?: string,
        label: string,
        onClick?: <T>(param?: T) => void
    }>;
    t: any;
}

const Breadcrumb: React.FC<IBreadcrumb> = ({ paths, t }) => {
    const location = useLocation();

    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                    {paths.map((item, index) => {
                        const isActive = location.pathname === item.path;
                        return (
                            <li key={index} className={`breadcrumb-item ${isActive ? 'active' : ''}`} aria-current={isActive ? 'page' : undefined}>
                                {isActive ? (
                                    t(item.label)
                                ) : item.onClick ? (
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            item.onClick?.();
                                    }}
                                    >
                                      {t(item.label)}
                                    </a>
                                ) : (
                                    <Link to={item.path!}>{t(item.label)}</Link>
                                )}
                            </li>
                        );
                    })}
                </ol>
            </nav>
        </div>
    );
};

export default Breadcrumb;
