import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {
  BadgeAndButtonForArchive,
  ModalDelete,
  Pagination,
  RenderEmptyList,
  Spinner,
  TooltipCustom
} from '@services/ui-components';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../../services/ErrorService';
import {Link, useNavigate} from 'react-router-dom';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import {UserRole} from '../../../utils/enums';
import {Button, Form} from 'react-bootstrap';
import {getTextColorAvailabilityClass} from '../constants';
import RenderProductCategoryName from './RenderProductCategoryName';
import {
  useDeleteProductsMutation,
  useGetProductsLazyQuery,
  useGetProductsQuery
} from '@me-team/host/main/products/graphql/products.hooks';
import {ProductProxy} from '@me-team/host/main/graphql/types';

interface ProductsTableProps {
  filters: {
    brand: string,
    categoryId: number,
    nameSkuBarcode: string,
    stockStatus: string
  }
}

const ProductsTable: React.FC<ProductsTableProps> = ({filters}) => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const navigate = useNavigate();
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const parentCheckboxRef = useRef<HTMLInputElement>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [parentChecked, setParentChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [markedForDeletionArray, setMarkedForDeletionArray] = useState<number[]>([]);
  const [showCannotDelete, setShowCannotDelete] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [arrDeleteProductId, setArrDeleteProductId] = useState<number[]>([]);
  const [productCounterByAvailability, setProductCounterByAvailability ] = useState({
    unavailableCount: 0,
    availableCount: 0,
    totalCount: 0,
  })

  const modalDeleteBody = productCounterByAvailability.availableCount > 0
    ?
    t('multiple_delete_products_message', {
      unavailableCount: productCounterByAvailability.unavailableCount,
      availableCount: productCounterByAvailability.availableCount,
      totalCount: productCounterByAvailability.totalCount,
    })
    : t('Are you sure you want to delete selected product(s)?');

  const isFilterNotEmpty = Object.values(filters).some(value => value !== '' && value !== null)

  const resetCurrentPage = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    resetCurrentPage();
  }, [filters?.nameSkuBarcode, filters?.categoryId, filters?.brand, filters?.stockStatus]);

  const {
    data: productsData,
    loading: productsDataLoading,
    refetch: refetchProducts
  } = useGetProductsQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      nameSkuBarcode: filters?.nameSkuBarcode ? filters?.nameSkuBarcode : null,
      categoryId: filters?.categoryId ? filters?.categoryId : null,
      brand: filters?.brand ? filters?.brand : null,
      stockStatus: filters?.stockStatus ? filters?.stockStatus : null,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
    fetchPolicy: "cache-and-network"
  });

  const [getProduct, { loading, data: oneProductData }] = useGetProductsLazyQuery();

  const [deleteProducts] = useDeleteProductsMutation({
    refetchQueries: ['GetProducts'],
    awaitRefetchQueries: true,
    onCompleted: async (data) => {
      if (data) {
        setShowDeleteConfirmation(false);
        setMarkedForDeletionArray([]);
        setArrDeleteProductId([]);
      }
      await refetchProducts();
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  });

  const handleConfirmationClose = () => {
    setArrDeleteProductId([]);
    setShowDeleteConfirmation(false);
    setProductCounterByAvailability({
      unavailableCount: 0,
      availableCount: 0,
      totalCount: 0
    });
  }
  const handleCannotDeleteClose = () => {
    setArrDeleteProductId([]);
    setShowCannotDelete(false);
    setProductCounterByAvailability({
      unavailableCount: 0,
      availableCount: 0,
      totalCount: 0
    });
  }

  const handleShowModalDelete = async (id: number) => {
    setMarkedForDeletionArray([]);
    setArrDeleteProductId([id]);
    const {data} = await getProduct({
      variables: {
        id: id,
        stockStatus: 'In stock'
      },
    });
    const isAvailability  = data && !!data?.products?.items?.length

    if (isAvailability) {
      setShowCannotDelete(true);
    } else {
      setShowDeleteConfirmation(true);
    }
  };

  const handleShowModalMultipleDelete = async () => {
    const productDataArray = await Promise.all(
      markedForDeletionArray?.map(async (id) => {
        const { data } = await getProduct({
          variables: {
            id: id,
            stockStatus: 'In stock',
          },
          context: {
            errorType: 'local'
          },
          onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
          },
        });
        return data;
      })
    );

    const unavailableCount = productDataArray?.filter(productData => productData?.products?.items?.length === 0).length;
    const availableCount = markedForDeletionArray.length - unavailableCount;

    setProductCounterByAvailability({
      unavailableCount: unavailableCount,
      availableCount: availableCount,
      totalCount: markedForDeletionArray.length
    });
    if (unavailableCount === 0) {
      setShowCannotDelete(true);
    } else {
      setShowDeleteConfirmation(true);
    }
  };

  const handleDelete = async () => {
    const productIdsToDelete = arrDeleteProductId.length > 0 ? arrDeleteProductId : markedForDeletionArray;
    await deleteProducts({
      variables: { productIds: productIdsToDelete && productIdsToDelete },
      context: {
        errorType: 'local'
      }
    });
  };

  const products = productsData?.products?.items
  const pageCount = parseInt(productsData?.products?.pageCount || '0');
  const totalItems = productsData?.products?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  useEffect(() => {
    if (markedForDeletionArray.length === 0) {
      setParentChecked(false);
      setIndeterminate(false);
    } else if (markedForDeletionArray.length === products?.length) {
      setParentChecked(true);
      setIndeterminate(false);
    } else {
      setParentChecked(false);
      setIndeterminate(true);
    }

    if (parentCheckboxRef.current) {
      parentCheckboxRef.current.indeterminate = indeterminate;
    }
  }, [markedForDeletionArray, products?.length, indeterminate]);

  const handleChildCheckboxChange = (id: number) => {
    setMarkedForDeletionArray((prevMarkedForDeletionArray) =>
      prevMarkedForDeletionArray.includes(id)
        ? prevMarkedForDeletionArray.filter((markedId) => markedId !== id)
        : [...prevMarkedForDeletionArray, id]
    );
  };

  const handleParentCheckboxChange = () => {
    if (indeterminate) {
      setMarkedForDeletionArray([]);
      setParentChecked(false);
    } else if (parentChecked) {
      setMarkedForDeletionArray([]);
    } else {
      setMarkedForDeletionArray(products?.filter((product) => !product.deleted).map((product) => product.id) || []);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (productsDataLoading || !currentCompanyId) return <Spinner/>;

  return (
    <>
      <div className='col-12 table-responsive scrollbar position-relative'>
        {
          !!products?.length ? (
            <table className='table table-borderless'>
              <thead>
              <tr className='fs-7'>
                {
                  !isEmployeeRole ?
                    <th className='py-2 col-1 ps-4 pe-2 align-middle' style={{minWidth: '106px'}}>
                      <div className='d-flex align-items-center gap-3'>
                        <Form.Check
                          type="checkbox"
                          ref={parentCheckboxRef}
                          checked={parentChecked}
                          onChange={handleParentCheckboxChange}
                        />
                        {markedForDeletionArray.length > 0 ?
                          <TooltipCustom placement='right' text={t('Delete Product(s)')}>
                            <Button
                              variant='outline-danger'
                              className='border-0 fs-16 p-0'
                              onClick={handleShowModalMultipleDelete}
                            >
                              <i className="bi bi-trash3 icon-multi-delete"></i>
                            </Button>
                          </TooltipCustom>
                          : null
                        }
                      </div>
                    </th>
                    : null
                }
                <th className='py-3 col-2 px-2 align-middle line-height-140'>
                  {t('ProductsList.Name')}
                </th>
                <th className='py-3 px-2 col-2 align-middle line-height-140'>
                  {t('ProductsList.Brand')}
                </th>
                <th className='py-3 px-2 col-2 align-middle line-height-140'>
                  {t('ProductsList.Category')}
                </th>
                <th className='py-3 px-2 col-1 align-middle line-height-140'>
                  <span>{t('ProductsList.SKU')}</span>
                </th>
                <th className='py-3 px-2 col-1 align-middle line-height-140'>
                  {t('ProductsList.Barcode')}
                </th>
                <th className='py-1 px-2 col-1 align-middle text-start line-height-140'>
                  <span>{t('ProductsList.Unit')}</span>
                </th>

                <th className='py-1 px-2 col-1 align-middle line-height-140'>
                  {t('ProductsList.In stock')}
                </th>
                <th className='py-3 px-2 pe-0 col-1 align-middle'></th>
              </tr>
              </thead>
              <tbody>
              {products.map((product: ProductProxy) => {
                const isArchive = product?.deleted;
                const colorForArchive = isArchive ? 'text-secondary' : '';
                return (
                  <tr
                    key={product?.id}
                    className='border-bottom'>
                    {!isEmployeeRole ?
                      <td className='align-middle ps-4 py-4 pe-2'>
                        <Form.Check
                          type="checkbox"
                          checked={markedForDeletionArray.includes(product.id)}
                          onChange={() => handleChildCheckboxChange(product.id)}
                          disabled={isArchive}
                        />
                      </td>
                      : null
                    }
                    <td className='align-middle py-4 px-2'>
                      {
                        !isArchive ?
                          <Link
                            to={`/warehouse/product/${currentCompanyId}/${product?.id}/edit-product`}
                            className={`text-decoration-none text-primary`}
                          >
                            {product?.name}
                          </Link>
                          :
                          <span  className={`text-decoration-none text-secondary`}>
                            {product?.name}
                          </span>
                      }
                    </td>
                    <td className='align-middle py-4 px-2'>
                      <span className={colorForArchive}>
                        {product?.brand}
                      </span>
                    </td>
                    <td className='align-middle py-4 px-2'>
                      <RenderProductCategoryName
                        category={product?.productCategoryNew}
                        isArchive={isArchive}
                      />
                    </td>
                    <td className='align-middle py-4 px-2'>
                      <span className={colorForArchive}>
                        {product.sku ? product.sku : '—'}
                      </span>
                    </td>
                    <td className='align-middle py-4 px-2'>
                      <span className={colorForArchive}>
                        {product?.barcode ? product?.barcode : '—'}
                      </span>
                    </td>
                    <td className='align-middle py-4 px-2'>
                      <span className={colorForArchive}>
                        {product?.unit?.name}
                        {product?.alternativeUnit?.name
                          ?
                          `(${product?.alternativeUnit?.name})`
                          : null
                        }
                      </span>
                    </td>
                    <td className={`align-middle py-4 px-2 ${getTextColorAvailabilityClass(product?.productStockStatus, isArchive)}`}>
                      {t(product?.productStockStatus)}
                    </td>
                    <td className='align-middle ps-2 pe-0 py-4'>
                      <div className='d-flex align-items-center justify-content-end gap-2'>
                        {
                          !isArchive ?
                            <>
                              <TooltipCustom placement='bottom' text={t('ProductsList.Edit product')}>
                                <Button
                                  variant='outline-secondary'
                                  className='btn-square d-flex align-items-center justify-content-center p-1'
                                  style={{width: '40px', height: '40px'}}
                                  onClick={() => navigate(`/warehouse/product/${currentCompanyId}/${product?.id}/edit-product`)}
                                >
                                  <i className='bi bi-pencil fs-16 text-dark'></i>
                                </Button>
                              </TooltipCustom>
                              {!isEmployeeRole
                                ?
                                <TooltipCustom placement='bottom' text={t('ProductsList.Delete Product')}>
                                  <Button
                                    variant='outline-secondary'
                                    className='btn-square d-flex align-items-center justify-content-center p-1'
                                    style={{width: '40px', height: '40px'}}
                                    onClick={() => handleShowModalDelete(product?.id)}
                                  >
                                    <i className='bi bi-trash3 fs-16 text-dark'></i>
                                  </Button>
                                </TooltipCustom>
                                : null
                              }
                            </>
                            :
                            <BadgeAndButtonForArchive
                              onButtonClick={() => navigate(`/warehouse/product/${currentCompanyId}/${product?.id}/restore`)}
                            />
                        }
                      </div>
                    </td>
                  </tr>
                )})}
              </tbody>
            </table>
          ) : null
        }
      </div>

      {!!totalItems ? (
          <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
            <Pagination
              t={t}
              pages={Number(pageCount)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              firstItemIndex={firstItemIndex}
              lastItemIndex={lastItemIndex}
              totalItems={totalItems}
            />
          </div>
        ) :
        <RenderEmptyList
          isFilterNotEmpty={isFilterNotEmpty}
          visibilityBtn={true}
          emptyFilterTitle='No products were found for the specified values in the filters or search.'
          emptyListTitle='Products not yet created. Add a product.'
          onClickButton={() => navigate(`/warehouse/${currentCompanyId}/new-product`)}
          buttonName={
            <span>
                <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
              {t('Add New Product')}
              </span>
          }
        />
      }

      <ModalDelete
        show={showDeleteConfirmation}
        handleClose={handleConfirmationClose}
        title={t('Deleting of product(s)')}
        body={modalDeleteBody}
        buttons={[
          {
            text: t('Cancel'),
            onClick: handleConfirmationClose,
            variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
            className: 'd-flex justify-content-center align-items-center w-100',
          },
          {
            text: t('delete'),
            onClick: handleDelete,
            variant: 'primary',
            className: 'w-100',
          }
        ]}
      />
      <ModalDelete
        show={showCannotDelete}
        handleClose={handleCannotDeleteClose}
        title={t('It is impossible to remove the product')}
        variantTitle='text-danger'
        body={t(
          'This product cannot be deleted. Please write off or migrate the selected product from the warehouse, before deleting.'
        )}
        buttons={[
          {
            text: t('okBtn'),
            onClick: handleCannotDeleteClose,
            variant: 'danger',
            className: 'w-100',
          }
        ]}
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default ProductsTable;