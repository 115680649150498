import React, {useEffect, useRef, useState} from 'react';
import {
  GetTechnologicalMapsQuery,
  useGetTechMapsForServiceQuery,
  useUpdateTechMapConnectionForServiceMutation
} from '@me-team/host/main/technologicalMaps/graphql/technologicalMaps.hooks';
import {RefetchFunction} from '../types';
import {Button, Form} from 'react-bootstrap';
import {Pagination, RenderEmptyList, Spinner, TooltipCustom} from '@services/ui-components';
import {useNavigate, useParams} from 'react-router-dom';
import RenderCategoryName from '../RenderComponents/RenderCategoryName';
import {truncateText} from '../constants';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import ErrorService from '../../../services/ErrorService';
import {
  TechnologicalMapProxy,
} from '@me-team/host/main/graphql/types';
import {ActionUpdateType, UserRole} from '../../../utils/enums';
import UpdateRecoveryTechMap from '../UpdateRecoveryTechMap/UpdateRecoveryTechMap';

interface TechMapsListForServiceTable {
  filters: {
    search: string,
    categoryId: number
  };
  setRefetchTechMaps: (refetchTechMaps: RefetchFunction<GetTechnologicalMapsQuery>) => void;
  onShowCreateTechMapModal: () => void;
}

const TechMapsListForServiceTable: React.FC<TechMapsListForServiceTable> = ({filters, onShowCreateTechMapModal, setRefetchTechMaps}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const {serviceId} = useParams();
  const [connectedTechMapIds, setConnectedTechMapIds] = useState<number[]>([]);
  const parentCheckboxRef = useRef<HTMLInputElement>(null);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [showUpdateRecoveryTechMapModal, setShowUpdateRecoveryTechMapModal] = useState(false);
  const [currentAction, setCurrentAction] = useState<ActionUpdateType | null>(null);
  const [currentUpdateTechMap, setCurrentUpdateTechMap] = useState(null)

  const isFilterNotEmpty = Object.values(filters).some(value => value !== '' && value !== null)

  const resetCurrentPage = () => {
    setCurrentPage(1);
  };
  useEffect(() => {
    resetCurrentPage();
  }, [filters?.search, filters?.categoryId]);

  const {
    data: techMapsData,
    loading: techMapsLoading,
    refetch: refetchTechMaps
  } = useGetTechMapsForServiceQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      search: filters?.search ? filters?.search : null,
      categoryId: filters?.categoryId ? filters?.categoryId : null,
      isDeleted: false,
      connect: true,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    fetchPolicy: 'network-only',
    context: {
      errorType: 'global'
    },
      onCompleted: (data) => {
        const arrConnectedTechMapIds: number[] = data?.user?.company?.technologicalMaps?.technologicalMaps
          ?.filter((techMap) =>
            techMap?.technologicalMapServiceRelations?.some(
              (relation) => relation?.service?.id === +serviceId
            )
          )
          ?.map((techMap) => techMap.id) || [];
        setConnectedTechMapIds(arrConnectedTechMapIds);
      },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  useEffect(() => {
    if (setRefetchTechMaps) {
      setRefetchTechMaps(refetchTechMaps);
    }
  }, [refetchTechMaps, setRefetchTechMaps]);

  const [updateTechMapConnection] = useUpdateTechMapConnectionForServiceMutation({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    },
  })

  const company = techMapsData?.user?.company
  const techMaps: TechnologicalMapProxy[] = company?.technologicalMaps?.technologicalMaps
  const pageCount = parseInt(company?.technologicalMaps?.pageCount || '0');
  const totalItems = company?.technologicalMaps?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  useEffect(() => {
    if (parentCheckboxRef.current) {
      const allChecked = connectedTechMapIds?.length === techMaps?.length;
      const noneChecked = connectedTechMapIds?.length === 0;
      parentCheckboxRef.current.indeterminate = !allChecked && !noneChecked;
      setIndeterminate(parentCheckboxRef.current.indeterminate)
      parentCheckboxRef.current.checked = allChecked;
    }
  }, [connectedTechMapIds, techMaps]);

  const handleParentCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>)=> {
    const isChecked = e.target.checked;
    const techMapIds = techMaps.map((techMap) => techMap.id);
    if (indeterminate) {
      await updateTechMapConnection({
        variables: {
          techMapIds: techMapIds,
          connect: false,
          serviceId: +serviceId
        }
      });
      parentCheckboxRef.current.indeterminate = false;
      setConnectedTechMapIds([]);
    } else {
      setConnectedTechMapIds(isChecked ? techMapIds : []);
      await updateTechMapConnection({
        variables: {
          techMapIds: techMapIds,
          connect: isChecked,
          serviceId: +serviceId
        }
      });
    }
  };

  const handleTechMapCheckboxChange = async (techMapId: number) => {
    const isCurrentlyConnected = connectedTechMapIds.includes(techMapId);
    const updatedTechMapIds: number[] = isCurrentlyConnected
      ? connectedTechMapIds.filter((id: number) => id !== techMapId)
      : [...connectedTechMapIds, techMapId];

    setConnectedTechMapIds(updatedTechMapIds);
    await updateTechMapConnection({
      variables: {
        techMapIds: [techMapId],
        connect: !isCurrentlyConnected,
        serviceId: +serviceId
      }
    });
  };

  const toggleUpdateTechMapModal = () => setShowUpdateRecoveryTechMapModal(prevState => !prevState);

  const handleOpenEditTechMap = (action: ActionUpdateType, techMap: TechnologicalMapProxy) => {
    if (action && techMap) {
      setCurrentAction(action);
      setCurrentUpdateTechMap(techMap);
      toggleUpdateTechMapModal()
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (techMapsLoading || !currentCompanyId || !userRole) return <Spinner/>;

  return (
    <>
      <div className='col-12 table-responsive scrollbar position-relative'>
        {
          !!techMaps?.length ? (
            <table className='table table-borderless'>
              <thead>
              <tr className='fs-7'>
                <th className='py-2 col-2 ps-4 pe-2 align-middle' style={{minWidth: '106px'}}>
                  <div className='d-flex align-items-center gap-2'>
                    <Form.Check
                      type="checkbox"
                      ref={parentCheckboxRef}
                      checked={connectedTechMapIds.length === techMaps.length}
                      onChange={handleParentCheckboxChange}
                    />
                    <span className='fs-7 text-dark d-flex align-items-center gap-2'>
                      {t('Choose Tech. Maps')}
                      <TooltipCustom placement='bottom' text={t('Select technological maps for this service. Products from the selected technological maps will be offered for write-off when the service is performed.')}>
                        <i className="bi bi-info-circle fs-7 text-dark d-flex align-items-center d-flex align-items-center"></i>
                      </TooltipCustom>
                    </span>
                  </div>
                </th>
                <th className='py-3 col-3 px-2 align-middle line-height-140'>
                  {t('Name of tech map')}
                </th>
                <th className='py-3 px-2 col-2 align-middle line-height-140'>
                  {t('ProductsList.Category')}
                </th>
                <th className='py-3 px-2 col-5 align-middle line-height-140'>
                  {t('Description')}
                </th>
              </tr>
              </thead>
              <tbody>
              {techMaps.map((techMap) => (
                <tr
                  key={techMap?.id}
                  className='border-bottom'
                >
                  <td className='align-middle ps-4 py-4 pe-2'>
                    <Form.Check
                      type="checkbox"
                      checked={connectedTechMapIds.includes(techMap?.id)}
                      onChange={() => handleTechMapCheckboxChange(techMap?.id)}
                    />
                  </td>
                  <td className='align-middle py-4 px-2'>
                    {
                      !isEmployeeRole ?
                        <Button
                          variant='link'
                          onClick={() => handleOpenEditTechMap(ActionUpdateType.Edit, techMap)}
                          className={`text-decoration-none text-primary p-0 text-start line-height-140`}
                        >
                          {techMap?.name}
                        </Button>
                        :
                        <span className={`text-decoration-none text-secondary line-height-140`}>
                              {techMap?.name}
                        </span>
                    }
                  </td>
                  <td className='align-middle py-4 px-2'>
                    <RenderCategoryName
                      category={techMap?.technologicalMapNewCategory}
                    />
                  </td>
                  <td className='align-middle py-4 px-2'>
                    <span className='text-dark'>
                      {truncateText(techMap?.description, 100)}
                    </span>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          ) : null
        }
      </div>

      {!!totalItems ? (
          <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
            <Pagination
              t={t}
              pages={Number(pageCount)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              firstItemIndex={firstItemIndex}
              lastItemIndex={lastItemIndex}
              totalItems={totalItems}
            />
          </div>
        ) :
        <RenderEmptyList
          isFilterNotEmpty={isFilterNotEmpty}
          visibilityBtn={!isFilterNotEmpty}
          emptyFilterTitle='No technological maps were found in the filters or search.'
          emptyListTitle='The technological maps have not been created yet. Please add a technological map.'
          onClickButton={onShowCreateTechMapModal}
          buttonName={
            <span>
              <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
              {t('Add Tech.map')}
            </span>
          }
        />
      }

      <UpdateRecoveryTechMap
        show={showUpdateRecoveryTechMapModal}
        onClose={toggleUpdateTechMapModal}
        currentAction={currentAction}
        currentUpdateTechMap={currentUpdateTechMap}
        refetchTechMaps={refetchTechMaps}
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default TechMapsListForServiceTable;