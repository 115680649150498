import React, {useEffect} from 'react';
import {Container} from 'react-bootstrap';
import {TableHeader} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import PaymentHistoryAndManagementTable from './PaymentHistoryAndManagementTable';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {UserRole} from '../../../utils/enums';
import {useNavigate} from 'react-router-dom';

const PaymentHistoryAndManagement = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isAdminRole = +userRole === UserRole.ADMINISTRATOR;
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;


  useEffect(() => {
    if (isAdminRole || isEmployeeRole) {
      navigate("/error/403", {replace: true} )
    }
  }, [isAdminRole, isEmployeeRole]);

  if (!currentCompanyId || !userRole) return <Spinner/>;

  return (
    <Container fluid className='mb-9 mx-0 px-2 px-lg-0'>
      <TableHeader
        title={t('History and Payment Management')}
        paths={[
          {
            path: `/payment/history/company/${currentCompanyId}`,
            label: t('Management of Tariffs')
          },
          {
            path: `/payment/history/company/${currentCompanyId}`,
            label: t('History and Payment Management')
          },
        ]}
      />
      <PaymentHistoryAndManagementTable />
    </Container>
  );
};

export default PaymentHistoryAndManagement;