import React, {useState} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {ReportsFilter} from './types';
import {formatDate} from '@me-team/host/src/utils/utils';
import {
  StorageReportItemProxy
} from '@me-team/host/main/graphql/types';
import i18next from 'i18next';
import {useReactiveVar} from '@apollo/client';
import {currentBranchIdVar} from '@me-team/host/src/apollo/globalVar/state';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {ErrorModalComponent} from '@me-pos/error-modal';

interface ReportExportWrapperProps {
  filters: ReportsFilter;
  reports: StorageReportItemProxy[];
}

const ReportExportWrapper: React.FC<ReportExportWrapperProps> = ({filters, reports}) => {
  const {t} = useTranslation();
  const basePath = process.env.REACT_APP_POS_URL;
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const [currentStatusError, setCurrentStatusError] = useState<number>(null);
  const reportType = filters.reportType.toLowerCase().replace(/_/g, '-');
  const startDate: string = filters.startDate;
  const endDate: string = filters.endDate;
  const startGenerationCSVFileHandler = async () => {
    try {
      const requestBody = {
        startDate: startDate,
        endDate: endDate,
      };
      const response = await fetch(`${basePath}${i18next.language === 'uk' ? '/ua' : ''}/ajax/reports/branch/${currentBranchId}/${reportType}/report-generation/csv`, {
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        credentials: "include",
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        setCurrentStatusError(response.status);
      }
    } catch (error) {
      setCurrentStatusError(500);
    }
  }

  const startGenerationPDFFileHandler = async () => {
    try {
      const requestBody = {
        startDate: startDate,
        endDate: endDate,
      };
      const response = await fetch(`${basePath}${i18next.language === 'uk' ? '/ua' : ''}/ajax/reports/branch/${currentBranchId}/${reportType}/report-generation/pdf`, {
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        credentials: "include",
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        setCurrentStatusError(response.status);
      }
    } catch (error) {
      setCurrentStatusError(500);
    }
  }

  if (!currentBranchId) return <Spinner/>;

  return (
    <>
      <Container fluid className='py-3  ps-4 pe-3 bg-violet-light'>
        <Row>
          <Col xs={6}>
            <div className='d-flex flex-column text-dark'>
              <span className="fs-7">{t('Transaction Date Range')}</span>
              <span
                className="fs-16 fw-bold">{`${formatDate(filters.startDate)} - ${formatDate(filters.endDate)}`}</span>
            </div>
          </Col>
          <Col xs={6}>
            {reports?.length > 0 ?
              <div className='d-flex justify-content-end gap-4 h-100'>
                <Button
                  variant='green'
                  className='text-white fw-bold py-2'
                  style={{minWidth: '147px'}}
                  onClick={startGenerationCSVFileHandler}
                >
                  <i className="bi bi-filetype-csv fs-16 me-2"></i>
                  {t('Report in CSV')}
                </Button>
                <Button
                  variant='primary'
                  className='fw-bold py-2'
                  style={{minWidth: '147px'}}
                  onClick={startGenerationPDFFileHandler}
                >
                  <i className="bi bi-filetype-pdf fs-16 me-2"></i>
                  {t('Report in PDF')}
                </Button>
              </div> : null
            }
          </Col>
        </Row>
      </Container>
      <ErrorModalComponent
        i18n={i18next}
        onClose={() => {}}
        isOpen={!!currentStatusError}
        currentError={currentStatusError}
      />
    </>
  );
};

export default ReportExportWrapper;