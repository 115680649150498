import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import AddEditModal from '@services/ui-components/src/AddEditModal/AddEditModal';
import {ErrorMessage, Form, Formik} from 'formik';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import TextInputWithCounter from '../inputs/TextInputWithCounter';
import {techMapFormValidationSchema} from '../constants';
import {CustomModal, Spinner, TextareaInputWithCounter} from '@services/ui-components';
import {RequestInputService, ServiceModalWrapper} from '@me-pos/service-modal';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../../services/ErrorService';
import {CategoryInterface} from '../../Products/types';
import CategoryTechMaps from '../services/CategoryTechMaps';
import {normalizeCategoryData} from '../services/normalizeCategoryData';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {useNavigate} from 'react-router-dom';
import {CreateTechMapCategoryVariables, DuplicateTechMapData} from '../types';
import {
  useCreateTechMapMutation, useCreateTechMapsCategoryMutation, useGetTechMapsCategoriesQuery
} from '@me-team/host/main/technologicalMaps/graphql/technologicalMaps.hooks';
import {TechMapCategoryNewProxy, TechnologicalMapInput} from '@me-team/host/main/graphql/types';

interface ValidationError {
  [key: string]: { message: string; code: string | null }[];
}

interface CreateTechnologicalMapProps {
  show: boolean;
  onClose: () => void;
  refetchTechMaps: () => void;
}

const CreateTechnologicalMap: React.FC<CreateTechnologicalMapProps> = ({
                                                                         show,
                                                                         onClose,
                                                                         refetchTechMaps
                                                                       }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const basePath = process.env.REACT_APP_POS_URL;
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);

  const buttonCategoryRef = useRef<HTMLButtonElement>(null);
  const [isTechMapModalOpen, setIsTechMapModalOpen] = useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categoryList, setCategoryList] = useState<CategoryInterface[]>(null)
  const [categoryInput, setCategoryInput] = useState(null);
  const [isDuplicateTechMap, setIsDuplicateTechMap] = useState<boolean>(false);
  const [pickedCategoryId, setPickedCategoryId] = useState<number>(null);
  const [duplicateTechMapData, setDuplicateTechMapData] = useState<DuplicateTechMapData | null>(null);

  const [
    categoryText,
    setCategoryText
  ] = useState([null, null]);
  const selectedCategory = categoryText[0] || '';
  const selectedSubcategory = categoryText[1] || '';

  const [createTechnologicalMap] = useCreateTechMapMutation()
  const [createTechMapCategory] = useCreateTechMapsCategoryMutation()

  const {
    data: techMapCategoriesData,
    refetch: refetchCategories,
    loading: techMapCategoryLoading
  } = useGetTechMapsCategoriesQuery({
    variables: {
      itemsPerPage: 1000,
      delete: false
    },
    context: {
      errorType: 'local'
    },
    onCompleted: (data) => {
      const normalizedItems = normalizeCategoryData(data);
      setCategoryList(CategoryTechMaps.categoryModuleCreator(normalizedItems, null, null))
      setCategoryInput(RequestInputService.createServiceCategoryInput(null, null, normalizedItems));
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const categoryListData: TechMapCategoryNewProxy[] = techMapCategoriesData?.TechMapCategories?.items

  const handleSubmitCreateCategory = async (variables: CreateTechMapCategoryVariables) => {
    try {
      currentCompanyId && await createTechMapCategory({
        variables: {
          companyId: +currentCompanyId,
          input: variables?.input
        },
        onCompleted: (data) => {
          data && setCurrentCategory(data.createTechMapCategory);
        },
        onError: (error) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenDuplicateOfTechMapModal = () => {
    setIsDuplicateTechMap(true)
  }
  const handleCloseDuplicateOfTechMapModal = () => {
    setIsDuplicateTechMap(false)
  }

  const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (buttonCategoryRef.current) {
      buttonCategoryRef.current.focus();
    }
  };

  const handleCategoryNameChange = (categoryName: string) => {
    setCategoryText([categoryName]);
  };

  const handleSubCategoryNameChange = (subCategoryName: string, categoryName: string) => {
    setCategoryText([categoryName, subCategoryName]);
  };

  const resetInitialStateCategory = () => {
    setCategoryText([null, null]);
    setPickedCategoryId(null)
  }

  const initialValues: TechnologicalMapInput = {
    name: '',
    categoryId: null,
    description: '',
    deleted: false,
  }

  const handleCreateSubmit = async (
    values: TechnologicalMapInput,
  ) => {
    const input: TechnologicalMapInput = {
      name: values?.name,
      categoryId: values?.categoryId,
      description: values?.description ? values?.description : null,
      deleted: false,
    }

    await createTechnologicalMap({
      variables: {companyId: +currentCompanyId, input},
      context: {
        errorType: 'local',
      },
      onCompleted: (data) => {
        refetchTechMaps()
        resetInitialStateCategory()
        data && onClose()
      },
      onError: (error: ApolloError) => {
        console.error('error', error?.graphQLErrors[0]);
        const validationErrors = (
          error?.graphQLErrors[0]?.extensions as { validation?: ValidationError }
        )?.validation;
        const extractedData: Record<string, string> = {};

        if (validationErrors && validationErrors[""]) {
          validationErrors[""]?.forEach((err: any) => {
            const message: string = err.message;

            if (message.includes('The technological map already exists with')) {
              const regex = /name\s*=\s*"(.+?)"\s*and\s*id\s*=\s*"(.+?)"/;
              const match = message.match(regex);

              if (match) {
                const [, name, id] = match;
                const extractedData: DuplicateTechMapData = {
                  name,
                  techMapId: id,
                };
                setDuplicateTechMapData(extractedData);
                handleOpenDuplicateOfTechMapModal()
              }
            }
          })
        } else {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        }
      }
    })
  }

  const handleCloseCreateTechMapModal = () => {
    resetInitialStateCategory()
    onClose()
  }

  const handleNavigateAndClose = () => {
    handleCloseDuplicateOfTechMapModal();
    onClose()
    navigate(`/warehouse/technological-maps/${currentCompanyId}/${duplicateTechMapData?.techMapId}/product`)
  };

  if (techMapCategoryLoading) return <Spinner/>;

  return (
    <>
      <AddEditModal
        isModalOpened={show}
        setIsModalOpened={handleCloseCreateTechMapModal}
        title={t('ModalTechMap.Create New Technological Map')}
        contentClassName={`${isDuplicateTechMap ? 'd-none ' : 'd-block'}`}
      >
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={techMapFormValidationSchema(t)}
            onSubmit={handleCreateSubmit}
          >
            {({
                isSubmitting,
                setFieldValue,
                handleReset,
                touched,
                errors
              }) => {
              useEffect(() => {
                pickedCategoryId && setFieldValue('categoryId', pickedCategoryId);
              }, [pickedCategoryId]);

              return (
                <Form>
                  <Row className="m-0 mb-3">
                    <TextInputWithCounter
                      id='createTechMap-name-field'
                      name="name"
                      label='Technological Map Name'
                      placeholder='ModalTechMap.Enter a Name'
                    />
                  </Row>
                  <Row className='m-0 mb-3'>
                    <Col
                      md={3}
                      className='mb-2 mb-md-0'>
                      <label
                        htmlFor='createTechMap-categoryId-field'
                        className='text-dark fs-7 fw-bold py-1'
                        onClick={handleLabelClick}
                      >
                        {t('Category')}&nbsp;<span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col md={9}>
                      <div>
                        <span
                          className={`${selectedCategory ? 'me-4' : 'd-none'}  mb-2 mb-lg-0 text-dark`}>
                          {selectedCategory} {selectedSubcategory ?
                          <>
                            <i className="bi bi-arrow-right"></i> {selectedSubcategory}
                          </>
                          : null
                        }
                        </span>
                        <Button
                          ref={buttonCategoryRef}
                          variant={`${touched.categoryId && errors.categoryId ? 'outline-danger' : 'outline-primary'}`}
                          className='w-100 fw-normal py-1'
                          id='createTechMap-categoryId-field'
                          style={{maxWidth: '149px'}}
                          onClick={() => {
                            setIsTechMapModalOpen(true)
                          }}
                        >
                          {selectedCategory ? t('Change Category') : t('Select Category')}
                        </Button>
                        <ErrorMessage name="categoryId">{msg => <div
                          className='text-danger d-block fs-7 mt-1'>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </Col>
                  </Row>
                  <Row className='m-0'>
                    <TextareaInputWithCounter
                      id='createTechMap-name-field'
                      name='description'
                      label={t('Description')}
                      labelCol={3}
                      inputCol={9}
                      maxLength={400}
                      placeholder={t('Enter a Description')}
                    />
                  </Row>
                  <Row className="w-100 m-0 mt-4">
                    <Col lg={6}
                         className='offset-lg-6 d-flex justify-content-end gap-2 px-0 ps-lg-4'>
                      <Col xs={6} lg={6}>
                        <Button
                          onClick={() => {
                            handleReset();
                            resetInitialStateCategory()
                            onClose();
                          }}
                          variant="outline-primary" type="button" disabled={isSubmitting}
                          className="w-100 fw-normal"
                        >
                          {t("Cancel")}
                        </Button>
                      </Col>
                      <Col xs={6} lg={6}>
                        <Button
                          variant="primary"
                          type="submit"
                          className="w-100"
                        >
                          {t("Save")}
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>

          {
            isTechMapModalOpen && techMapCategoriesData && !techMapCategoryLoading ?
              <ServiceModalWrapper
                i18n={i18next}
                selectedCategoryId={null}
                selectedSubcategoryId={null}
                apolloUrl={process.env.REACT_APP_API_URL}
                isOpen={isTechMapModalOpen}
                onClose={() => {
                  setIsTechMapModalOpen(!isTechMapModalOpen)
                }}
                onCategorySelect={(selectedCategory) => {
                  handleCategoryNameChange(selectedCategory?.name);
                  setPickedCategoryId(selectedCategory?.id)
                  setIsTechMapModalOpen(!isTechMapModalOpen)
                }}
                onSubcategorySelect={(selectedSubcategory) => {
                  handleSubCategoryNameChange(
                    selectedSubcategory?.name,
                    selectedSubcategory?.parentCategory?.name
                  );
                  setPickedCategoryId(selectedSubcategory?.id)
                  setIsTechMapModalOpen(!isTechMapModalOpen)
                }}
                categoryList={categoryList}
                categoryInput={categoryInput}
                categoryListData={categoryListData}
                refetchCategories={refetchCategories}
                onSubmitCreate={handleSubmitCreateCategory}
                currentCategory={currentCategory}
                categoryService={CategoryTechMaps}
                requestInputService={RequestInputService}
                normalizeCategoryData={normalizeCategoryData}
              />
              : null
          }

          {isErrorModalOpen ?
            <ErrorModalComponent
              i18n={i18next}
              onClose={() => {
                setIsErrorModalOpen(null)
              }}
              isOpen={!!isErrorModalOpen}
              currentError={isErrorModalOpen}
            /> : null
          }
        </>
      </AddEditModal>
      <CustomModal
        show={isDuplicateTechMap}
        handleClose={handleCloseDuplicateOfTechMapModal}
        title={
          <Container>
            <Row>
              <h5 className='m-0 p-0 fw-normal'>{t('The technological map already exists')}</h5>
            </Row>
          </Container>
        }
        body={
          <Container>
            <Row>
              <Col>
                <span
                  className='fs-16 me-1'>{t('Such a Technological Map already exists. You can view its products via the link.')}
                </span>
                <div>
                  <Button
                    variant='link'
                    className='fs-normal text-primary text-decoration-underline cursor-pointer'
                    onClick={handleNavigateAndClose}
                  >
                    {`${duplicateTechMapData?.name}`}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        }
        buttons={[
          {
            text: t('ProductDuplicate.Back'),
            onClick: handleCloseDuplicateOfTechMapModal,
            variant: 'outline-primary',
            className: 'w-100 fw-normal',
          }
        ]}
      />
    </>
  );
};

export default CreateTechnologicalMap;