import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form as FormCh } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import CategoryInput from "./CategoryInput";
import {
    useCreateServiceCategoryMutation,
    useGetCurrencySignQuery,
    useUpdateServiceCategoryModalMutation
} from "@me-team/host/main/modalSalary/graphql/modalSalary.hooks";
import SalaryComponent from './SalaryComponent/SalaryComponent';
import { ServiceCategorySalaryProxy} from "@me-team/host/main/graphql/types";
import {
    validateSalarySections,
    processDeleteState,
    processSalarySection,
    SalarySection, transformSalarySections
} from "./salaryUtils";
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {TFunction} from 'i18next';

interface SalaryModalProps {
    show: boolean;
    setShowSalaryModal: React.Dispatch<React.SetStateAction<boolean>>;
    employeeId: any;
    refetch: () => void
    t: TFunction;
}
const defaultSalarySection: SalarySection = {
    id: Date.now(),
    categoryId: '',
    salary: 0,
    salaryType: '%',
    categoryName: '',
    subCategoryId: '',
    selectedAllCategories: false,
    update: false,
    mutation: "create"
};
const SalaryModal: React.FC<SalaryModalProps> = ({ show, setShowSalaryModal, employeeId, refetch, t }) => {
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);

    const handleModal = () => {
        setShowSalaryModal(prevState => !prevState)
        resetSalarySections()
    }
    const initialValues = {
        companyId: employeeId?.id,
        salarySections: [{ categoryId: '', salary: 0, salaryType: '', categoryName: '', subCategoryId: '' }]
    };
    const [salarySections, setSalarySections] = useState<SalarySection[]>([{ id: Date.now(), categoryId: '', salary: 0, salaryType: '%', categoryName: '', subCategoryId: '', selectedAllCategories: false, update: false, salaryError: false, errorMessage: '', errorCategory: ''}]);
    const [checkbox, setCheckbox] = useState(false);
    const [deleteState, setDeleteState] = useState([]);
    const isSelectedAll = employeeId?.ServiceCategorySalaries.some(
        (item: ServiceCategorySalaryProxy) => item.deleted === false && item.selectedAll === true
    );

    const { data: currencySign, loading: loadingCurrency } = useGetCurrencySignQuery({
        variables: { companyId: +currentCompanyId },
        skip: !currentCompanyId,
    })
    const currSign = currencySign?.user?.company?.currency?.currencySign;
    useEffect(() => {
        if (show) {
            setCheckbox(isSelectedAll);
        }
    }, [show, isSelectedAll]);

    useEffect(() => {
        if (employeeId?.ServiceCategorySalaries) {
            const transformedSalarySections = transformSalarySections(employeeId, defaultSalarySection, currSign);
            setSalarySections(transformedSalarySections);
        }
    }, [show, employeeId?.ServiceCategorySalaries]);

    const resetSalarySections = () => {
        setSalarySections(prevSections => {
            const selectedAll =  !prevSections[0].selectedAllCategories;
            const updatedFirstSection = {
                ...prevSections[0],
                selectedAllCategories: selectedAll,
                errorCategory: selectedAll ? false : prevSections[0].errorCategory,
                errorMessage: selectedAll ? '' : prevSections[0].errorMessage,
            };
            return [updatedFirstSection];
        });
        setDeleteState([]);
    };

    const handleCheckbox = () => {
        setCheckbox(prevState => !prevState);
        resetSalarySections()
    };
    const addSalarySection = () => {
        setSalarySections([...salarySections, {...defaultSalarySection, id: Date.now()}]);
    };

    const [createServiceCategory] = useCreateServiceCategoryMutation({
        context: {
            errorType: 'local',
        },
    });
    const [updateServiceCategory] = useUpdateServiceCategoryModalMutation({
        context: {
            errorType: 'local',
        },
    });

    const handleSubmit = async (
        values: typeof initialValues,
        { setFieldError }: FormikHelpers<typeof initialValues>
    ) => {
        try {
            const { hasErrors, updatedSections } = validateSalarySections(
                salarySections,
                checkbox,
                t
            );
            setSalarySections(updatedSections);

            if (hasErrors) {
                return;
            }
            await processDeleteState(deleteState, updateServiceCategory, setDeleteState);
            const promises = salarySections.map((section) =>
                processSalarySection(
                    section,
                    checkbox,
                    employeeId,
                    currentCompanyId,
                    updateServiceCategory,
                    createServiceCategory
                )
            );
            await Promise.allSettled(promises);
            await refetch();
            await handleModal();
        } catch (error) {
            console.error('Error handling service category:', error);
        }
    };

    const handleSalaryValueChange = (index: number, newValue: number) => {
        const updatedSections = salarySections.map((section, i) =>
            i === index ? { ...section, salary: newValue } : section
        );
        setSalarySections(updatedSections);
    };

    const handleSalaryTypeChange = (index: number, newType: string) => {
        const updatedSections = salarySections.map((section, i) =>
            i === index ? { ...section, salaryType: newType } : section
        );
        setSalarySections(updatedSections);
    };
    const updateSalarySections = (newSections: SalarySection[]) => {
        const { hasErrors, updatedSections: validatedSections } = validateSalarySections(
            newSections,
            checkbox,
            t
        );
        setSalarySections(validatedSections);

    };
    const removeSalarySection = (id: number | string) => {
        const sectionToDelete = salarySections.find(section => section.id === id);
        const updatedDeleteState = [...deleteState, sectionToDelete];
        const updatedSalarySections = salarySections.filter(section => section.id !== id);
        setDeleteState(updatedDeleteState);
        updateSalarySections(updatedSalarySections);
    };

    const handleCategoryIdChange = (index: number, categoryId: number) => {
        setSalarySections(prevSections => {
            const updatedSections = prevSections.map((section, i) =>
                i === index ? { ...section, categoryId } : section
            );
            updateSalarySections(updatedSections);
            return updatedSections;
        });
    };

    const handleSubCategoryIdChange = (index: number, subCategoryId: number) => {
        setSalarySections(prevSections => {
            const updatedSections = prevSections.map((section, i) =>
                i === index ? { ...section, subCategoryId } : section
            );
            updateSalarySections(updatedSections);
            return updatedSections;
        });
    };

    const handleCategoryNameChange = (index: number, categoryName: string) => {
        const updatedSections = salarySections.map((section, i) =>
            i === index ? { ...section, categoryName: categoryName } : section
        );
        setSalarySections(updatedSections);
    };

    const handleSubCategoryNameChange = (index: number, subCategoryName: string) => {
        const updatedSections = salarySections.map((section, i) =>
            i === index ? { ...section, subCategoryName: subCategoryName } : section
        );
        setSalarySections(updatedSections);
    }

    return (
        <>
            <Modal size="lg" show={show} onHide={handleModal} centered>
                <Modal.Header className="border-0 pb-0" closeButton>
                    <Col>
                        <h5 className="m-0 pb-1">
                            {t('Employee Salary')} {employeeId?.name + ' ' + employeeId?.surname}
                        </h5>
                    </Col>
                </Modal.Header>
                <span className='text-grey ps-3 pb-4' >
                            {t('Please specify the salary for one completed service')}
                        </span>
                <Modal.Body className='pt-2'>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form>
                                {salarySections.map((section, index) => (
                                    <div key={section.id}>
                                        <Row className='pt-1'>
                                            <Col xs={3} sm={2}  className="d-flex">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label  htmlFor="salaryModal-name-field" className="mb-0 text-dark form-label required"> {t('Services category')} <span className="text-danger">*</span></label>
                                                    </small>
                                                </span>
                                            </Col>
                                            <Col xs={9} sm={10}>
                                                <div className="position-relative">

                                                    <div className="w-100">
                                                        {checkbox ? (
                                                            <FormCh.Group>
                                                                <FormCh.Control
                                                                    type="text"
                                                                    placeholder={t('Select a category/subcategory')}
                                                                    className="fs-7 form-control"
                                                                    disabled
                                                                    id="salaryModal-name-field"
                                                                />
                                                            </FormCh.Group>
                                                        ) : (
                                                            <CategoryInput
                                                                updateCategoryId={(id) => handleCategoryIdChange(index, id)}
                                                                updateSubCategoryId={(id) => handleSubCategoryIdChange(index, id)}
                                                                updateCategoryName={(name) => handleCategoryNameChange(index, name)}
                                                                updateSubCategoryName={(name) => handleSubCategoryNameChange(index, name)}
                                                                t={t}
                                                                isValid={!!section.errorCategory}
                                                                nameCategory={section?.categoryName}
                                                            />
                                                        )}
                                                        {section.errorCategory && (
                                                            <p className="fs-7 p-1 text-danger m-0">
                                                                {section.errorMessage}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className='mt-3'>
                                                    {salarySections.length === 1 && index === 0 && (
                                                        <FormCh.Check
                                                            className='m-0'
                                                            type={"checkbox"}
                                                            id={`default-checkbox-${index}`}
                                                            label={<span className="fs-7 text-dark">{t('All Categories')}</span>}
                                                            checked={checkbox}
                                                            onChange={handleCheckbox}

                                                        />
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="pt-3">
                                            <Col xs={3} sm={2}>
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label htmlFor="Salary" className="mb-0 text-dark form-label required"> {t('Salary')} <span className="text-danger">*</span></label>
                                                    </small>
                                                </span>
                                            </Col>
                                            <Col  xs={9} sm={10}>
                                                <div className="position-relative">
                                                    <div className="w-100">
                                                        <SalaryComponent
                                                            currencySign={currSign}
                                                            updateSalaryValue={(value) => handleSalaryValueChange(index, value)}
                                                            updateSalaryType={(type) => handleSalaryTypeChange(index, type)}
                                                            salaryError={section.salaryError}
                                                            salary={section?.salary}
                                                            currType={section?.salaryType}
                                                        />
                                                        {index !== 0 && (
                                                            <Button
                                                                variant='outline-secondary'
                                                                onClick={() => removeSalarySection(section.id)}
                                                                className="text-dark ms-3 p-0"
                                                                style={{ height: '49px', width: '49px' }}
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </Button>
                                                        )}
                                                        {section.salaryError && (
                                                            <p className="fs-7 p-1 text-danger m-0">
                                                                {section.errorMessage}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        {salarySections.length > 1 && index < salarySections.length - 1 && (
                                            <Row>
                                                <Col xs={2}></Col>
                                                <Col xs={10}>
                                                    <div className="border-bottom m-0 my-4"></div>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                ))}

                                <Row className="pt-3">
                                    <Col xs={2}></Col>
                                    <Col xs={10} className={`pt-3  ${checkbox ? 'text-secondary' : 'text-primary'}`} >
                                        <span className='fs-7 cursor-pointer' onClick={checkbox ? () => {} : addSalarySection}>
                                            + {t('Add salary for the service category')}
                                        </span>
                                    </Col>
                                </Row>

                                <div className="d-flex justify-content-end mt-4 pt-2">
                                    <Button variant="outline-primary" className="fw-normal mb-0 mb-sm-0 me-2 custom-btn rounded-1" onClick={handleModal}>
                                        {t("Cancel")}
                                    </Button>
                                    <Button type="submit" variant="primary" className="fw-bold custom-btn rounded-1">
                                        {t('Save')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SalaryModal
