import React, {useEffect, useState} from "react";
import {FiltersButton, TableHeader} from "@services/ui-components";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {SearchComponent} from "../SearchComponent/SearchComponent";
import Button from "react-bootstrap/Button";
import {ServiceCategoriesList} from "./ServiceCategoriesList/ServiceCategoriesList";
import {CreateEditModalComponent, RequestInputService} from "@me-pos/service-modal";
import i18next from "i18next";
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar, currentUserRoleVar} from "@me-team/host/src/apollo/globalVar/state";
import ToastComponent from "../../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../../hooks/useToast";
import {ImportFiles} from "../../ImportFiles/ImportFiles";
import {requirementsInfoType} from "../../../interfaces/interfaces";
import RequirementsFileInfo from "../../../services/importFiles/RequirementsFileInfo";
import {ServiceCategoryNewProxy} from "@me-team/host/main/graphql/types";
import {ExportBtn} from "../../ExportBtn/ExportBtn";
import {EntityType} from "../../../utils/enums";
import {useCreateServCategoryMutation} from "@me-team/host/main/services/graphql/services.hooks";

export const ServiceCategories: React.FC = () => {
    const {t: translate} = useTranslation();
    const [showFilters, setShowFilters] = useState(false);
    const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>(null);
    const [categoryList, setCategoryList] = useState<ServiceCategoryNewProxy[]>([]);
    const [reRender, setRerender] = useState<boolean>(false);
    const [hasAccess, setHasAccess] = useState<boolean>(null);
    const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
    const [serviceCategoriesInfo, setServiceCategoriesInfo] = useState<requirementsInfoType>(null);
    const {showToast, toastText, toggleShowToast, setShowToast} = useToast();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const role = useReactiveVar(currentUserRoleVar);

    useEffect(() => {
        if (role === 1 || role === 3) {
            setHasAccess(true);
        } else {
            setHasAccess(false);
        }
    }, [role]);

    useEffect(() => {
        if (currentCompanyId) {
            setServiceCategoriesInfo(RequirementsFileInfo.serviceCategoryFileRequirements(parseInt(currentCompanyId), translate, i18next.language));
        }
    }, [i18next.language, currentCompanyId]);
    const onOpenImportModalHandler = () => {
        setIsImportModalOpen(true);
    };
    const onCloseImportModalHandler = () => {
        setIsImportModalOpen(false);
    };
    const getCategoryList = (categoryList: ServiceCategoryNewProxy[]) => {
        setCategoryList(categoryList)
    }

    const HandleToggleFilters = () => {
        setShowFilters(!showFilters)
    }

    const searchHandling = (currentValue: string) => {
        setSearchValue(currentValue ? currentValue : null)
    }


    const onSubmitHandlerRestoreCategory = () => {
        setRerender(!reRender)
        setIsCreateCategoryModalOpen(false);
    }

    const [createCategory] = useCreateServCategoryMutation({
        fetchPolicy: 'network-only'
    });

    return (
        <>
            <TableHeader
                title={translate('servicesPage.serviceCategoryTitle')}
                paths={[{path: `/services/service/${currentCompanyId}/service-list`, label: translate('Services')}, {
                    path: "/service-categories",
                    label: translate('servicesPage.serviceCategoryTitle')
                }]}
                colSize={5}
                btnColSize={7}
                buttons={
                    <Row className='gx-3 justify-content-end mt-4 mb-2'>
                        {hasAccess &&
                            <>
                                <Col
                                    xs={6} lg>
                                    <ExportBtn companyId={parseInt(currentCompanyId)} exportType={EntityType.SERVICE_CATEGORIES}/>
                                </Col>
                                <Col xs={6} lg>
                                    <button
                                        className='btn btn-outline-violet border-primary text-primary fw-normal d-flex align-items-center justify-content-center w-100 mb-3 mb-xl-0  '
                                        onClick={onOpenImportModalHandler}>
                                        <i className='bi bi-box-arrow-in-down me-2'></i>
                                        {translate('imports')}
                                    </button>
                                </Col>
                                <Col xs={8} sm={9} lg={5} xl={4}>
                                    <Button className="btn btn-primary w-100 fw-bold px-2 text-truncate" onClick={() => {
                                        setIsCreateCategoryModalOpen(true)
                                    }}>
                                        <i className='bi bi-plus-lg me-2'></i> {translate('servicesPage.addCategory')}
                                    </Button>
                                </Col>
                            </>
                        }
                        <Col className={`${hasAccess ? "col-4 col-sm-3 col-lg" : "col-sm-5 col-xl-3"}`}>
                            <FiltersButton filter={{searchValue: searchValue}} showFilters={showFilters}
                                           onClick={HandleToggleFilters} classesText={`${hasAccess ? "d-none d-xxl-inline-block" : "d-inline-block"}`}/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
            >
                <SearchComponent value={searchValue} placeholder={translate("searchByServiceName")} searchHandling={searchHandling}/>
            </TableHeader>
            <ServiceCategoriesList
                getCategoryList={getCategoryList}
                reRender={reRender}
                searchValue={searchValue ? searchValue.toLowerCase() : null}
                hasAccess={hasAccess}
                toggleShowToast={toggleShowToast}
            />
            {isCreateCategoryModalOpen &&
                <CreateEditModalComponent
                    i18n={i18next}
                    categoryId={null}
                    subcategoryId={null}
                    isOpen={isCreateCategoryModalOpen}
                    categoryInput={RequestInputService.createServiceCategoryInput(null, null, [])}
                    categoryList={categoryList}
                    isCreateAction={isCreateCategoryModalOpen}
                    onClose={() => {
                        setIsCreateCategoryModalOpen(false);
                    }}
                    onSubmit={() => {}}
                    onSubmitCreate={async (inputVariables) => {
                        await createCategory({
                            variables : {companyId : inputVariables.companyId, input : inputVariables.input},
                            onCompleted : () => {
                                onSubmitHandlerRestoreCategory();
                                toggleShowToast(translate('Created'))
                            }
                        })

                    }}
                    onSubmitUpdate={async () => {}}
                    requestInputService={RequestInputService}
                />
            }
            {showToast && <ToastComponent show={showToast} setShow={setShowToast} text={toastText}/>}
            {serviceCategoriesInfo &&
                <ImportFiles currentRequirementsInfo={serviceCategoriesInfo} isOpen={isImportModalOpen}
                             onClose={onCloseImportModalHandler}/>}
        </>
    )
}
