import React from 'react';
import {Container, Table} from 'react-bootstrap';
import {
  StorageReportItemOperationProxy,
  StorageReportType
} from '@me-team/host/main/graphql/types';
import {useTranslation} from 'react-i18next';
import {ColumnConfig} from './types';
import {OPERATION_COLUMNS} from './constants';
import RenderOperationRow from './components/RenderOperationRow';

interface OperationsTableProps {
  operations: StorageReportItemOperationProxy[];
  reportType: StorageReportType;
}

const OperationsTable: React.FC<OperationsTableProps> = ({operations, reportType}) => {
  const {t} = useTranslation();
  const columns: ColumnConfig[] = OPERATION_COLUMNS[reportType];

  return (
    <Container
      className='px-0 table-responsive scrollbar position-relative d-flex justify-content-center'>
      {operations ? (
        <Table className='table table-borderless mb-0'>
          <thead>
          <tr>
            {columns.map((col) => (
              <th
                key={col.key}
                className={`fs-7 py-6 px-2 align-middle text-start line-height-140 ${col.className || ""}`}
              >
                {t(col.label)}
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {!!operations.length ? operations?.map((operation, index) => {
              const hasBorder = index !== operations.length - 1 ? "border-bottom" : "";
              return (
                <tr className='p-4' key={index}>
                  <RenderOperationRow
                    operationData={operation}
                    reportType={reportType}
                    hasBorder={hasBorder}
                  />
                </tr>
              )
            }
          ) : null}
          </tbody>
        </Table>) : null}
    </Container>
  );
};

export default OperationsTable;