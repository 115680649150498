/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetAddNewProductListDocument = gql`
    query GetAddNewProductList($page: Int, $itemsPerPage: Int, $nameSkuBarcode: String, $categoryId: Int, $brand: String, $branchesPaginatedId: Int, $storagesPaginated: Int, $companyId: Int!) {
  user {
    company(id: $companyId) {
      branchesPaginated(id: $branchesPaginatedId) {
        branches {
          storagesPaginated(id: $storagesPaginated) {
            storages {
              id
              name
              outOfStockProducts(
                page: $page
                itemsPerPage: $itemsPerPage
                nameSkuBarcode: $nameSkuBarcode
                categoryId: $categoryId
                brand: $brand
              ) {
                products {
                  id
                  image
                  brand
                  name
                  sku
                  description
                  barcode
                  unitGroup {
                    id
                    name
                    units {
                      id
                      name
                    }
                  }
                  unit {
                    id
                    name
                  }
                  alternativeUnit {
                    id
                    name
                    unitGroup {
                      id
                      name
                      units {
                        id
                        name
                      }
                    }
                  }
                  alternativeValue
                  productCategoryNew {
                    id
                    name
                    deleted
                    parentCategory {
                      id
                      name
                      deleted
                    }
                  }
                  storageProductRelations {
                    id
                    quantity
                  }
                  deleted
                }
                totalCount
                currentPage
                itemsPerPage
                pageCount
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAddNewProductListQuery__
 *
 * To run a query within a React component, call `useGetAddNewProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddNewProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddNewProductListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      nameSkuBarcode: // value for 'nameSkuBarcode'
 *      categoryId: // value for 'categoryId'
 *      brand: // value for 'brand'
 *      branchesPaginatedId: // value for 'branchesPaginatedId'
 *      storagesPaginated: // value for 'storagesPaginated'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAddNewProductListQuery(baseOptions: Apollo.QueryHookOptions<GetAddNewProductListQuery, GetAddNewProductListQueryVariables> & ({ variables: GetAddNewProductListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddNewProductListQuery, GetAddNewProductListQueryVariables>(GetAddNewProductListDocument, options);
      }
export function useGetAddNewProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddNewProductListQuery, GetAddNewProductListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddNewProductListQuery, GetAddNewProductListQueryVariables>(GetAddNewProductListDocument, options);
        }
export function useGetAddNewProductListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAddNewProductListQuery, GetAddNewProductListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAddNewProductListQuery, GetAddNewProductListQueryVariables>(GetAddNewProductListDocument, options);
        }
export type GetAddNewProductListQueryHookResult = ReturnType<typeof useGetAddNewProductListQuery>;
export type GetAddNewProductListLazyQueryHookResult = ReturnType<typeof useGetAddNewProductListLazyQuery>;
export type GetAddNewProductListSuspenseQueryHookResult = ReturnType<typeof useGetAddNewProductListSuspenseQuery>;
export type GetAddNewProductListQueryResult = Apollo.QueryResult<GetAddNewProductListQuery, GetAddNewProductListQueryVariables>;
export const GetProductForAddStorageDocument = gql`
    query GetProductForAddStorage($productId: Int) {
  products(id: $productId) {
    items {
      id
      price
      cost
      image
      brand
      name
      sku
      description
      barcode
      unitGroup {
        id
        name
        units {
          id
          name
        }
      }
      unit {
        id
        name
      }
      alternativeUnit {
        id
        name
        unitGroup {
          id
          name
          units {
            id
            name
          }
        }
      }
      alternativeValue
      productCategoryNew {
        id
        name
        deleted
        parentCategory {
          id
          name
          deleted
        }
      }
      storageProductRelations {
        id
        quantity
      }
      deleted
    }
  }
}
    `;

/**
 * __useGetProductForAddStorageQuery__
 *
 * To run a query within a React component, call `useGetProductForAddStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductForAddStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductForAddStorageQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetProductForAddStorageQuery(baseOptions?: Apollo.QueryHookOptions<GetProductForAddStorageQuery, GetProductForAddStorageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductForAddStorageQuery, GetProductForAddStorageQueryVariables>(GetProductForAddStorageDocument, options);
      }
export function useGetProductForAddStorageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductForAddStorageQuery, GetProductForAddStorageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductForAddStorageQuery, GetProductForAddStorageQueryVariables>(GetProductForAddStorageDocument, options);
        }
export function useGetProductForAddStorageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductForAddStorageQuery, GetProductForAddStorageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductForAddStorageQuery, GetProductForAddStorageQueryVariables>(GetProductForAddStorageDocument, options);
        }
export type GetProductForAddStorageQueryHookResult = ReturnType<typeof useGetProductForAddStorageQuery>;
export type GetProductForAddStorageLazyQueryHookResult = ReturnType<typeof useGetProductForAddStorageLazyQuery>;
export type GetProductForAddStorageSuspenseQueryHookResult = ReturnType<typeof useGetProductForAddStorageSuspenseQuery>;
export type GetProductForAddStorageQueryResult = Apollo.QueryResult<GetProductForAddStorageQuery, GetProductForAddStorageQueryVariables>;
export const CreateProductToWarehouseDocument = gql`
    mutation CreateProductToWarehouse($companyId: Int!, $input: ProductInput!) {
  createProduct(companyId: $companyId, input: $input) {
    id
    name
    deleted
  }
}
    `;
export type CreateProductToWarehouseMutationFn = Apollo.MutationFunction<CreateProductToWarehouseMutation, CreateProductToWarehouseMutationVariables>;

/**
 * __useCreateProductToWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateProductToWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductToWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductToWarehouseMutation, { data, loading, error }] = useCreateProductToWarehouseMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductToWarehouseMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductToWarehouseMutation, CreateProductToWarehouseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductToWarehouseMutation, CreateProductToWarehouseMutationVariables>(CreateProductToWarehouseDocument, options);
      }
export type CreateProductToWarehouseMutationHookResult = ReturnType<typeof useCreateProductToWarehouseMutation>;
export type CreateProductToWarehouseMutationResult = Apollo.MutationResult<CreateProductToWarehouseMutation>;
export type CreateProductToWarehouseMutationOptions = Apollo.BaseMutationOptions<CreateProductToWarehouseMutation, CreateProductToWarehouseMutationVariables>;
export type GetAddNewProductListQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  nameSkuBarcode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  brand?: Types.InputMaybe<Types.Scalars['String']['input']>;
  branchesPaginatedId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  storagesPaginated?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  companyId: Types.Scalars['Int']['input'];
}>;


export type GetAddNewProductListQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', branchesPaginated?: { __typename?: 'BranchPaginated', branches?: Array<{ __typename?: 'BranchProxy', storagesPaginated?: { __typename?: 'StoragePaginated', storages?: Array<{ __typename?: 'StorageProxy', id: number, name: string, outOfStockProducts?: { __typename?: 'OutOfStockProductsPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, products?: Array<{ __typename?: 'ProductProxy', id: number, image?: string | null, brand?: string | null, name: string, sku?: string | null, description?: string | null, barcode?: string | null, alternativeValue?: number | null, deleted: boolean, unitGroup?: { __typename?: 'UnitGroupProxy', id: number, name: string, units?: Array<{ __typename?: 'UnitProxy', id: number, name: string }> | null } | null, unit?: { __typename?: 'UnitProxy', id: number, name: string } | null, alternativeUnit?: { __typename?: 'UnitProxy', id: number, name: string, unitGroup?: { __typename?: 'UnitGroupProxy', id: number, name: string, units?: Array<{ __typename?: 'UnitProxy', id: number, name: string }> | null } | null } | null, productCategoryNew?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null, storageProductRelations?: Array<{ __typename?: 'StorageProductRelationProxy', id?: number | null, quantity?: number | null }> | null }> | null } | null }> | null } | null }> | null } | null } | null } | null };

export type GetProductForAddStorageQueryVariables = Types.Exact<{
  productId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetProductForAddStorageQuery = { __typename?: 'Query', products?: { __typename?: 'ProductPaginated', items?: Array<{ __typename?: 'ProductProxy', id: number, price: string, cost: string, image?: string | null, brand?: string | null, name: string, sku?: string | null, description?: string | null, barcode?: string | null, alternativeValue?: number | null, deleted: boolean, unitGroup?: { __typename?: 'UnitGroupProxy', id: number, name: string, units?: Array<{ __typename?: 'UnitProxy', id: number, name: string }> | null } | null, unit?: { __typename?: 'UnitProxy', id: number, name: string } | null, alternativeUnit?: { __typename?: 'UnitProxy', id: number, name: string, unitGroup?: { __typename?: 'UnitGroupProxy', id: number, name: string, units?: Array<{ __typename?: 'UnitProxy', id: number, name: string }> | null } | null } | null, productCategoryNew?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null, storageProductRelations?: Array<{ __typename?: 'StorageProductRelationProxy', id?: number | null, quantity?: number | null }> | null }> | null } | null };

export type CreateProductToWarehouseMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ProductInput;
}>;


export type CreateProductToWarehouseMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'ProductProxy', id: number, name: string, deleted: boolean } };
