import React, {useRef, useState} from 'react';
import {Formik, Field, Form, ErrorMessage, FormikHelpers} from 'formik';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Row, Col, Button} from 'react-bootstrap';
import CommentField from './inputs/CommentField ';
import {clientFormValidationSchema} from './constants';
import DatePicker from './inputs/DatePicker';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {
  PhonePicker,
  ReactSelect,
  TextInput
} from '@services/ui-components';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import {PhoneInputRefType} from 'react-international-phone';
import {UserRole} from '../../utils/enums';
import {
  useCreateClientMutation,
  useGetGenderQuery,
  useGetOneClientQuery, useUpdateClientMutation
} from '@me-team/host/main/clients/graphql/clients.hooks';
import { ClientInput } from '@me-team/host/main/graphql/types';
import {useGetCountryGeoQuery} from "@me-team/host/main/branches/graphql/branches.hooks";

interface ValidationErrorExtensions {
  validation: {
    phone?: { message: string }[];
    email?: { message: string }[];
    image?: { message: string }[];
  };
}

interface Option {
  value: number;
  label: string;
}

interface FormValues {
  comment?: string;
  dateOfBirth?: string;
  email: string;
  gender: number | null;
  image?: File | null;
  isUpdateImage?: boolean;
  name: string;
  patronymic: string;
  phone: string;
  surname: string;
}

type SetFieldValueType = (field: string, value: string | boolean | string[] | File | (string | File)[]) => void;

const CreateUpdateClientForm: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const phonePickerRef = useRef<PhoneInputRefType>(null);
  const {id} = useParams();

  const {
    data: oneClientData,
    loading,
  } = useGetOneClientQuery({
    variables: {
      id: +id,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId || !id,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const {data: genderData} = useGetGenderQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  });
  const { data: countryGeoData, loading: loadingCountryGeo} = useGetCountryGeoQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const [createClient] = useCreateClientMutation();
  const [updateClient] = useUpdateClientMutation();

  const client = oneClientData?.user?.company?.clients?.clients[0];

  const [selectedOption, setSelectedOption] = useState<number>(id ? client?.gender?.id : null);

  const options: Option[] = genderData?.gender.map((item) => ({
    value: item.id,
    label: t(item.name),
  }));

  const handlePhoto = (setFieldValue: SetFieldValueType) => {
    setFieldValue('isUpdateImage', true);
  }

  const countryGeo = countryGeoData?.countryGeo?.country;

  const initialValues = {
    name: id && client?.name ? client?.name : '',
    surname: id && client?.surname ? client?.surname : '',
    patronymic: id && client?.patronymic ? client?.patronymic : '',
    dateOfBirth: id && client?.dateOfBirth ? client?.dateOfBirth : '',
    email: id && client?.email ? client?.email : '',
    phone: id && client?.phone ? client?.phone : '',
    comment: id && client?.comment ? client?.comment : '',
    gender: id && client?.gender?.id ? client?.gender?.id : null,
    isUpdateImage: false,
  };

  const handleUpdateSubmit = async (
    values: FormValues,
    {setSubmitting, setErrors}: FormikHelpers<FormValues>
  ) => {
    const input: ClientInput = {
      id: +id,
      company: +currentCompanyId,
      name: values?.name,
      surname: values?.surname,
      patronymic: values?.patronymic,
      email: values?.email ? values?.email : values?.email,
      phone: values?.phone ? values?.phone : values?.phone,
      comment: values?.comment,
      dateOfBirth: values?.dateOfBirth,
      gender: values?.gender ? values?.gender : null,
      isUpdateImage: values?.isUpdateImage,

      ...(values?.image && { image: values?.image }),
    };

    await updateClient({
      variables: {id: +id, input, companyId: +currentCompanyId},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        data && navigate(`/clients/${currentCompanyId}/list`, { state:  {  text: t('Saved'), toast: true } });
        data && setSubmitting(false);
      },
      onError: (error) => {
        console.error('error', error.graphQLErrors[0]);
        const validationErrors = (
          error.graphQLErrors[0].extensions as unknown as ValidationErrorExtensions
        ).validation;
        validationErrors?.phone && validationErrors?.phone[0]?.message === 'This value is not a' +
        ' valid phone number.' &&
        setErrors({phone: t('validation.this value is not a valid phone number')});
        validationErrors?.email && validationErrors?.email[0].message === 'This value is not a' +
        ' valid email address.' &&
        setErrors({email: t('validation.This value is not a valid email address.')});

        validationErrors?.phone && validationErrors?.phone[0]?.message === 'Client already exists by provided phone.' &&
        setErrors({phone: t('validation.there is already a customer with this phone number')});
        validationErrors?.email && validationErrors?.email[0].message === 'Client already exists by provided email.' &&
        setErrors({email: t('validation.there is already a client with this email')});

        validationErrors?.image &&
        validationErrors?.image[0].message.replace(/\(\d+px\)/, "(width)") === 'The image width is too small (width). Minimum width' +
        ' expected is 500px.' &&
        setErrors({image: t('The image width is too small. Minimum width expected is 500px.')});

        setIsErrorModalOpen(ErrorService.errorHandling(error))
        setSubmitting(false);
      },
    });
  };

  const handleCreateSubmit = async (
    values: FormValues,
    {setSubmitting, setErrors}: FormikHelpers<FormValues>
  ) => {
    const input: ClientInput = {
      company: +currentCompanyId,
      name: values?.name,
      surname: values?.surname ? values?.surname : null,
      patronymic: values?.patronymic ? values?.patronymic : null,
      email: values?.email ? values?.email : null,
      phone: values?.phone ? values?.phone : null,
      comment: values?.comment ? values?.comment : null,
      image: values?.image ? values?.image : null,
      dateOfBirth: values?.dateOfBirth ? values?.dateOfBirth : null,
      gender: values?.gender ? values?.gender : null,
    };

    const errorMappings: Record<string, { field: keyof FormValues; message: string }> = {
      'This value is not a valid phone number.': { field: 'phone', message: t('validation.this value is not a valid phone number') },
      'This value is not a valid email address.': { field: 'email', message: t('validation.This value is not a valid email address.') },
      'Client already exists by provided phone.': { field: 'phone', message: t('validation.there is already a customer with this phone number') },
      'Client already exists by provided email.': { field: 'email', message: t('validation.there is already a client with this email') },
      'The image width is too small (width). Minimum width expected is 500px.': { field: 'image', message: t('The image width is too small. Minimum width expected is 500px.') },
    };
    await createClient({
      variables: {companyId: +currentCompanyId, input},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        data && navigate(`/clients/${currentCompanyId}/list`, { state:  {  text: t('Created'), toast: true } });
        setSubmitting(false);
      },
      onError: (error: ApolloError) => {
        const validationErrors = (
          error.graphQLErrors[0].extensions as unknown as ValidationErrorExtensions
        ).validation;

        Object.entries(validationErrors || {}).forEach(([field, messages]) => {
          const message = messages[0]?.message;
          const mappedError = errorMappings[message];

          if (mappedError && mappedError.field === field) {
            setErrors({ [field]: mappedError.message });
          }
        });
        setIsErrorModalOpen(ErrorService.errorHandling(error))
        setSubmitting(false);
      },
    });
  };

  if (loading || loadingCountryGeo || !currentCompanyId) return <Spinner/>;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={clientFormValidationSchema(t)}
        onSubmit={id ? handleUpdateSubmit : handleCreateSubmit}>
        {({setFieldValue, errors, values, handleBlur, touched, isValid, isSubmitting}) => {
          const handleSelectChange = (selectedOption: Option | null) => {
            setFieldValue('gender', selectedOption ? selectedOption?.value : '');
            setSelectedOption(selectedOption ? selectedOption?.value : null)
          };

          const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            navigate(-1);
          };

          return (
            <Form>
              <Row className='flex-column-reverse flex-lg-row mb-5'>
                <Col lg={8} xxl={9} className=' pe-xl-5 '>
                  <h4 className='mb-3'>{t('Personal data')}</h4>
                  <Row className='mb-4 fs-7'>
                    <TextInput
                      id='client-name-field'
                      name='name'
                      placeholder='Add Name'
                      label={<span>{t('Name')}&nbsp;<span className='text-danger'>*</span></span>}
                      maxLength={50}
                      disabled={isEmployeeRole}
                    />
                  </Row>
                  <Row className='mb-4 fs-7'>
                    <TextInput
                      id='client-surname-field'
                      name='surname'
                      placeholder='Add Last name'
                      label={t('Last name')}
                      maxLength={50}
                      disabled={isEmployeeRole}
                    />
                  </Row>
                  <Row className='mb-4 fs-7'>
                    <TextInput
                      id='client-patronymic-field'
                      name='patronymic'
                      placeholder='Add Middle name'
                      label={t('Middle name')}
                      maxLength={50}
                      disabled={isEmployeeRole}
                    />
                  </Row>
                  <Row className='mb-4'>
                    <DatePicker
                      id='client-dateOfBirth-field'
                      name='dateOfBirth'
                      label={t('Date of birth')}
                      placeholder={t('dd.mm.yyyy')}
                      value={id && client?.dateOfBirth}
                      disabled={isEmployeeRole}
                    />
                  </Row>
                  <Row className='mb-2'>
                    <Col md={2} className='mb-2 mb-md-0 d-flex align-items-center'>
                      <label
                        htmlFor='client-email-field'
                        className='text-dark fs-7 fw-bold'>
                        {t('E-mail')}&nbsp;<span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <Field
                        id='client-email-field'
                        name='email'
                        placeholder={t('Add E-mail')}
                        className={`form-control fs-7  ${
                          errors?.email && touched?.email ? 'is-invalid' : ''
                        }`}
                        onBlur={handleBlur}
                        disabled={isEmployeeRole}
                      />
                      <ErrorMessage name='email'>
                        {msg => <div className='invalid-feedback'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Col className='text-center text-grey mb-2'>
                    <span>{t('or')}</span>
                  </Col>
                  <Row className='mb-4'>
                    <Col md={2} className='mb-2 mb-md-0 d-flex align-items-center'>
                      <label
                        htmlFor='client-phone-field'
                        className='text-dark fs-7 fw-bold'
                        onClick={() => phonePickerRef.current?.focus()}
                      >
                        {t('phone')}&nbsp;<span className='text-danger'>*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <PhonePicker
                        phoneRef={phonePickerRef}
                        name='phone'
                        value={id ? client?.phone : ''}
                        disabled={isEmployeeRole}
                        id='client-phone-field'
                      />
                      <ErrorMessage name='phone'>
                        {msg => <div className="fs-7 m-0 p-1 text-danger">{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <Col md={2} className='mb-2 mb-md-0 d-flex align-items-center'>
                      <label
                        htmlFor='gender'
                        className='text-dark fs-7 fw-bold'>
                        {t('Gender')}
                      </label>
                    </Col>
                    <Col md={10}>
                      <ReactSelect
                        id='client-gender-field'
                        name='gender'
                        placeholder={t('Select gender')}
                        options={options}
                        value={options?.find((option: any) => option.value === values.gender)}
                        onChange={handleSelectChange}
                        isSearchable={false}
                        disabled={isEmployeeRole}
                      />
                      <ErrorMessage name='gender'>
                        {msg => <div className='invalid-feedback'>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row className='mb-4'>
                    <CommentField
                      id='client-comment-field'
                      label={t('Comment')}
                      name='comment'
                      placeholder={t('Add a comment')}
                      disabled={isEmployeeRole}
                    />
                  </Row>
                </Col>
                <Col lg={4} xxl={3}>
                  <div
                    className='d-flex mb-3 w-100 flex-column  justify-content-lg-end'>
                    <h4 className='mb-3 w-100'>
                      {t('Clients photo')}
                    </h4>
                    <ImageCrop
                      previewPhoto={id && client?.image}
                      setFieldValue={setFieldValue}
                      fieldSetter={() => handlePhoto(setFieldValue)}
                      fieldName="image"
                      dismissImage={() => setFieldValue('isUpdateImage', true)}
                      modalTitle={t('Clients photo')}
                      notBGinfo={true}
                      disabled={isEmployeeRole}
                    />
                  </div>
                </Col>
              </Row>

              <Row className='mt-2 mt-lg-5'>
                  <Col lg={4} className='d-flex gap-4'  >
                    <Button
                      variant='outline-primary'
                      className='custom-btn'
                      type='reset'
                      onClick={handleCancel}
                    >
                      {t('cancel')}
                    </Button>


                    {id ? (
                      <Button
                        variant='primary'
                        type='submit'
                        className='custom-btn'
                        disabled={isEmployeeRole}
                      >
                        {t('Save')}
                      </Button>
                    ) : (
                      <Button
                        variant='primary'
                        type='submit'
                        className='custom-btn'
                        disabled={isEmployeeRole || isSubmitting}
                      >
                        {t('create')}
                      </Button>
                    )}
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};
export default CreateUpdateClientForm;
