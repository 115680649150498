import React, {useState} from 'react'
import {InfoBanner, TableHeader} from "@services/ui-components";
import {Trans, useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import TariffPlanFilter from "../TariffPlanFilter";
import ModulesList from "./ModulesList/ModulesList";
import {PaidInfoInterface} from "../../../interfaces/interfaces";
import {GetTariffPlansQuery} from "@me-team/host/main/tariffPlans/graphql/tariffPlans.hooks";
import {Filter} from "../interface";

const Modules: React.FC = () => {
    const {t: translate, i18n} = useTranslation();
    const {currentCompanyId} = useParams();
    const basePath = process.env.REACT_APP_POS_URL;
    const paymentTermsConditionsHref: string = `${basePath}/${i18n.language === 'en' ? '' : `${i18n.language}/`}terms-and-conditions`;
    const [paidInfo, setPaidInfo] = useState<PaidInfoInterface>({
        currencyInfo : {
            currency : {value : null, label : null},
            period : null
        },
        planPrice : null
    });

    return (
        <div className={'mb-5 px-2 px-lg-0'}>
            <InfoBanner iconClassColor={'text-dark'}>
                <p className='fs-7 mb-0 text-dark'>
                    <Trans i18nKey="payment_terms_conditions"
                           components={{1: <a href={paymentTermsConditionsHref} target="_blank"/>}}/>
                </p>
            </InfoBanner>

            <TableHeader
                title={translate('Modules')}
                paths={[
                    {
                        path: `/payment/company/${currentCompanyId}/modules`,
                        label: translate('Management of Tariffs')
                    },
                    {
                        path: `/payment/company/${currentCompanyId}/modules`,
                        label: translate('Modules')
                    }
                ]}
                buttons={
                    <TariffPlanFilter
                        onFilterChange={(filter : Filter) => {
                            if (filter) {
                                setPaidInfo((prevState : PaidInfoInterface) => {
                                    return {
                                        ...prevState,
                                        currencyInfo : filter
                                    }
                                })
                            }
                        }}
                        onTariffPlans={(getTariffPlans:  GetTariffPlansQuery) => {
                            if (getTariffPlans) {
                                setPaidInfo((prevState) => {
                                    return {
                                        ...prevState,
                                        planPrice : getTariffPlans.planPrice
                                    }
                                })
                            }
                        }}
                        hideTitle={true}
                        btnStylesClass={{
                            activeButton: 'primary text-white',
                            inactiveButton: 'outline-primary text-primary',
                        }}
                    />
                }
                btnPositionEnd={'align-self-end'}
            >
            </TableHeader>
            <ModulesList paidInfo={paidInfo}/>
        </div>
    )
}

export default Modules