import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Col, Button, Row, Container} from "react-bootstrap";
import {CustomModal, FiltersButton, TableHeader} from '@services/ui-components';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import {useReactiveVar} from "@apollo/client";
import {currentBranchIdVar, currentUserRoleVar} from "@me-team/host/src/apollo/globalVar/state";
import AddNewProductListTable from "./AddNewProductListTable/AddNewProductListTable";
import {ProductsFilterI} from "../Products/types";
import ProductsFilterWrapper from "../Products/ProductsList/ProductsFilterWrapper";
import {UserRole} from "../../utils/enums";
import {useLocation, useParams} from "react-router-dom";
import {ProductProxy} from '@me-team/host/main/graphql/types';
import {
  useGetProductForAddStorageLazyQuery
} from '@me-team/host/main/addNewProductList/graphql/addNewProductList.hooks';

const AddNewProductList: React.FC = () => {
    const { t } = useTranslation()
    const {id} = useParams();
    const location = useLocation();
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState();
    const [showFilters, setShowFilters] = useState(false);
    const [existingProduct, setExistingProduct] = useState(null)
    const initialValueFilter: ProductsFilterI = {
        nameSkuBarcode: null,
        categoryId: null,
        brand: null,
    }
    const [showDuplicateProductModal, setShowDuplicateProductModal] = useState<boolean>(false);
    const [productForChange, setProductForChange] = useState<ProductProxy>(null);
    const [filters, setFilters] = useState(initialValueFilter);
    const userRole = useReactiveVar(currentUserRoleVar);
    const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
    const handleAddWarehouseModal = (product?: ProductProxy) => {
        if (product) {
            setExistingProduct(product);
        }
        setShowModal(prevState => !prevState);
    }

    const [getProductForAddStorage, { data }] = useGetProductForAddStorageLazyQuery({
        context: {
          errorType: 'local',
        },
        onError: (error) => {
          console.error(error);
        },
    });

    const handleOpenDuplicateProductModal = async (productId: number) => {
        const { data } =await getProductForAddStorage({
          variables: { productId: productId },
        });
        const product: ProductProxy = data?.products?.items[0];
        product && setExistingProduct(product);
        setShowDuplicateProductModal(true)
    }

    const handleNavigateToAddProduct =  () => {
        existingProduct && handleAddWarehouseModal(existingProduct)
        setShowDuplicateProductModal(false)
    }
    const handleCloseDuplicateProductModal = () => {
      setProductForChange(existingProduct)
      setExistingProduct(null)
      setShowDuplicateProductModal(false)
      handleAddWarehouseModal()
    }

    const toggleFilters = () => setShowFilters(!showFilters);
    return (
        <>
            <VioletBadge
                text={t('Click “+” to add a product or “+ Create new product" to create and add.')}
            />
            <TableHeader
                title={t('Add products to the warehouse')}
                paths={[
                    { path: location?.pathname, label: t('Warehouse accounting') },
                    { path: "/warehouses", label: t('Warehouses') },
                    { path: `/warehouses/warehouses-goods/${currentBranchId}/${id}`, label: name },
                    { path: location?.pathname, label: t('Add products to the warehouse') },
                ]}

                colSize={9}
                btnColSize={3}
                buttons={
                    <Row
                        className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
                        {
                            !isEmployeeRole
                                ?
                                <Col
                                    xs={12}
                                    md={6}
                                    lg={7}
                                >
                                    <Button
                                        variant='primary'
                                        className='w-100 text-white mb-3 mb-lg-0 '
                                        onClick={() => handleAddWarehouseModal()}
                                    >
                                        <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                                        {t('Add Product')}
                                    </Button>
                                </Col>
                                : null
                        }
                        <Col
                            xs={12}
                            md={6}
                            lg={5}
                            className='px-0'
                        >
                            <FiltersButton filter={filters} showFilters={showFilters} onClick={toggleFilters} classes="mb-3 mb-lg-0"/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}
            >
                <ProductsFilterWrapper setFilters={setFilters} stylesAddNewProductList/>
            </TableHeader>
            <AddNewProductListTable
                filters={filters}
                handleModal={handleAddWarehouseModal}
                setName={setName}
                showModal={showModal}
                onHide={()=> ''}
                existingProduct={existingProduct}
                setExistingProduct={()=> setExistingProduct(null) }
                initialValueFilter={initialValueFilter}
                onDuplicateProductModal={handleOpenDuplicateProductModal}
                setProductForChange={setProductForChange}
                productForChange={productForChange}
            />
            <CustomModal
                show={showDuplicateProductModal}
                handleClose={handleCloseDuplicateProductModal}
                title={
                    <Container>
                        <Row>
                            <h5 className='m-0 p-0 fw-normal'>{t('The product already exists')}</h5>
                        </Row>
                    </Container>
                }
                body={
                    <Container>
                        <Row>
                            <Col>
                                <span>
                                  {t("This product is already in the system, to add it to the warehouse, click “OK”.")}
                                </span>
                            </Col>
                        </Row>
                    </Container>
                }
                buttons={[
                    {
                        text: t('Ok'),
                        onClick: handleNavigateToAddProduct,
                        variant: 'primary',
                        className: 'w-100 fw-normal',
                    }
                ]}
            />
        </>
    );
};

export default AddNewProductList;