import React, {useState} from 'react'
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {ModuleInputType, ModuleProxy} from "@me-team/host/main/graphql/types";
import Form from 'react-bootstrap/Form';
import default_module_img from '@me-team/host/src/images/default_module_img.png'
import {useTranslation} from "react-i18next";
import styles from '../ModulesListStyles.module.scss'
import {ModulesTagName, SubModulesTagName} from "../../../../../utils/enums";
import {useParams} from "react-router-dom";
import ModulesService from "../../../../../services/ModulesService/ModulesService";
import {DisableModuleDataInterface} from "../../../../../interfaces/interfaces";

type PersonalModuleProps = {
    modulesList: ModuleProxy[],
    updateModules: (companyId: number, updates: ModuleInputType[]) => Promise<void>;
    openDisableModal : (moduleData : DisableModuleDataInterface) => void;
}


const PersonalModule: React.FC<PersonalModuleProps> = (props) => {
    const {currentCompanyId} = useParams();
    const {t: translate} = useTranslation();
    const PERSONAL_MODULE: ModuleProxy = props.modulesList.find((module: ModuleProxy) => module.name === ModulesTagName.PERSONNEL);
    const LOGBOOK_MODULE: ModuleProxy = props.modulesList.find((module: ModuleProxy) => module.name === ModulesTagName.LOGBOOK);
    const SERVICES_MODULE: ModuleProxy = props.modulesList.find((module: ModuleProxy) => module.name === ModulesTagName.SERVICES);
    const basePath: string = process.env.REACT_APP_POS_URL;
    const imageSRC: string = PERSONAL_MODULE.image ? `${basePath}/build/images/modules/${PERSONAL_MODULE.image}` : default_module_img;
    const [isMainSwitchDisable, setIsMainSwitchDisable] = useState(false);
    const [isChildModuleDisabled, setIsChildModuleDisabled] = useState(false);
    const isSalaryModuleDisabled: (module: ModuleProxy) => boolean = (module: ModuleProxy): boolean =>
        module.name !== SubModulesTagName.SALARY || !LOGBOOK_MODULE.active || isChildModuleDisabled

    const onTogglePersonnelModule = async () => {
        setIsMainSwitchDisable(true);

        const updates: ModuleInputType[] = [
            { id: PERSONAL_MODULE.id, isActive: !PERSONAL_MODULE.active }
        ];

        if (PERSONAL_MODULE.active) {
            const disabledData: DisableModuleDataInterface = ModulesService.disabledModule(PERSONAL_MODULE, props.modulesList);

            if (disabledData.dependentModules.length !== 0) {
                props.openDisableModal(disabledData);
                setIsMainSwitchDisable(false);
                return;
            }
        } else {
            if (SERVICES_MODULE.active) {
                const serviceProvides = ModulesService.getChildModule(SERVICES_MODULE, SubModulesTagName.SERVICE_PROVIDES);
                updates.push({ id: serviceProvides.id, isActive: true });
            }
        }

        await props.updateModules(Number(currentCompanyId), updates);
        setIsMainSwitchDisable(false);
    };


    const onToggleSalaryModule = async (childModule: ModuleProxy) => {
        setIsChildModuleDisabled(true);
        await props.updateModules(Number(currentCompanyId), [
            { id: childModule.id, isActive: !childModule.active }
        ]);
        setIsChildModuleDisabled(false);
    };


    return (
        <div className={'border rounded p-4'}>
            <Row>
                <Col xl={2} xxl={2} className="mb-3 mb-xl-0">
                    <div className={!PERSONAL_MODULE.active ? 'opacity-75' : ""}>
                        <img className={`${styles.customImagesSize} d-block`} src={imageSRC}
                             alt={PERSONAL_MODULE.name}/>
                    </div>
                </Col>
                <Col xl={5} xxl={6} className="mb-3 mb-xl-0">
                    <div className={!PERSONAL_MODULE.active ? 'opacity-75' : ""}>
                        <h5 className={'fw-normal mb-2 fs-24'}>{translate(PERSONAL_MODULE.name)}</h5>
                        <p className={'mb-0 text-grey'}>{PERSONAL_MODULE.description}</p>
                    </div>
                </Col>
                <Col xs={12} sm={6}  md={6} xl={2} xxl={2} className={'d-flex justify-content-start justify-content-sm-center d-xl-block mb-3 mb-sm-0'}>
                    <Form className={!PERSONAL_MODULE.active ? 'opacity-75' : ""}>
                        <ul className="list-unstyled align-content-md-center">
                            {PERSONAL_MODULE.childModules.map((childModule: ModuleProxy, index: number) => (

                                <li
                                    className={`d-flex align-items-center ${
                                        index === PERSONAL_MODULE.childModules.length - 1 ? '' : 'mb-3'
                                    }`}
                                    key={childModule.id}
                                >
                                    <Form.Check
                                        type="switch"
                                        label={translate(childModule.name)}
                                        checked={childModule.active}
                                        disabled={!PERSONAL_MODULE.active ? true : isSalaryModuleDisabled(childModule)}
                                        className="mb-0 custom-module-switch"
                                        onChange={() => onToggleSalaryModule(childModule)}
                                    />
                                    {(childModule.name === SubModulesTagName.SALARY && !LOGBOOK_MODULE.active) && (
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id={`tooltip-${childModule.id}`}>
                                                    {translate('Will become available after connecting the \"Record Journal\" module.')}
                                                </Tooltip>
                                            }
                                        >
                                            <i className="bi bi-question-circle ms-2 text-grey cursor-pointer"></i>
                                        </OverlayTrigger>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </Form>
                </Col >
                <Col xs={12} sm={6}  md={6} xl={3} xxl={2} className={'d-flex flex-column justify-content-between align-items-center  align-items-xl-start'}>
                    <Form className={'d-flex align-items-center'}>
                        <Form.Check
                            type="switch"
                            label={PERSONAL_MODULE.active ? translate('Enabled') : translate('Disabled')}
                            checked={PERSONAL_MODULE.active}
                            disabled={isMainSwitchDisable}
                            className="mb-0 custom-module-switch"
                            onChange={onTogglePersonnelModule}
                        />
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id={`tooltip-${PERSONAL_MODULE.id}`}>
                                    {translate('You can disable/enable the module functionality at any time without canceling your subscription.')}
                                </Tooltip>
                            }
                        >
                            <i className="bi bi-question-circle ms-2 text-grey cursor-pointer"></i>
                        </OverlayTrigger>
                    </Form>
                    <h5 className={'mt-3 mt-sm-0'}>{translate('Free')}</h5>
                </Col>
            </Row>
        </div>
    )
}

export default React.memo(PersonalModule)