import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {
    EmptyList,
    Pagination,
    Spinner, TooltipCustom,
} from '@services/ui-components';

import {ApolloError} from '@apollo/client';
import ErrorService from '../../../services/ErrorService';
import {
    StorageProductRelationProxy
} from "@me-team/host/main/graphql/types";
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Button, Container, Form, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import RenderProductCategoryName from './RenderProductCategoryName';
import {useGetWarehousesGoodsQuery} from "@me-team/host/main/warehouses_goods/graphql/warehouses_goods.hooks";
import AddGoodsWarehouseModal from "../modals/AddGoodsWarehouseModal";
import WriteOffModal from "../modals/WriteOffModal";
import MoveGoodsModal from "../modals/MoveGoodsModal";
import DeleteGoodsModals from "../modals/DeleteGoodsModals";
import {useGetWarehousesQuery} from "@me-team/host/main/warehouses/graphql/warehouses.hooks";
import ToastComponent from "../../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../../hooks/useToast";
import {useReactiveVar} from "@apollo/client";
import { currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import {formatRemnantsForTooltip} from '../constants';
interface ProductsTableProps {
    filters: {
        brand: string,
        categoryId: number,
        nameSkuBarcode: string,
        stockStatus: string
    },
    setAddress: React.Dispatch<React.SetStateAction<string>>;
    filtersApplied: boolean
}

const WarehousesGoodsTable: React.FC<ProductsTableProps> = ({filters, setAddress, filtersApplied}) => {
    const {t} = useTranslation();
    const { branchId,  storageId} = useParams();
    const navigate = useNavigate();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const parentCheckboxRef = useRef<HTMLInputElement>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
    const [parentChecked, setParentChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const [markedForDeletionArray, setMarkedForDeletionArray] = useState<number[]>([]);
    const [showAddModal, setShowAddModal] = useState(false)
    const [showMoveModal, setShowMoveModal] = useState(false)
    const [showWriteOfModal, setShowWriteOfModal] = useState(false)
    const [chosenProduct, setChosenProduct] = useState<StorageProductRelationProxy>(null)
    const [modalDelete, setModalInfo] = useState<{ text?: string; type?: string } | null>(null);
    const [canDelete, setCanDelete] = useState([])
    const { showToast, toastText, setShowToast, toggleShowToast } = useToast();
    const handleDeleteModal = () => setModalInfo(null)
    const resetCurrentPage = () => {
        setCurrentPage(1);
    };

    useEffect(() => {
        resetCurrentPage();
    }, [filters?.nameSkuBarcode, filters?.categoryId, filters?.brand, filters?.stockStatus]);

    const {data: warehousesGoods, loading, refetch} = useGetWarehousesGoodsQuery({
        variables: {
            page: currentPage,
            itemsPerPage: itemsPerPage,
            nameSkuBarcode: filters?.nameSkuBarcode,
            categoryId: filters?.categoryId,
            brand: filters?.brand,
            stockStatus: filters?.stockStatus,
            branchesPaginatedId: +branchId,
            storagesPaginatedId: +storageId,
            companyId: +currentCompanyId,
        },
        skip: !currentCompanyId,
        context: {
            errorType: 'global'
        },
        onCompleted: async (data) => {
            setAddress(data?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages[0]?.name)
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
    });

    const { data, loading: loadingWarehouses, error,  } = useGetWarehousesQuery({
        variables: {
            page: 1,
            itemsPerPage: 10000,
            currentBranchId: +branchId,
            companyId: +currentCompanyId,
        },
        skip: !currentCompanyId || !branchId,
        context: {
            errorType: 'global',
        },
    })
    const storageProducts = warehousesGoods?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages[0]?.storageProducts?.storageProductRelations;
    const warehouses = data?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages
    const pageCount = warehousesGoods?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages[0]?.storageProducts?.pageCount
    const totalItems = warehousesGoods?.user?.company?.branchesPaginated?.branches[0]?.storagesPaginated?.storages[0]?.storageProducts?.totalCount
    const writeOffReasons = warehousesGoods?.relations?.writeOffReasons?.map(i => ({
        value: i?.id,
        label: i?.name
    }))

    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

    useEffect(() => {
        if (markedForDeletionArray.length === 0) {
            setParentChecked(false);
            setIndeterminate(false);
        } else if (markedForDeletionArray.length === storageProducts?.length) {
            setParentChecked(true);
            setIndeterminate(false);
        } else {
            setParentChecked(false);
            setIndeterminate(true);
        }

        if (parentCheckboxRef.current) {
            parentCheckboxRef.current.indeterminate = indeterminate;
        }
    }, [markedForDeletionArray, indeterminate]);

    const handleChildCheckboxChange = (id: number) => {
        setMarkedForDeletionArray((prevMarkedForDeletionArray) =>
            prevMarkedForDeletionArray.includes(id)
                ? prevMarkedForDeletionArray.filter((markedId) => markedId !== id)
                : [...prevMarkedForDeletionArray, id]
        );
    };

    const handleParentCheckboxChange = () => {
        if (indeterminate) {
            setMarkedForDeletionArray([]);
            setParentChecked(false);
        } else if (parentChecked) {
            setMarkedForDeletionArray([]);
        } else {
            setMarkedForDeletionArray(storageProducts?.filter((product) => !product.deleted).map((product) => product.id) || []);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    const handleAddModal = (product?: StorageProductRelationProxy) => {
        setChosenProduct(product)
        setShowAddModal(prevState => !prevState)
    }
    const handleMoveModal = (product?: StorageProductRelationProxy) => {
        setChosenProduct(product)
        setShowMoveModal(prevState => !prevState)
    }
    const handleWriteOfModal = (product?: StorageProductRelationProxy) => {
        setChosenProduct(product)
        setShowWriteOfModal(prevState => !prevState)
    }

    const transformUnitsForSelect = () => {
        return warehouses
            ?.filter(i => !i?.deleted)
            .map(i => ({
                value: i?.id,
                label: i?.name
            }));
    };

    const handleShowModalMultipleDelete = async () => {
        const cannotDelete: typeof storageProducts = [];
        const canDelete: number[] = [];

        markedForDeletionArray.forEach(id => {
            const product = storageProducts.find(item => item.id === id);
            if (product) {
                if (product.quantity > 0) {
                    cannotDelete.push(product);
                } else {
                    canDelete.push(product?.id);
                }
            }
        });
        setCanDelete(canDelete)
        let result;
        if (canDelete.length === 0) {
            result = {
                text: 'Вибраний товар(и) неможливо видалити, оскільки їх кількість більше 0.',
                type: 'deleteNotPossible',
            };
        } else if (cannotDelete.length === 0) {
            result = {
                text: 'Вибраний товар(и) буде видалено. Ви підтверджуєте цю операцію?',
                type: 'confirmDelete',
            };
        } else {
            result = {
                text: `${canDelete.length} ${t('out of')} ${canDelete.length + cannotDelete.length} ${t('will be deleted')}.
                 ${cannotDelete.length}  ${t('product(s) cannot be deleted')}. ${t('In order to delete products, you must write off their remaining inventory')}.`,
                type: 'partialDelete',
            };
        }
        setModalInfo(result)
        return result;
    }
    if ( loading || loadingWarehouses || !currentCompanyId) return <Spinner/>;
    return (
        <>
            <div className='col-12 table-responsive scrollbar position-relative'>
                {
                    <table className='table table-borderless'>
                        <thead>
                        <tr className='fs-7'>
                            <th className='py-2 col-1 ps-4 pe-2 align-middle' style={{minWidth: '106px'}}>
                                <div className='d-flex align-items-center gap-3'>
                                    <Form.Check
                                        type="checkbox"
                                        ref={parentCheckboxRef}
                                        checked={parentChecked}
                                        onChange={handleParentCheckboxChange}
                                    />
                                    {markedForDeletionArray.length > 0 ?
                                        <TooltipCustom placement='right' text={t('Delete Product(s)')}>
                                            <Button
                                                variant='outline-danger'
                                                className='border-0 fs-16 p-0'
                                                onClick={handleShowModalMultipleDelete}
                                            >
                                                <i className="bi bi-trash3 icon-multi-delete"></i>
                                            </Button>
                                        </TooltipCustom>
                                        : null
                                    }
                                </div>
                            </th>
                            <th className='py-3 col-2 px-2 align-middle'>
                                {t('Product name')}
                            </th>
                            <th className='py-3 px-2 col-1 align-middle'>
                                {t('ProductsList.Brand')}
                            </th>
                            <th className='py-3 px-2 col-2 align-middle'>
                                {t('ProductsList.Category')}
                            </th>
                            <th className='py-3 px-2 col-1 align-middle'>
                                {t('ProductsList.SKU')}
                            </th>
                            <th className='py-3 px-2 col-1 align-middle'>
                                {t('ProductsList.Barcode')}
                            </th>
                            <th className='py-3 px-2 col-2 align-middle text-start'>
                                {t('Remnants')}
                            </th>
                            <th className='py-3 px-2 pe-0 col-1 align-middle'></th>
                        </tr>
                        </thead>
                        <tbody>
                        {storageProducts?.map((product: StorageProductRelationProxy, index) => (
                            <tr key={index + product?.id} className='border-bottom'>
                                <td className='align-middle ps-4 py-4 pe-2'>
                                    <Form.Check
                                        type="checkbox"
                                        checked={markedForDeletionArray.includes(product.id)}
                                        onChange={() => handleChildCheckboxChange(product.id)}
                                    />
                                </td>
                                <td className='align-middle  pe-2'>
                                    <Link to={`/warehouse/product/${currentCompanyId}/${product?.product?.id}/edit-product`}
                                              className={`text-decoration-none text-primary`}>
                                        {product?.product?.name}
                                    </Link>
                                </td>
                                <td className='align-middle  pe-2'>{product?.product?.brand}</td>
                                <td className='align-middle pe-2'>
                                    <RenderProductCategoryName
                                        category={product?.product?.productCategoryNew}
                                        isArchive={product?.product?.deleted}
                                    />
                                </td>
                                <td className='align-middle  pe-2'>{product?.product?.sku}</td>
                                <td className='align-middle  pe-2'>{product?.product?.barcode}</td>
                                <td className='align-middle  pe-2'>
                                    {product?.quantityForRender}
                                    {product?.product?.alternativeUnit?.name ?
                                        <TooltipCustom
                                            placement="right"
                                            text={formatRemnantsForTooltip(
                                                product?.product?.alternativeValue,
                                                product?.product?.alternativeUnit?.name,
                                                product?.stockQuantity
                                            )}
                                        >
                                            <i className="bi bi-info-circle ms-3"></i>
                                        </TooltipCustom>
                                        : null
                                    }
                                </td>
                                <td className='align-middle'>
                                    <div className="d-flex justify-content-end">
                                        <OverlayTrigger overlay={<Tooltip>{t('Add Product')}</Tooltip>}>
                                            <Button
                                                variant='outline-secondary'
                                                className="outline-secondary text-dark m-1 p-0"
                                                onClick={() => handleAddModal(product)}
                                                style={{height: '39px', width: '39px'}}
                                            >
                                                <i className="bi bi-plus-lg"></i>
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger overlay={<Tooltip>{t('Write-off product')}</Tooltip>}>
                                            <Button
                                                variant='outline-secondary'
                                                className="outline-secondary text-dark m-1 p-0"
                                                onClick={() => handleWriteOfModal(product)}
                                                style={{height: '39px', width: '39px'}}
                                            >
                                                <i className="bi bi-dash"></i>
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger overlay={<Tooltip>{t('Move product')}</Tooltip>}>
                                            <Button
                                                variant='outline-secondary'
                                                className="outline-secondary text-dark m-1 p-0"
                                                onClick={() => handleMoveModal(product)}
                                                style={{height: '39px', width: '39px'}}
                                            >
                                                <i className="bi bi-reply"></i>
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                }
            </div>
            {storageProducts?.length === 0 && (
                filtersApplied ? (
                    <EmptyList title={t('No products were found for the specified values in the filters or search.')} />
                ) : (
                    <Container className="mt-5">
                        <Row className="mt-5">
                            <Col xs={12}>
                                <h4 className="head-personal-data text-center fw-normal pt-5 mt-5">
                                    {t('The products are out of stock. Please add products.')}
                                </h4>
                                <div className="text-center mt-3">
                                    <Button
                                        className="px-4"
                                        onClick={() => navigate('/warehouses/add-product/' + storageId)}
                                    >
                                        <i className="bi bi-plus-lg fw-bold me-1"></i> {t('Add Product')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )
            )}
            {storageProducts?.length !== 0 ? (
                <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
                    <Pagination
                        t={t}
                        pages={Number(pageCount)}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        totalItems={totalItems}
                    />
                </div>
            ) : null}

            {isErrorModalOpen ?
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                /> : null
            }
            <DeleteGoodsModals
                modalState={modalDelete}
                handleModal={handleDeleteModal}
                productsForDeleting={canDelete}
                refetch={refetch}
                toggleShowToast={toggleShowToast}
            />
            <AddGoodsWarehouseModal
                show={showAddModal}
                handleModal={handleAddModal}
                chosenProduct={chosenProduct}
                refetch={refetch}
                toggleShowToast={toggleShowToast}
            />
            <WriteOffModal show={showWriteOfModal}
                           handleModal={handleWriteOfModal}
                           refetch={refetch}
                           chosenProduct={chosenProduct}
                           writeOffReasons={writeOffReasons}
                           toggleShowToast={toggleShowToast}
            />

            <MoveGoodsModal show={showMoveModal} refetch={refetch} handleModal={handleMoveModal}
                            chosenProduct={chosenProduct} warehouses={transformUnitsForSelect()}
                            storageId={+storageId}
                            toggleShowToast={toggleShowToast}
            />
            {showToast && <ToastComponent show={showToast} setShow={setShowToast} text={toastText} />}
        </>
    );
};

export default WarehousesGoodsTable;