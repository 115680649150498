import React, {useState} from 'react';
import {ColumnConfig, ReportsFilter} from './types';
import {Button} from 'react-bootstrap';
import {Pagination, Spinner} from '@services/ui-components';
import RenderProductCategoryName from '../../Products/ProductsList/RenderProductCategoryName';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {ApolloError} from '@apollo/client';
import ErrorService from '../../../services/ErrorService';
import {useGetStorageReportsQuery} from '@me-team/host/main/reports/graphql/reports.hooks';
import {StorageReportItemProxy, StorageReportType} from '@me-team/host/main/graphql/types';
import {getColumns, getTotalQuantity} from './constants';
import RenderTotalQuantity from './components/RenderTotalQuantity';
import OperationsTable from './OperationsTable';
import EmptyList from '@services/ui-components/src/EmptyList/EmptyList';
import ReportExportWrapper from './ReportExportWrapper';

interface ReportsTableProps {
  filters: ReportsFilter
}

const ReportsTable: React.FC<ReportsTableProps> = ({filters}) => {
  const {t} = useTranslation();
  const isMainFilter = !!(filters.startDate && filters.endDate && filters.reportType);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const handleToggleRow = (id: number) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [id]: !prevExpandedRows[id],
    }));
  };

  const {
    data: storageReportsData,
    loading: storageReportsLoading,
    refetch: refetchStorageReports
  } = useGetStorageReportsQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      startDate: filters.startDate,
      endDate: filters.endDate,
      reportType: filters.reportType,
      categoryId: filters?.categoryId ? filters?.categoryId : null,
      brand: filters?.brand ? filters?.brand : null,
      sku_barcode: filters?.skuBarcode ? filters?.skuBarcode : null,
      warehouseId: filters?.warehouseId ? filters?.warehouseId : null,
      employeeId: filters?.employeeId ? filters?.employeeId : null,
    },
    skip: !filters.startDate || !filters.endDate || !filters.reportType,
    context: {
      errorType: 'global'
    },
    onCompleted: () => {
      setExpandedRows({})
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const reports: StorageReportItemProxy[] = storageReportsData?.storageReports?.items || null;
  const pageCount = parseInt(storageReportsData?.storageReports.pageCount || '0');
  const totalItems = storageReportsData?.storageReports?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
  const columns: ColumnConfig[] = getColumns(filters?.reportType);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (storageReportsLoading) return <Spinner/>;

  return (
    <>
      {isMainFilter ? <ReportExportWrapper filters={filters} reports={reports}/> : null}
      <div className='col-12 table-responsive scrollbar position-relative'>
        {
          !!reports?.length ? (
            <table className='table table-borderless'>
              <thead>
              <tr className='fs-7'>
                {columns?.map((col) => (
                  <th key={col.key}
                      className={`py-3 px-2 align-middle line-height-140 ${col.className || ""}`}>
                    {t(col.label)}
                  </th>
                ))}
                <th className='py-3 px-2 pe-0 col-1 align-middle'></th>
              </tr>
              </thead>
              <tbody>
              {reports?.map((report) => {
                const isArchiveProduct = report.product.deleted;
                const isArchiveCategory = report?.product?.productCategoryNew?.deleted;
                const colorForArchiveProduct = isArchiveProduct ? 'text-grey' : '';
                const totalQuantityForFirstColumn: string = getTotalQuantity(report, filters.reportType);

                return (
                  <React.Fragment key={report.product.id}>
                    <tr key={report.product.id}>
                      <td className='align-middle py-4 ps-4 pe-2 line-height-140'>
                        <span className={colorForArchiveProduct}>
                          {report.product.name} {isArchiveProduct ? t('(Archive)') : null}
                        </span>
                      </td>
                      <td className='align-middle py-4 px-2 line-height-140'>
                        <span>
                          {report.product.brand}
                        </span>
                      </td>
                      <td className='align-middle py-4 px-2 line-height-140'>
                        <RenderProductCategoryName
                          category={report.product.productCategoryNew}
                          isArchive={isArchiveCategory}
                          archiveColor='text-grey'
                        />
                      </td>
                      <td className='align-middle py-4 px-2 line-height-140'>
                        <span>
                          {report.product.sku ? report.product.sku : '—'}
                        </span>
                      </td>
                      <td className='align-middle py-4 px-2 line-height-140'>
                        <span>
                          {report.product.barcode ? report.product.barcode : '—'}
                        </span>
                      </td>
                      <td className='align-middle py-4 px-2 line-height-140'>
                        <span>
                          {totalQuantityForFirstColumn ?
                            <RenderTotalQuantity totalQuantity={totalQuantityForFirstColumn}/> : '—'
                          }
                        </span>
                      </td>
                      {filters.reportType === StorageReportType.ArrivalWriteOff ?
                        <td className='align-middle py-4 px-2 line-height-140'>
                          <span>
                            {report.totalWriteOffQuantity ?
                              <RenderTotalQuantity
                                totalQuantity={report.totalWriteOffQuantity}/> : '—'
                            }
                          </span>
                        </td> : null
                      }
                      <td className='align-middle ps-2 pe-0 py-1 text-end'>
                        <Button
                          variant='outline-grey'
                          style={{width: '39px', height: "39px"}}
                          className='p-1 bg-transparent'
                          onClick={() => handleToggleRow(report.product.id)}
                        >
                          <i
                            className={`bi bi-chevron-${expandedRows[report.product.id] ? 'up' : 'down'} text-dark fs-16`}></i>
                        </Button>
                      </td>
                    </tr>
                    {expandedRows[report.product.id] && (
                      <tr className='text-center'>
                        <td colSpan={12} className='p-0'>
                          <div
                            className="overflow-auto w-100"
                            style={{maxHeight: "300px"}}
                          >
                            <OperationsTable
                              operations={report?.operations}
                              reportType={filters.reportType}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={12} className='p-0'>
                        <hr className="w-100 m-0 border-grey"/>
                      </td>
                    </tr>
                  </React.Fragment>
                )
              })}
              </tbody>
            </table>
          ) : null
        }
      </div>

      {!!totalItems ? (
        <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
          <Pagination
            t={t}
            pages={Number(pageCount)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            firstItemIndex={firstItemIndex}
            lastItemIndex={lastItemIndex}
            totalItems={totalItems}
          />
        </div>
      ) : null
      }

      {!totalItems && isMainFilter ?
        <EmptyList title='No products were found for the specified values in the filters.'/>
        : null
      }

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default ReportsTable;