import React, {useEffect, useState} from 'react';
import {Container, Row} from 'react-bootstrap';
import {TableHeader} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import TariffPlanFilter from '../TariffPlanFilter';
import InfoFooter from '../InfoFooter';
import TariffCard from './TariffCard';
import {getInitialPlans, getPrice, getSecondWord} from '../constants';
import {Filter, Plan} from '../interface';
import ErrorService from '../../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {UserRole} from '../../../utils/enums';
import {useNavigate} from 'react-router-dom';
import { TariffPeriod } from '@me-team/host/main/graphql/types';
import {
  GetTariffPlansQuery,
  useGetPaidFeaturesQuery,
  useGetTariffPlansQuery
} from '@me-team/host/main/tariffPlans/graphql/tariffPlans.hooks';

const TariffPlans: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isAdminRole = +userRole === UserRole.ADMINISTRATOR;
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [getTariffPlans, setTariffPlans] = useState<GetTariffPlansQuery>(null);
  const [filter, setFilter] = useState<Filter>({
    currency: {value: 'uah', label: '₴'},
    period: 'YEARLY',
  });

  useEffect(() => {
    if (isAdminRole || isEmployeeRole) {
      navigate("/error/403", {replace: true} )
    }
  }, [isAdminRole, isEmployeeRole]);

  const period: TariffPeriod = filter.period as TariffPeriod;

  const {
    data: getTariffPlansData,
    loading: getTariffPlansLoading,
    refetch: refetchTariffPlans
  } = useGetTariffPlansQuery({
    variables: {period: period},
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  useEffect(() => {
    setTariffPlans(getTariffPlansData)
  }, [getTariffPlansData]);

  const {
    data: subscriptionsData,
    loading: loadingSubscriptions
  } = useGetPaidFeaturesQuery({
    variables: { companyId: +currentCompanyId },
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  });

  const currentSubscriptions = subscriptionsData?.user?.company?.paidFeatures.find((subscription) => (
      subscription?.status === 'ACTIVE' && subscription?.name
    )
  )

  const handleFilterChange = (newFilter: Filter) => {
    setFilter(newFilter);
  };

  const handleTariffPlansChange = (newTariffPlans: GetTariffPlansQuery) => {
    setTariffPlans(newTariffPlans)
  };

  const [plans, setPlans] = useState<Plan[]>(getInitialPlans(t, filter.currency.label))
  const isCurrentTariff = Array.isArray(plans) && plans.some(tariff => tariff.currentPlan === true);

  const currencyForDependence = filter?.currency
  useEffect(() => {
    if (getTariffPlans) {
      setPlans(prevPlans => {
        return prevPlans.map(plan => {
          const planData = getTariffPlans?.planPrice?.find(p => p.planName.split('_')[0] === plan.planNameForFind);
          const currentNamePlan = currentSubscriptions && getSecondWord(currentSubscriptions?.name)
          const currency = filter?.currency?.value;
          const period = filter?.period;
          if (planData) {
            return {
              ...plan,
              planId: planData?.planId,
              price: getPrice(currency, period, planData),
              currency: filter?.currency?.label,
              period: period,
              currentPlan: currentNamePlan === plan.planNameForFind
            };
          }

          return {
            ...plan,
            currency: filter?.currency?.label,
            period: period,
            currentPlan: currentNamePlan === plan.planNameForFind
          };
        });
      });
    }
  }, [getTariffPlans, currencyForDependence, currentSubscriptions]);

  if (loadingSubscriptions || !currentCompanyId || !userRole) return <Spinner/>;

  return (
    <Container fluid className='mx-0 mb-5 px-0'>
      <TableHeader
        title={t('Tariff Plans')}
        paths={[
          {
            path: `/payment/company/${currentCompanyId}/tariff`,
            label: t('Management of Tariffs')
          },
          {
            path: `/payment/company/${currentCompanyId}/tariff`,
            label: t('Tariff Plans')
          },
        ]}
      />

      <TariffPlanFilter
        onFilterChange={handleFilterChange}
        onTariffPlans={handleTariffPlansChange}
      />

      <Row
        className="d-flex gap-4 gap-lg-0 flex-wrap flex-lg-nowrap mt-4 pt-2 overflow-auto scrollbar">
        {plans.map(plan => (
          <TariffCard
            key={plan.id}
            planId={plan.planId}
            isCurrentTariff={isCurrentTariff}
            planNameForFind={plan.planNameForFind}
            planName={t(plan.planName)}
            tariff={plan.planNameForFind}
            price={plan.price}
            currency={plan.currency}
            features={plan.features}
            currentPlan={plan.currentPlan}
            period={plan.period}
          />
        ))}
      </Row>
      <InfoFooter/>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </Container>
  );
}

export default TariffPlans;
