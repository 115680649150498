import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {
  Pagination,
  RenderEmptyList,
  Spinner,
  TooltipCustom
} from '@services/ui-components';
import {ProductProxy} from '@me-team/host/main/graphql/types';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {
  useAddProductTechMapMutation,
  useGetProductsQuery, useRemoveProductsFromTechMapMutation
} from '@me-team/host/main/products/graphql/products.hooks';
import ErrorService from '../../../services/ErrorService';
import {AddProductToTechMapFilter} from '../types';
import RenderCategoryName from '@services/ui-components/src/RenderCategoryName/RenderCategoryName';

interface AddProductToTechMapTableProps {
  filters: AddProductToTechMapFilter;
}

const AddProductToTechMapTable: React.FC<AddProductToTechMapTableProps> = ({filters}) => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const navigate = useNavigate();
  const {techMapId} = useParams()
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const isFilterNotEmpty = Object.values(filters).some(value => value !== '' && value !== null)

  const [addProductTechMap, {loading : addProductTechMapLoading}] = useAddProductTechMapMutation({
    context: {
      errorType: 'local',
    },
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  });
  const [removeProductsFromTechMap, {loading: removeProductsFromTechMapLoading}] = useRemoveProductsFromTechMapMutation({
    context: {
      errorType: 'local',
    },
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  });

  const resetCurrentPage = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    resetCurrentPage();
  }, [filters?.nameSkuBarcode, filters?.categoryId, filters?.brand]);

  const {
    data: productsData,
    loading: productsDataLoading,
    refetch: refetchProducts
  } = useGetProductsQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      nameSkuBarcode: filters?.nameSkuBarcode ? filters?.nameSkuBarcode : null,
      categoryId: filters?.categoryId ? filters?.categoryId : null,
      brand: filters?.brand ? filters?.brand : null,
      deleted: false,
      techMapId: +techMapId
    },
    context: {
      errorType: 'global'
    },
    fetchPolicy: "cache-and-network",
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  });

  const products = productsData?.products?.items
  const pageCount = parseInt(productsData?.products?.pageCount || '0');
  const totalItems = productsData?.products?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);

  const handlerAddProduct = async (productId: number) => {
    await addProductTechMap({
      variables: {
        productId: productId,
        techMapId: +techMapId,
      },
      onCompleted: () => {
        refetchProducts()
      }
    })
  }

  const handlerRemoveProduct = async (relationId: number) => {
    await removeProductsFromTechMap({
      variables: {
        relationIds: [relationId]
      },
      onCompleted: () => {
        refetchProducts()
      }
    })
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (productsDataLoading || addProductTechMapLoading || removeProductsFromTechMapLoading) return <Spinner/>;

  return (
    <>
      <div className='col-12 table-responsive scrollbar position-relative'>
        {
          !!products?.length ? (
            <table className='table table-borderless'>
              <thead>
              <tr className='fs-7'>
                <th className='py-3 col-3 px-2 align-middle line-height-140'>
                  {t('Product Name')}
                </th>
                <th className='py-3 px-2 col-2 align-middle line-height-140'>
                  {t('ProductsList.Brand')}
                </th>
                <th className='py-3 px-2 col-2 align-middle line-height-140'>
                  {t('ProductsList.Category')}
                </th>
                <th className='py-3 px-2 col-1 align-middle line-height-140'>
                  <span>{t('ProductsList.SKU')}</span>
                </th>
                <th className='py-3 px-2 col-1 align-middle line-height-140'>
                  {t('ProductsList.Barcode')}
                </th>
                <th className='py-1 px-2 col-2 align-middle text-start line-height-140'>
                  <span>{t('ProductsList.Unit')}</span>
                </th>
                <th className='py-3 px-2 pe-0 col-1 align-middle'></th>
              </tr>
              </thead>
              <tbody>
              {products.map((product: ProductProxy) => {
                const isProductTechCardConnected = product.technologicalMapProductRelations.length > 0;
                return (
                  <tr
                    key={product?.id}
                    className='border-bottom'>
                    <td className='align-middle py-3 px-2 line-height-140'>
                      <Link
                        to={`/warehouse/product/${currentCompanyId}/${product?.id}/edit-product`}
                        className={`text-decoration-none text-primary`}
                      >
                        {product?.name}
                      </Link>
                    </td>
                    <td className='align-middle py-3 px-2 line-height-140'>
                      {product?.brand}
                    </td>
                    <td className='align-middle py-3 px-2 line-height-140'>
                      <RenderCategoryName category={product?.productCategoryNew}/>
                    </td>
                    <td className='align-middle py-3 px-2 line-height-140'>
                      {product.sku ? product.sku : '—'}
                    </td>
                    <td className='align-middle py-3 px-2 line-height-140'>
                      {product?.barcode ? product?.barcode : '—'}
                    </td>
                    <td className='align-middle py-3 px-2 line-height-140'>
                      {product?.unit?.name}
                    </td>
                    <td className='align-middle ps-2 pe-0 py-3 line-height-140'>
                      <div className='d-flex align-items-center justify-content-end'>
                        {isProductTechCardConnected ?
                          <TooltipCustom placement='bottom' text={t('Remove product')}>
                            <Button
                              variant='outline-orange'
                              className='btn-square d-flex align-items-center justify-content-center p-1 bg-additional-orange'
                              style={{width: '40px', height: '40px'}}
                              onClick={() => handlerRemoveProduct(product.technologicalMapProductRelations?.[0]?.id)}
                            >
                              <i className="bi bi-dash-lg fs-16 text-orange"></i>
                            </Button>
                          </TooltipCustom>
                          :
                          <TooltipCustom placement='bottom' text={t('Add product')}>
                            <Button
                              variant='outline-green'
                              className='btn-square d-flex align-items-center justify-content-center p-1 bg-additional-green'
                              style={{width: '40px', height: '40px'}}
                              onClick={() => handlerAddProduct(product?.id)}
                            >
                              <i className="bi bi-plus-lg fs-16 text-green"></i>
                            </Button>
                          </TooltipCustom>
                        }
                      </div>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          ) : null
        }
      </div>

      {!!totalItems ? (
          <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
            <Pagination
              t={t}
              pages={Number(pageCount)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              firstItemIndex={firstItemIndex}
              lastItemIndex={lastItemIndex}
              totalItems={totalItems}
            />
          </div>
        ) :
        <RenderEmptyList
          isFilterNotEmpty={isFilterNotEmpty}
          visibilityBtn={false}
          emptyFilterTitle='No products were found for the specified values in the filters or search.'
          emptyListTitle='Products not yet created. Add a product.'
          onClickButton={() => navigate(`/warehouse/${currentCompanyId}/new-product`)}
          buttonName={
            <span>
                <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
              {t('Add Product')}
              </span>
          }
        />
      }

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default AddProductToTechMapTable;