import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Pagination,
  QRCodeDownload,
  Spinner,
  TooltipCustom,
  TooltipPopover
} from '@services/ui-components';
import {Button, Col, Row} from 'react-bootstrap';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {
  currentBranchIdVar,
  currentCompanyIdVar,
  currentUserRoleVar
} from '@me-team/host/src/apollo/globalVar/state';
import ErrorService from '../../services/ErrorService';
import Form from 'react-bootstrap/Form';
import {Link, useLocation} from 'react-router-dom';
import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import ToastComponent from "../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../hooks/useToast";
import {
  useGetOnlineBookingsConfigQuery,
  useUpdateBranchForOnlineBookingMutation,
  useUpdateMeBookingModuleConfigMutation
} from '@me-team/host/main/onlineBookings/graphql/onlineBookings.hooks';
import { BranchInput, BranchProxy, CompanyInput } from '@me-team/host/main/graphql/types';
import JoyrideWrapper from "../Joyride/JoyrideWrapper";
import SuccessfulModal from "../Joyride/modals/SuccessfulModal";
import {UserRole} from '../../utils/enums';

const basePath = process.env.REACT_APP_POS_URL
const bookingBasePath = process.env.REACT_APP_BOOKING_URL

const OnlineBookingSettingsTable: React.FC = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE
  const [, copy] = useCopyToClipboard();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const runJoy = localStorage.getItem('run') === 'true';
  const step = localStorage.getItem('step') || '0';
  const [stepIndex, setStepIndex] = useState(+step);
  const [run, setRun] = useState(runJoy);
  const [modalJoyride, setModalJoyride] = useState(false);
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const { showToast, toastText, toggleShowToast, setShowToast } = useToast();
  const checkRefneedsClientRecall = useRef(null);

  const handleLabelClick = () => {
    if (checkRefneedsClientRecall.current) {
      checkRefneedsClientRecall.current.focus();
    }
  };

  const {
    data: onlineBookingSettingsData,
    loading,
    refetch: refetchOnlineBookingSettings
  } = useGetOnlineBookingsConfigQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      companyId: +currentCompanyId,
    },
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  })
  const [updateCompany] = useUpdateMeBookingModuleConfigMutation()
  const [updateBranch] = useUpdateBranchForOnlineBookingMutation()

  const company = onlineBookingSettingsData && onlineBookingSettingsData?.user?.company
  const branches = onlineBookingSettingsData?.user?.company?.branchesPaginated?.branches
  const pageCount = parseInt(company?.branchesPaginated?.pageCount || '0');
  const totalItems = company?.branchesPaginated?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
  const [confirmationCall, setConfirmationCall] = useState<boolean>(false);
  const [onlineRegistrationsCompany, setOnlineRegistrationsCompany] = useState<boolean>(false);
  const [onlineRegistrationsBranch, setOnlineRegistrationsBranch] = useState<{
    [key: number]: boolean
  }>({});
  const initialConfirmationCallRef = useRef<boolean>(false);
  const initialOnlineRegistrationsCompanyRef = useRef<boolean>(false);
  const initialOnlineRegistrationsBranchRef = useRef<{ [key: number]: boolean }>({});

  const confirmationCallRef = useRef<boolean>(confirmationCall);
  const onlineRegistrationsCompanyRef = useRef<boolean>(onlineRegistrationsCompany);
  const onlineRegistrationsBranchRef = useRef<{ [key: number]: boolean }>({});
  const modifiedBranchesRef = useRef<Set<number>>(new Set());

  useEffect(() => {
    if (!loading && company && branches) {
      const initialConfirmationCall = company.meBookingModuleConfig?.confirmationCall ?? false;
      const initialOnlineRegistrationsCompany = company.meBookingModuleConfig?.onlineBooking ?? false;
      const initialBranchRegistrations = branches.reduce((acc: Record<number, boolean>, branch) => {
        acc[branch.id] = branch.onlineRegistration;
        return acc;
      }, {});

      setConfirmationCall(initialConfirmationCall);
      setOnlineRegistrationsCompany(initialOnlineRegistrationsCompany);
      setOnlineRegistrationsBranch(initialBranchRegistrations);

      initialConfirmationCallRef.current = initialConfirmationCall;
      initialOnlineRegistrationsCompanyRef.current = initialOnlineRegistrationsCompany;
      initialOnlineRegistrationsBranchRef.current = initialBranchRegistrations;

      confirmationCallRef.current = initialConfirmationCall;
      onlineRegistrationsCompanyRef.current = initialOnlineRegistrationsCompany;
      onlineRegistrationsBranchRef.current = initialBranchRegistrations;
    }
  }, [loading, company, branches]);

  useEffect(() => {
    confirmationCallRef.current = confirmationCall;
  }, [confirmationCall]);

  useEffect(() => {
    onlineRegistrationsCompanyRef.current = onlineRegistrationsCompany;
  }, [onlineRegistrationsCompany]);

  useEffect(() => {
    onlineRegistrationsBranchRef.current = onlineRegistrationsBranch;
  }, [onlineRegistrationsBranch]);

  const hasCompanyChanges = useCallback(() => {
    return (
      confirmationCallRef.current !== initialConfirmationCallRef.current ||
      onlineRegistrationsCompanyRef.current !== initialOnlineRegistrationsCompanyRef.current
    );
  }, []);

  const hasBranchChanges = useCallback(() => {
    for (const key in onlineRegistrationsBranchRef.current) {
      if (
        onlineRegistrationsBranchRef.current[key] !==
        initialOnlineRegistrationsBranchRef.current[key]
      ) {
        return true;
      }
    }
    return false;
  }, []);

  const handleCompanyMutationChange = useCallback(async () => {
    if (!hasCompanyChanges()) return;

    const updatedInput: CompanyInput = {
      name: company?.name,
      country: company?.country?.id,
      address: company?.address,
      phone: company?.phone,
      timezone: company?.timeZone?.id,
      messageLanguage: company?.messageLanguage?.id,
      currency: company?.currency?.id,
      deleted: company?.deleted,
      category: company?.category?.type,
      needsClientRecall: confirmationCallRef.current,
      onlineBooking: onlineRegistrationsCompanyRef.current,
    };

    await updateCompany({
      variables: {id: +currentCompanyId, input: updatedInput},
      onCompleted: (data) => {
        if (data) toggleShowToast(t('Saved'));
        initialConfirmationCallRef.current = confirmationCallRef.current;
        initialOnlineRegistrationsCompanyRef.current = onlineRegistrationsCompanyRef.current;
      },
      context: {
        errorType: 'local'
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error));
      },
    });
  }, [updateCompany, hasCompanyChanges, hasCompanyChanges, company]);

  const handleBranchMutations = useCallback(async () => {
    if (modifiedBranchesRef.current.size === 0) return;

    const branchesToUpdate = branches?.filter(branch => modifiedBranchesRef.current.has(branch.id));

    if (branchesToUpdate && branchesToUpdate.length > 0) {
      for (const branch of branchesToUpdate) {
        const input: BranchInput = {
          name: branch.name,
          type: branch.type.slug,
          category: branch.category.type,
          country: +branch.country?.id,
          address: branch.address,
          phones: branch.phones[0]?.phone ? [{phone: branch.phones[0].phone}] : null,
          onlineBooking: onlineRegistrationsBranchRef.current[branch.id],
          workSchedule: branch.workSchedule,
        };

        await updateBranch({
          variables: {id: branch.id, input, restore: false},
          context: {
            errorType: 'local',
          },
          onCompleted: (data) => {
            initialOnlineRegistrationsBranchRef.current[branch.id] = onlineRegistrationsBranchRef.current[branch.id];
            refetchOnlineBookingSettings();
          },
          onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error as ApolloError));
          }
        });
      }
      modifiedBranchesRef.current.clear();
      toggleShowToast(t('Saved'));
    }
  }, [branches, updateBranch, refetchOnlineBookingSettings]);

  useEffect(() => {
    return () => {
      handleCompanyMutationChange();
      handleBranchMutations();
    };
  }, [location.pathname, company, branches]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (hasCompanyChanges()) {
        const updatedInput: CompanyInput = {
          name: company?.name,
          country: company?.country?.id,
          address: company?.address,
          phone: company?.phone,
          timezone: company?.timeZone?.id,
          messageLanguage: company?.messageLanguage?.id,
          currency: company?.currency?.id,
          deleted: company?.deleted,
          category: company?.category?.type,
          needsClientRecall: confirmationCallRef.current,
          onlineBooking: onlineRegistrationsCompanyRef.current,
        };

        const query = `
          mutation UpdateCompany($id: Int!, $input: CompanyInput!) {
            updateCompany(id: $id, input: $input) {
                              meBookingModuleConfig {
                  confirmationCall
                  onlineBooking
                  id
                }
                address
                deleted
                id
                name
                paidFeaturesPaginated {
                  totalCount
                }
                phone
            }
          }
        `;

        const variables = {
          id: +currentCompanyId,
          input: updatedInput,
        };

        const payload = JSON.stringify({
          query,
          variables,
        });

        fetch(`${basePath}/graphql/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: payload,
          keepalive: true,
          credentials: 'include',
        });
      }

      if (hasBranchChanges()) {
        modifiedBranchesRef.current.forEach((branchId) => {
          const branch = branches.find(b => b.id === branchId);
          if (branch) {
            const input: BranchInput = {
              name: branch.name,
              type: branch.type.slug,
              category: branch.category.type,
              country: +branch.country?.id,
              address: branch.address,
              phones: branch.phones[0]?.phone ? [{ phone: branch.phones[0].phone }] : null,
              onlineBooking: onlineRegistrationsBranchRef.current[branch.id],
              workSchedule: branch.workSchedule,
            };

            const query = `
              mutation UpdateBranch($id: Int!, $input: BranchInput!, $restore: Boolean!) {
                updateBranch(id: $id, input: $input, restore: $restore) {
                      id
                      name
                      address
                      onlineRegistration
                      bookingQrLink
                      deleted
                }
              }
            `;

            const variables = {
              id: branch.id,
              input,
              restore: false,
            };

            const payload = JSON.stringify({
              query,
              variables,
            });

            fetch(`${basePath}/graphql/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: payload,
              keepalive: true,
              credentials: 'include',
            });
          }
        });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasCompanyChanges(), hasBranchChanges(), company, branches, currentCompanyId]);

  const toggleConfirmationCall = () => {
    setConfirmationCall((prev) => !prev);
  };

  const toggleOnlineRegistrationCompany = () => {
    setOnlineRegistrationsCompany((prev) => !prev);
  };

  const toggleOnlineRegistrationBranch = (branch: BranchProxy) => {
    setOnlineRegistrationsBranch((prev) => {
      const newStatus = !prev[branch.id];
      if (newStatus !== initialOnlineRegistrationsBranchRef.current[branch.id]) {
        modifiedBranchesRef.current.add(branch.id);
      } else {
        modifiedBranchesRef.current.delete(branch.id);
      }
      return {
        ...prev,
        [branch.id]: newStatus,
      };
    });
  };

  const handleCopyToClipboard = async (value: string) => {
    await copy(value);
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (loading) return <Spinner/>;

  return (
    <>
      <Row className='my-4 pb-2'>
        <Col lg={2} className='online-registration-сall'>
          <Form>
            <div className='d-flex align-items-center'>
              <Form.Label
                htmlFor='needsClientRecall'
                className="mb-0 fs-7 fw-bold me-4 pe-1"
                style={{minWidth: '8.438rem'}}
                onClick={handleLabelClick}
              >
                {t('Call for Confirmation')}
              </Form.Label>
              <Form.Check
                type='switch'
                id='onlineBookingSettings-needsClientRecall-field'
                checked={!!confirmationCall}
                onChange={toggleConfirmationCall}
                ref={checkRefneedsClientRecall}
                disabled={isEmployeeRole}
              />
              <Form.Label
                className="mb-0 fs-7 fw-bold"
                onClick={handleLabelClick}
              >
                <TooltipPopover
                  placement='right'
                  maxWidth='220px'
                  text={t('Call back for confirmation - enable this function so that through the online registration form, records are created with the status "Approve client", so that the administrator can call back such customers and confirm the record.')}
                >
                  <i className="bi bi-info-circle text-dark fs-16 ms-2 align-self-center"></i>
                </TooltipPopover>
              </Form.Label>
            </div>
          </Form>
        </Col>
      </Row>
      <Row className='mt-1'>

        <Col id={'table'}
             className={run && window?.innerWidth > 630 ? "table-responsive scrollbar " : "overflow-auto"}
          >
          <Row>
            <Col>
              {company ? (
                <table className="table" >
                  <thead>
                  <tr className='fs-7'>
                    <th className='py-3 col-2 ps-4 align-middle bg-violet-light'>{t('company')}</th>
                    <th className='py-3 col-1 align-middle bg-violet-light'>{t('city')}</th>
                    <th className='py-3 col-1 align-middle bg-violet-light'>{t('address')}</th>
                    <th
                      className='py-3 col-2 align-middle bg-violet-light'>{t('Online Booking')}</th>
                    <th className='py-3 col-2 align-middle bg-violet-light'>URL</th>
                    <th className='py-3 col-2 align-middle bg-violet-light'></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr className='align-middle border-bottom trClass'>
                    <td className='align-middle py-4 ps-4 tr1'>
                      {company?.name}
                    </td>
                    <td className='align-middle py-4 tr2'>
                      {company?.city?.name}
                    </td>
                    <td className='align-middle py-4 tr3'>
                      {company?.address}
                    </td>
                    <td className='align-middle py-4 online-record'>
                      <Form>
                        <Form.Check
                          type='switch'
                          id={`online-registration-${currentCompanyId}`}
                          label={onlineRegistrationsCompany ? t('allowed') : t('Forbidden')}
                          checked={!!onlineRegistrationsCompany}
                          onChange={toggleOnlineRegistrationCompany}
                          disabled={isEmployeeRole}
                        />
                      </Form>
                    </td>
                    <td className='align-middle py-4 d-flex align-items-center border-0 url-step'>
                      <Link to={`${bookingBasePath}/company/${currentCompanyId}`}
                            className='text-grey' target="_blank"
                      >
                        <span className='text-grey word-break-all'>
                          {`${bookingBasePath}/company/${currentCompanyId}`}
                        </span>
                      </Link>
                      <TooltipCustom placement='bottom' text={t('Copy')}>
                        <Button
                          variant='link'
                          className='p-0 ms-2'
                          onClick={() => handleCopyToClipboard(`${bookingBasePath}/company/${currentCompanyId}`)}
                        >
                          <i className="bi bi-stickies text-primary fs-normal"></i>
                        </Button>
                      </TooltipCustom>
                    </td>
                    <td className='align-middle py-4 pe-0 text-end'>
                      <QRCodeDownload qrCodeUrl={company?.bookingQrLink}/>
                    </td>
                  </tr>
                  </tbody>
                </table>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col>
              {!!branches?.length ? (
                <table className='table table-borderless mb-0'>
                  <thead>
                  <tr className='fs-7'>
                    <th className='py-3 col-2 ps-4 align-middle'>{t('Branch')}</th>
                    <th className='py-3 col-1 align-middle'>{t('city')}</th>
                    <th className='py-3 col-1 align-middle'>{t('address')}</th>
                    <th className='py-3 col-2 align-middle'>{t('Online Booking')}</th>
                    <th className='py-3 col-2 align-middle'>URL</th>
                    <th className='py-3 col-2 align-middle'></th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    branches?.map((branch: BranchProxy) => (
                      <tr key={branch.id} className='align-middle border-bottom'>
                        <td className='align-middle py-4 ps-4'>
                          {branch?.name}
                        </td>
                        <td className='align-middle py-4'>
                          {branch?.city?.name}
                        </td>
                        <td className='align-middle py-4'>
                          {branch?.address}
                        </td>
                        <td className={`align-middle py-4  ${ branch.id === currentBranchId ? 'online-record-branch' : ''}`}>
                          <Form>
                            <Form.Check
                              type='switch'
                              id={`online-registration-branch-${branch?.id}`}
                              label={onlineRegistrationsBranch[branch?.id] ? t('allowed') : t('Forbidden')}
                              checked={!!onlineRegistrationsBranch[branch?.id]}
                              onChange={() => toggleOnlineRegistrationBranch(branch)}
                              disabled={!onlineRegistrationsCompany || isEmployeeRole}
                            />
                          </Form>
                        </td>
                        <td className='align-middle py-4 d-flex align-items-center'>
                          <Link
                            to={`${bookingBasePath}/company/${currentCompanyId}/branch-menu-list/${branch?.id}`}
                            className='text-grey' target="_blank"
                          >
                            <span className='text-grey d-block word-break-all'>
                              {`${bookingBasePath}/company/${currentCompanyId}/branch-menu-list/${branch?.id}`}
                            </span>
                          </Link>
                          <TooltipCustom placement='bottom' text={t('Copy')}>
                            <Button
                              variant='link'
                              className='p-0 ms-2'
                              onClick={() => handleCopyToClipboard(`${bookingBasePath}/company/${currentCompanyId}/branch-menu-list/${branch?.id}`)}
                            >
                              <i className="bi bi-stickies text-primary fs-normal"></i>
                            </Button>
                          </TooltipCustom>
                        </td>
                        <td className='align-middle py-4 pe-0 text-end'>
                          <QRCodeDownload qrCodeUrl={branch?.bookingQrLink}/>
                        </td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      {
        company?.branchesPaginated?.totalCount > 20 && (
          <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
            <Pagination
              t={t}
              pages={Number(pageCount)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              firstItemIndex={firstItemIndex}
              lastItemIndex={lastItemIndex}
              totalItems={totalItems}
            />
          </div>
        )
      }
      {
        isErrorModalOpen ?
          <ErrorModalComponent
            i18n={i18next}
            onClose={() => {
              setIsErrorModalOpen(null)
            }}
            isOpen={!!isErrorModalOpen}
            currentError={isErrorModalOpen}
          /> : null
      }
      { showToast &&  <ToastComponent show={showToast} setShow={setShowToast}  text={toastText}/>}
      {run && !loading && <JoyrideWrapper run={run} stepIndex={stepIndex} setStepIndex={setStepIndex} setRun={setRun} setModalJoyride={setModalJoyride} />}
      <SuccessfulModal setModalJoyride={setModalJoyride} modalJoyride={modalJoyride}/>
    </>
  );
};

export default OnlineBookingSettingsTable;