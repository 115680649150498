import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {CategoryPickerCategory, CategoryPickerComponent} from '@me-pos/category-picker';
import i18n from '@me-team/host/src/i18n';
import {ReactSelect, Spinner} from '@services/ui-components';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {
  AddProductToTechMapFilter,
  CategoryInterface,
  Option,
} from '../types';
import {
  useGetBrandsQuery,
  useGetProductCategoriesQuery
} from '@me-team/host/main/products/graphql/products.hooks';
import ErrorService from '../../../services/ErrorService';
import {normalizeCategoryData} from '../services/normalizeCategoryData';
import transformProductCategories from '@me-team/host/src/utils/transformProductCategories';
import {ProductParametersProxy} from '@me-team/host/main/graphql/types';

interface AddProductToTechMapFilterWrapperProps {
  setFilters: React.Dispatch<React.SetStateAction<AddProductToTechMapFilter>>;
}

const AddProductToTechMapFilterWrapper: React.FC<AddProductToTechMapFilterWrapperProps> = ({setFilters}) => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const dropdownRef = useRef(null);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [currentNameFilter, setCurrentNameFilter] = useState<string>('');
  const [currentIdFilter, setCurrentIdFilter] = useState({categoryId: null, subCategoryId: null});
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<CategoryInterface[]>(null);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const {
    data: productCategoriesData,
    refetch: refetchCategories,
    loading: productCategoryLoading
  } = useGetProductCategoriesQuery({
    variables: {
      itemsPerPage: 1000
    },
    context: {
      errorType: 'local'
    },
    onCompleted: (data) => {
      const normalizedItems = normalizeCategoryData(data);

      setSelectedCategory(
        transformProductCategories(normalizedItems, null, null)
      );
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLInputElement;
      if (dropdownRef.current && !dropdownRef.current.contains(target) && target.id !== 'addProductToTechMap-categoryId-field') {
        setIsPickerOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const {data: brandsData, loading: brandsDataLoading} = useGetBrandsQuery({
    variables: {
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const brandsOptions = brandsData?.companyBrands?.map((brand: ProductParametersProxy) => {
    return {
      value: brand?.name,
      label: brand?.name
    }
  }) || [];

  const handleBrandChange = (selectedOption: Option) => {
    setSelectedBrand(selectedOption);
  };
  const handleCategoryIdChange = (categoryId: number) => {
    setCurrentIdFilter({categoryId, subCategoryId: null});
  };
  const handleSubCategoryIdChange = (categoryId: number, subCategoryId: number) => {
    setCurrentIdFilter((prevState) => ({
      ...prevState,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
    }));
  };

  const handleApplyFilter = () => {
    setFilters({nameSkuBarcode: '', categoryId: null, brand: null});
    setFilters({
      nameSkuBarcode: search ? search.trim() : '',
      categoryId: currentIdFilter?.subCategoryId ? currentIdFilter?.subCategoryId : currentIdFilter?.categoryId,
      brand: selectedBrand?.value ? selectedBrand?.value : null,
    });
    setIsPickerOpen(false);
  };

  const resetSelectedCategories = (categories: CategoryInterface[]) => {
    return categories?.map((category) => ({
      ...category,
      isPicked: false,
      isSubcategoryPiked: false,
      subcategoryList: category?.subcategoryList?.map((subcategory) => ({
        ...subcategory,
        isPicked: false,
      })),
    }));
  };

  const handleClearFilter = () => {
    setFilters({
      nameSkuBarcode: null,
      categoryId: null,
      brand: null,
    });
    setCurrentNameFilter('');
    setSearch('');
    setSelectedCategory(null);
    setSelectedBrand(null);
    setCurrentIdFilter({categoryId: null, subCategoryId: null})
    if (selectedCategory) {
      setSelectedCategory(resetSelectedCategories(selectedCategory));
    }
    setIsPickerOpen(false);
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleApplyFilter();
    }
  };

  if (productCategoryLoading) return <Spinner/>
  return (
    <>
      <Row className='gx-3'>
        <Col lg={10}>
          <Row className='gx-3 h-100'>
            <Col lg={4}>
              <div className='position-relative mb-3 mb-lg-0'>
                <input
                  name='nameSkuBarcode'
                  id="addProductToTechMap-nameSkuBarcode-field"
                  type='text'
                  className='form-control px-6 pe-4'
                  placeholder={t('Search by name, sku or barcode')}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={handleSearchKeyDown}
                  maxLength={50}
                />
                <i className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-2'></i>
              </div>
            </Col>
            <Col lg={4}>
              <div className='position-relative mb-3 mb-lg-0'>
                <input
                  type='text'
                  id='addProductToTechMap-categoryId-field'
                  className='form-control px-4 cursor-pointer'
                  placeholder={t('Category')}
                  value={currentNameFilter}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsPickerOpen((prev) => !prev);
                  }}
                  readOnly
                />
                <i
                  className={`bi bi-chevron-down position-absolute top-50 end-0 text-dark me-2 pointer-event-none transition-transform-icon ${isPickerOpen ? 'rotate-180-icon' : ''}`}
                ></i>
                <div
                  ref={dropdownRef}
                  className='dropdown position-absolute opacity-1 z-2 top-10  start-0 end-0 mt-2 bg-white shadow'
                >
                  {productCategoriesData && selectedCategory && (
                    <CategoryPickerComponent
                      isOpen={isPickerOpen}
                      locale={i18n.language}
                      onSearchChange={(searchVealue: string) => {
                      }}
                      onChangeCategory={(currentCategory: CategoryPickerCategory) => {
                        if (currentCategory.isPicked) {
                          setCurrentNameFilter(currentCategory?.name)
                          handleCategoryIdChange(currentCategory?.id);
                          setIsPickerOpen(false);
                        } else {
                          setCurrentNameFilter('')
                        }
                      }}
                      onChangeSubcategory={(currentSubcategory, currentCategory) => {
                        if (currentSubcategory?.isPicked) {
                          setCurrentNameFilter(currentSubcategory?.name)
                          handleSubCategoryIdChange(currentCategory?.id, currentSubcategory?.id);
                          setIsPickerOpen(false);
                        } else {
                          setCurrentNameFilter('')
                        }
                      }}
                      serviceCategories={selectedCategory}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <ReactSelect
                id="addProductToTechMap-brand-field"
                value={selectedBrand}
                options={brandsOptions}
                placeholder={t('Brand')}
                onChange={handleBrandChange}
                isSearchable={true}
                onKeyDown={handleSearchKeyDown}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={2}>
          <Row className='gx-3 h-100'>
            <Col lg={6} className='mb-3 mb-lg-0'>
              <Button
                type='button'
                variant='outline-dark'
                className='border-grey w-100 h-100 px-1 fw-normal'
                onClick={handleClearFilter}
              >
                {t('clear')}
              </Button>
            </Col>
            <Col lg={6} className='mb-3 mb-lg-0'>
              <Button
                variant='primary'
                className='w-100 h-100 px-1 fw-normal'
                onClick={handleApplyFilter}
              >
                {t('apply')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>

  );
};

export default AddProductToTechMapFilterWrapper;