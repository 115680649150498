import React, {useState} from 'react';
import {FiltersButton, InfoBanner, Spinner, TableHeader} from '@services/ui-components';
import {Button, Col, Row} from 'react-bootstrap';
import TechMapServicesFilterWrapper from '../TechMapServices/TechMapServicesFilterWrapper';
import TechMapServicesTable from '../TechMapServices/TechMapServicesTable';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import {
  useGetServiceQuery
} from '@me-team/host/main/technologicalMaps/graphql/technologicalMaps.hooks';
import ErrorService from '../../../services/ErrorService';
import {TechMapsFilterI} from '../types';
import {UserRole} from '../../../utils/enums';
import TechMapsListForServiceFilterWrapper from './TechMapsListForServiceFilterWrapper';
import TechMapsListForServiceTable from './TechMapsListForServiceTable';
import CreateTechnologicalMap from '../CreateTechnologicalMap/CreateTechnologicalMap';

const TechMapsListForService: React.FC= () => {
  const {t} = useTranslation();
  const {serviceId} = useParams();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const [showFilters, setShowFilters] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [showCreateTechMapModal, setShowCreateTechMapModal] = useState(false);
  const [refetchTechMaps, setRefetchTechMaps] = useState(null);

  const handleSetRefetch = (refetchTechMaps: any) => {
    setRefetchTechMaps(() => refetchTechMaps);
  };

  const {
    data: oneServiceData,
    loading: oneServiceLoading
  } = useGetServiceQuery({
    variables: {
      id: +serviceId,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const serviceName = oneServiceData?.user?.company?.services?.services?.[0]?.name;

  const initialValueFilter: TechMapsFilterI = {
    search: null,
    categoryId: null
  }

  const [filters, setFilters] = useState<TechMapsFilterI>(initialValueFilter);

  const toggleFilters = () => setShowFilters(!showFilters);
  const handleCreateTechMap = () => setShowCreateTechMapModal(prevState => !prevState);

  if (oneServiceLoading || !currentCompanyId || !userRole) return <Spinner/>;
  return (
    <div className='mb-9 px-2 px-lg-0'>
      <InfoBanner>
        <p className='fs-7 mb-0'>
          {t('Select the services for which you want to use the technological card is to be used (the products from the technological card will be offered for write-off after the service is completed).')}
        </p>
      </InfoBanner>
      <TableHeader
        title={`${t('Technological Maps Services')} ${serviceName}`}
        paths={[
          {
            path: `/services/service/${currentCompanyId}/service-list/${serviceId}/technological/maps`,
            label: t('Services')
          },
          {
            path: `/services/service/${currentCompanyId}/service-list`,
            label: t('Service list')
          },
          {
            path: `/services/service/${currentCompanyId}/service-list/${serviceId}/technological/maps`,
            label: `${t('Technological Maps Services')} ${serviceName}`
          },
        ]}
        colSize={6}
        btnColSize={6}
        buttons={
          <Row
            className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
            {
              !isEmployeeRole
                ?
                <Col
                  xs={12}
                  md={6}
                  lg={9}
                  xl={6}
                  xxl={5}
                >
                  <Button
                    variant='primary'
                    className='w-100 text-white mb-3 mb-lg-0 '
                    onClick={handleCreateTechMap}
                  >
                    <i className='bi bi-plus-lg fw-bold me-1 w-100 h-100'></i>
                    {t('Add Technological Map')}
                  </Button>
                </Col>
                : null
            }
            <Col
              xs={12}
              md={6}
              lg={3}
              xl={2}
              xxl={2}
              className='px-0'
            >
              <FiltersButton filter={filters} showFilters={showFilters} onClick={toggleFilters}
                             classes="mb-3 mb-lg-0"/>
            </Col>
          </Row>
        }
        showFilters={showFilters}
      >
        <TechMapsListForServiceFilterWrapper setFilters={setFilters}/>
      </TableHeader>
      <TechMapsListForServiceTable
        filters={filters}
        setRefetchTechMaps={handleSetRefetch}
        onShowCreateTechMapModal={handleCreateTechMap}
      />

      <CreateTechnologicalMap
        show={showCreateTechMapModal}
        onClose={handleCreateTechMap}
        refetchTechMaps={refetchTechMaps}
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default TechMapsListForService;