import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {FiltersButton, InfoBanner, Spinner, TableHeader} from '@services/ui-components';
import {Button, Col, Row} from 'react-bootstrap';
import {CategoryPickerCategory, CategoryPickerComponent} from '@me-pos/category-picker';
import {CategoryInterface} from '../../../services/ServicesCategoryInnit';
import TableServices from './TableServices';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar, currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';
import {requirementsInfoType} from "../../../interfaces/interfaces";
import RequirementsFileInfo from "../../../services/importFiles/RequirementsFileInfo";
import i18next from "i18next";
import {ImportFiles} from "../../ImportFiles/ImportFiles";
import {ErrorModalComponent} from '@me-pos/error-modal';
import ErrorService from '../../../services/ErrorService';
import {ExportBtn} from "../../ExportBtn/ExportBtn";
import {EntityType, UserRole} from "../../../utils/enums";
import i18n from '@me-team/host/src/i18n';
import transformCategories from '@me-team/host/src/utils/transformCategories';
import {useGetCategoryQuery} from '@me-team/host/main/services/graphql/services.hooks';
import JoyrideWrapper from "../../Joyride/JoyrideWrapper";
import {changeStep} from '../../Joyride/joyrideCallbackHandler';

const Services: React.FC = () => {
    const {t} = useTranslation();
    const runJoy = localStorage.getItem('run') || false;
    const step = localStorage.getItem('step') || '0';
    const {categoryId, subCategoryId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const userRole = useReactiveVar(currentUserRoleVar);
    const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
    const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
    const [showFilters, setShowFilters] = useState(false);
    const [currentNameFilter, setCurrentNameFilter] = useState({
        categoryName: '',
        subCategoryName: '',
    });

    const [currentIdFilter, setCurrentIdFilter] = useState({categoryId: null, subCategoryId: null});
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState({
        name: '',
        categoryId: +categoryId || null,
        subCategoryId: +subCategoryId || null,
        id: location?.state?.service
    });
    const [selectedCategory, setSelectedCategory] = useState<CategoryInterface[]>(null);

    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
    const [serviceRequirementsInfo, setServiceRequirementsInfo] = useState<requirementsInfoType>(null);

    const [run, setRun] = useState(runJoy);
    const [stepIndex, setStepIndex] = useState(step);
    const componentRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (currentCompanyId) {
            setServiceRequirementsInfo(RequirementsFileInfo.serviceFileRequirements(parseInt(currentCompanyId), t, i18next.language));
        }
    }, [i18next.language, currentCompanyId]);

    const toggleFilters = () => setShowFilters(!showFilters);

    const {data: categoryData, loading, error} = useGetCategoryQuery({
        variables: { companyId: +currentCompanyId },
        skip: !currentCompanyId,
        context: {
            errorType: 'local'
        },
        onCompleted: (data) => {
            setSelectedCategory(
                transformCategories(data?.user?.company?.serviceCategoriesNew)
            );
        },
        onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
    });

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
                setIsPickerOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCategoryNameChange = (categoryName: string) => {
        setCurrentNameFilter({categoryName, subCategoryName: ''});
    };

    const onOpenImportModalHandler = () => {
        setIsImportModalOpen(true);
    }
    const onCloseImportModalHandler = () => {
        setIsImportModalOpen(false);
    }

    const handleSubCategoryNameChange = (subCategoryName: string, categoryName: string = '') => {
        setCurrentNameFilter({categoryName, subCategoryName});
    };

    const handleCategoryIdChange = (categoryId: number) => {
        setCurrentIdFilter({categoryId, subCategoryId: null});
    };

    const handleSubCategoryIdChange = (categoryId: number, subCategoryId: number) => {
        setCurrentIdFilter((prevState) => ({
            ...prevState,
            categoryId: categoryId,
            subCategoryId: subCategoryId,
        }));
    };


    const handleApplyFilter = () => {
        setFilter((prev) => ({
            ...prev,
            name: '',
            categoryId: null,
            subCategoryId: null,
        }));
        setFilter((prev) => ({
            ...prev,
            categoryId: currentIdFilter?.categoryId,
            subCategoryId: currentIdFilter?.subCategoryId,
            name: search,
        }));

        setIsPickerOpen(false);
    };

    const resetSelectedCategories = (categories: CategoryInterface[]) => {
        return categories.map((category) => ({
            ...category,
            isPicked: false,
            isSubcategoryPiked: false,
            subcategoryList: category.subcategoryList.map((subcategory) => ({
                ...subcategory,
                isPicked: false,
            })),
        }));
    };


    const handleClearFilter = () => {
        setFilter((prev) => ({
            ...prev,
            name: '',
            categoryId: null,
            subCategoryId: null,
        }));
        setCurrentNameFilter({categoryName: '', subCategoryName: ''});
        setSearch('');
        if (selectedCategory) {
            setSelectedCategory(resetSelectedCategories(selectedCategory));
        }
        setIsPickerOpen(false);
    };

    const handleCreateService = () => {
        run && +stepIndex === 10 && changeStep(+stepIndex, setStepIndex, 'next');
        navigate(`/services/service/${currentCompanyId}/new`);
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleApplyFilter();
        }
    };

    if (loading || !currentCompanyId) return <Spinner/>;

    return (
        <div className='mb-9'>
            {step === '10' && <JoyrideWrapper run={run}/>}
            <Col>
                <InfoBanner>
                    <p className='fs-7 mb-0'>{t('infoBanner')} <i className='bi bi-people'></i></p>
                </InfoBanner>
            </Col>
            <TableHeader
                title={t('Service list')}
                paths={[
                    {path: `/services/service/${currentCompanyId}/service-list`, label: t('services')},
                    {path: `/services/service/${currentCompanyId}/service-list`, label: t('Service list')},
                ]}
                colSize={isEmployeeRole ? 6 : 5}
                btnColSize={isEmployeeRole ? 6 : 7}
                buttons={
                    <Row
                        className='gx-3 flex-nowrap justify-content-end mt-3 mt-lg-0'>
                        {
                            !isEmployeeRole
                                &&
                            <>
                                <Col
                                    xs={2}
                                    xl={3}
                                    xxl={2}
                                >
                                    <ExportBtn companyId={parseInt(currentCompanyId)} exportType={EntityType.SERVICES} classes="d-none d-xl-inline-block"/>
                                </Col>
                                <Col
                                    xs={2}
                                    xl={3}
                                    xxl={2}
                                >
                                    <Button
                                        variant='outline-violet'
                                        className='border-primary text-primary fw-normal d-flex align-items-center justify-content-center w-100 mb-3 mb-lg-0'
                                        onClick={onOpenImportModalHandler}>
                                        <i className='bi bi-box-arrow-in-down'></i>
                                        <span className="d-none d-xl-inline-block ms-1">{t('imports')}</span>
                                    </Button>
                                </Col>
                                <Col
                                    xs={6}
                                    xl={4}
                                    xxl={3}
                                >
                                    <Button
                                        variant='primary'
                                        className={`w-100 mb-3 mb-lg-0 text-truncate fw-bold ${stepIndex === '10' ? 'provide-services' : ''}`}

                                        onClick={handleCreateService}
                                    >
                                        <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
                                        {t('Add a service')}
                                    </Button>
                                </Col>
                            </>
                        }
                        <Col xs={!isEmployeeRole ? 2 : 5} xl={!isEmployeeRole ? 2 : 3}>
                            <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters}
                                           classesText={`${!isEmployeeRole ? "d-none d-xxl-inline-block" : "d-inline-block"}`}/>
                        </Col>
                    </Row>
                }
                showFilters={showFilters}>
                <Row>
                    <Col lg={8} xl={9}>
                        <Row>
                            <Col
                                md={6}
                                className='text-primary'>
                                <div className='position-relative mb-3 mb-lg-0'>
                                    <input
                                        id='serviceList-search-field'
                                        type='text'
                                        className='form-control pe-4'
                                        placeholder={t('Search by service name')}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        onKeyDown={handleSearchKeyDown}
                                    />
                                    <i
                                        className='bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-2'></i>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='position-relative mb-3 mb-lg-0' ref={componentRef}>
                                    <input
                                        type='text'
                                        id='serviceList-category-field'
                                        className='form-control px-4 cursor-pointer'
                                        placeholder={t('category')}
                                        ref={inputRef}
                                        value={
                                            currentNameFilter?.categoryName || currentNameFilter?.subCategoryName
                                                ? `${currentNameFilter?.categoryName}  ${currentNameFilter?.subCategoryName}`
                                                : ''
                                        }
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setIsPickerOpen((prev) => !prev);
                                        }}
                                        readOnly
                                    />
                                    <i
                                        className='bi bi-chevron-down position-absolute top-50 end-0 translate-middle-y text-dark me-2'
                                        onClick={(e) => {
                                        e.preventDefault();
                                        setIsPickerOpen(!isPickerOpen);
                                        inputRef.current?.focus();
                                    }}
                                    ></i>
                                    <div
                                        className='dropdown position-absolute opacity-1 z-2 top-10  start-0 end-0 mt-2 bg-white shadow'>
                                        {categoryData && (
                                            <CategoryPickerComponent
                                                isOpen={isPickerOpen}
                                                locale={i18n.language}
                                                onSearchChange={(searchVealue: string) => {
                                                }}
                                                onChangeCategory={(currentCategory: CategoryPickerCategory) => {
                                                    if (currentCategory.isPicked) {
                                                        handleCategoryNameChange(currentCategory?.name);
                                                        handleCategoryIdChange(currentCategory?.id);
                                                        setIsPickerOpen(false);
                                                    } else {
                                                        setCurrentNameFilter({categoryName: '', subCategoryName: ''});
                                                    }
                                                }}
                                                onChangeSubcategory={(currentSubcategory, currentCategory) => {
                                                    if (currentSubcategory.isPicked) {
                                                        handleSubCategoryNameChange(currentSubcategory?.name);
                                                        handleSubCategoryIdChange(currentCategory?.id, currentSubcategory?.id);
                                                        setIsPickerOpen(false);
                                                    } else {
                                                        setCurrentNameFilter({categoryName: '', subCategoryName: ''});
                                                    }
                                                }}
                                                serviceCategories={selectedCategory}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} xl={3}>
                        <Row className='gx-3 h-100'>
                            <Col
                                xs={6}
                                className='mb-3 mb-lg-0'>
                                <Button
                                    variant='outline-dark'
                                    type='button'
                                    className='border-grey w-100 h-100  fw-normal'
                                    onClick={handleClearFilter}>
                                    {t('clear')}
                                </Button>
                            </Col>
                            <Col
                                xs={6}
                                className='mb-3 mb-lg-0'>
                                <Button
                                    variant='primary'
                                    className='w-100 h-100 px-1'
                                    onClick={handleApplyFilter}>
                                    {t('apply')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TableHeader>
            <TableServices filter={filter}/>
            {isErrorModalOpen ?
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                /> : null
            }
            {serviceRequirementsInfo &&
                <ImportFiles currentRequirementsInfo={serviceRequirementsInfo} isOpen={isImportModalOpen}
                             onClose={onCloseImportModalHandler}/>}
        </div>
    );
};

export default Services;
