import { enUS, uk, hi, id, th, vi, ar } from 'date-fns/locale';
import moment from 'moment/moment';

export const getLocaleFromLanguage = (language: string) => {
  const customUk = {
    ...uk,
    options: { ...uk.options, weekStartsOn: 1 },
    localize: {
      ...uk.localize,
      day: (n: number) => {
        const daysShort = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
        return daysShort[n] || uk.localize.day(n);
      },
    },
  } as Locale;
  switch (language) {
    case 'en':
      return enUS;
    case 'uk':
      return customUk;
    case 'hi':
      return hi;
    case 'id':
      return id;
    case 'th':
      return th;
    case 'vi':
      return vi;
    case 'ar':
      return ar;
    default:
      return enUS;
  }
};

export const normalizeDateToStandardFormat = (date: string): string => {
  if (!date) {
    return null;
  }
  const normalizedDate = date
    .replace(/[٠-٩]/g, d => String('٠١٢٣٤٥٦٧٨٩'.indexOf(d)))
    .replace(/[०-९]/g, d => String('०१२३४५६७८९'.indexOf(d)));

  const momentDate = moment(normalizedDate, ["YYYY-MM-DD"], true);
  momentDate.locale('en');

  if (momentDate.isValid()) {
    return momentDate.format("YYYY-MM-DD");
  }
  return null;
}