import {ModuleProxy, PaidFeatureProxy, PlanPriceProxy, Tariff} from "@me-team/host/main/graphql/types";
import {
    CurrencyInfoInterface,
    DependentModulesInterface,
    DisableModuleDataInterface
} from "../../interfaces/interfaces";
import {DateTime} from "luxon";
import {ModulesTagName, SubModulesTagName} from "../../utils/enums";
import {TFunction} from "i18next";

class ModulesService {
    public static warehouseTariffInfo = (tariffList: PlanPriceProxy[],
                                         warehouseYear: string, warehouseMonth: string) => {
        return tariffList.find((tariff: PlanPriceProxy) => tariff.planName === warehouseYear || tariff.planName === warehouseMonth) || null
    }

    public static getWarehouseModulePrice = (warehouseTariff: PlanPriceProxy, currencyInfo: CurrencyInfoInterface) => {
        const price = warehouseTariff[currencyInfo?.currency?.value as keyof PlanPriceProxy];
        return `${price ? price : 'Not found'}`
    }

    public static getWarehousePaidFeatures = (paidFeatures: PaidFeatureProxy[]): PaidFeatureProxy => {
        return paidFeatures.find(
            (paidModule: PaidFeatureProxy) => paidModule.name === Tariff.WarehousesModule
        )
    }

    public static getEndDateWarehouseSubscribe = (paidFeatures: PaidFeatureProxy[]): string => {
        const warehousePaidFeatures = this.getWarehousePaidFeatures(paidFeatures);

        if (!warehousePaidFeatures || !warehousePaidFeatures.dateOfEnd) {
            return "";
        }

        return DateTime.fromSQL(warehousePaidFeatures.dateOfEnd).toFormat('dd.MM.yy');
    };

    public static getChildModule = (parentModule: ModuleProxy, childModuleToFind: SubModulesTagName) => {
        return parentModule.childModules.find((childModule: ModuleProxy) => {
            return childModule.name === childModuleToFind;
        });
    }

    public static disabledModule = (currentModule: ModuleProxy, moduleList: ModuleProxy[],): DisableModuleDataInterface => {
        const addDependent = (module: ModuleProxy): DependentModulesInterface | null =>
            module && module.active
                ? {
                    name: module.name,
                    inputForRequest: {id: module.id, isActive: false},
                }
                : null;

        const LOGBOOK_MODULE: ModuleProxy = moduleList.find((module: ModuleProxy) => module.name === ModulesTagName.LOGBOOK);
        const ONLINE_BOOKING_MODULE: ModuleProxy = moduleList.find((module: ModuleProxy) => module.name === ModulesTagName.BOOKING);
        const SERVICES_MODULE: ModuleProxy = moduleList.find((module: ModuleProxy) => module.name === ModulesTagName.SERVICES);
        const CLIENTS_MODULE: ModuleProxy = moduleList.find((module: ModuleProxy) => module.name === ModulesTagName.CLIENTS);
        const PERSONAL_MODULE: ModuleProxy = moduleList.find((module: ModuleProxy) => module.name === ModulesTagName.PERSONNEL);
        const REVIEWS_MODULE: ModuleProxy = this.getChildModule(ONLINE_BOOKING_MODULE, SubModulesTagName.REVIEWS);
        const SERVICE_PROVIDES_MODULE: ModuleProxy = this.getChildModule(SERVICES_MODULE, SubModulesTagName.SERVICE_PROVIDES);
        const VISIT_HISTORY_MODULE: ModuleProxy = this.getChildModule(CLIENTS_MODULE, SubModulesTagName.VISIT_HISTORY);
        const SALARY_MODULE: ModuleProxy = this.getChildModule(PERSONAL_MODULE, SubModulesTagName.SALARY);
        const STATISTIC_MODULE: ModuleProxy = this.getChildModule(LOGBOOK_MODULE, SubModulesTagName.STATISTIC);

        const potentialDependencies = [
            LOGBOOK_MODULE,
            ONLINE_BOOKING_MODULE,
            REVIEWS_MODULE,
            SERVICE_PROVIDES_MODULE,
            VISIT_HISTORY_MODULE,
            SALARY_MODULE,
            STATISTIC_MODULE,
        ];

        const dependentModules = potentialDependencies
            .map(addDependent)
            .filter((dependentModule: DependentModulesInterface): boolean => dependentModule !== null);

        return {
            isModalOpen: dependentModules.length > 0,
            moduleName: currentModule.name,
            moduleId: currentModule.id,
            dependentModules,
        };
    }

    public static getDescForDependModal = (
        disableModalData: DisableModuleDataInterface,
        translate: TFunction<'translation'>
    ): string => {
        const dependentModulesNames = disableModalData.dependentModules
            .map((dep) => `“${translate(dep.name)}”`)
            .join(', ');

        const fromParentModule = translate('disableTogether', { moduleName: translate(disableModalData.moduleName) });

        return `${fromParentModule} ${dependentModulesNames}. ${translate('Are you sure you want to disable them?')}`;
    };

}

export default ModulesService