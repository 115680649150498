/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetCategoriesForReportsDocument = gql`
    query GetCategoriesForReports($branchId: Int!) {
  branchProductCategories(branchId: $branchId) {
    id
    name
    comment
    deleted
    parentCategory {
      id
      name
      deleted
    }
    company {
      id
      name
      address
      phone
      website
      deleted
      bookingQrLink
      isPremiumOn
      paidFeaturesPaginated {
        totalCount
      }
    }
    childCategories {
      id
      name
      deleted
      parentCategory {
        id
        name
        deleted
      }
    }
  }
}
    `;

/**
 * __useGetCategoriesForReportsQuery__
 *
 * To run a query within a React component, call `useGetCategoriesForReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesForReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesForReportsQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useGetCategoriesForReportsQuery(baseOptions: Apollo.QueryHookOptions<GetCategoriesForReportsQuery, GetCategoriesForReportsQueryVariables> & ({ variables: GetCategoriesForReportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesForReportsQuery, GetCategoriesForReportsQueryVariables>(GetCategoriesForReportsDocument, options);
      }
export function useGetCategoriesForReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesForReportsQuery, GetCategoriesForReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesForReportsQuery, GetCategoriesForReportsQueryVariables>(GetCategoriesForReportsDocument, options);
        }
export function useGetCategoriesForReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCategoriesForReportsQuery, GetCategoriesForReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCategoriesForReportsQuery, GetCategoriesForReportsQueryVariables>(GetCategoriesForReportsDocument, options);
        }
export type GetCategoriesForReportsQueryHookResult = ReturnType<typeof useGetCategoriesForReportsQuery>;
export type GetCategoriesForReportsLazyQueryHookResult = ReturnType<typeof useGetCategoriesForReportsLazyQuery>;
export type GetCategoriesForReportsSuspenseQueryHookResult = ReturnType<typeof useGetCategoriesForReportsSuspenseQuery>;
export type GetCategoriesForReportsQueryResult = Apollo.QueryResult<GetCategoriesForReportsQuery, GetCategoriesForReportsQueryVariables>;
export const GetBrandsSkUsBarcodesStoragesForReportsDocument = gql`
    query GetBrandsSKUsBarcodesStoragesForReports($branchId: Int!) {
  branchBrands(branchId: $branchId) {
    name
  }
  branchSKUs(branchId: $branchId) {
    name
  }
  branchBarcodes(branchId: $branchId) {
    name
  }
  user {
    id
    currentBranch {
      storages {
        id
        name
        deleted
      }
    }
  }
}
    `;

/**
 * __useGetBrandsSkUsBarcodesStoragesForReportsQuery__
 *
 * To run a query within a React component, call `useGetBrandsSkUsBarcodesStoragesForReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsSkUsBarcodesStoragesForReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsSkUsBarcodesStoragesForReportsQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useGetBrandsSkUsBarcodesStoragesForReportsQuery(baseOptions: Apollo.QueryHookOptions<GetBrandsSkUsBarcodesStoragesForReportsQuery, GetBrandsSkUsBarcodesStoragesForReportsQueryVariables> & ({ variables: GetBrandsSkUsBarcodesStoragesForReportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsSkUsBarcodesStoragesForReportsQuery, GetBrandsSkUsBarcodesStoragesForReportsQueryVariables>(GetBrandsSkUsBarcodesStoragesForReportsDocument, options);
      }
export function useGetBrandsSkUsBarcodesStoragesForReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsSkUsBarcodesStoragesForReportsQuery, GetBrandsSkUsBarcodesStoragesForReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsSkUsBarcodesStoragesForReportsQuery, GetBrandsSkUsBarcodesStoragesForReportsQueryVariables>(GetBrandsSkUsBarcodesStoragesForReportsDocument, options);
        }
export function useGetBrandsSkUsBarcodesStoragesForReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBrandsSkUsBarcodesStoragesForReportsQuery, GetBrandsSkUsBarcodesStoragesForReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandsSkUsBarcodesStoragesForReportsQuery, GetBrandsSkUsBarcodesStoragesForReportsQueryVariables>(GetBrandsSkUsBarcodesStoragesForReportsDocument, options);
        }
export type GetBrandsSkUsBarcodesStoragesForReportsQueryHookResult = ReturnType<typeof useGetBrandsSkUsBarcodesStoragesForReportsQuery>;
export type GetBrandsSkUsBarcodesStoragesForReportsLazyQueryHookResult = ReturnType<typeof useGetBrandsSkUsBarcodesStoragesForReportsLazyQuery>;
export type GetBrandsSkUsBarcodesStoragesForReportsSuspenseQueryHookResult = ReturnType<typeof useGetBrandsSkUsBarcodesStoragesForReportsSuspenseQuery>;
export type GetBrandsSkUsBarcodesStoragesForReportsQueryResult = Apollo.QueryResult<GetBrandsSkUsBarcodesStoragesForReportsQuery, GetBrandsSkUsBarcodesStoragesForReportsQueryVariables>;
export const GetStorageReportsDocument = gql`
    query GetStorageReports($startDate: Date!, $endDate: Date!, $reportType: StorageReportType!, $categoryId: Int, $brand: String, $sku_barcode: String, $warehouseId: Int, $employeeId: Int, $page: Int, $itemsPerPage: Int) {
  storageReports(
    startDate: $startDate
    endDate: $endDate
    reportType: $reportType
    categoryId: $categoryId
    brand: $brand
    sku_barcode: $sku_barcode
    warehouseId: $warehouseId
    employeeId: $employeeId
    page: $page
    itemsPerPage: $itemsPerPage
  ) {
    items {
      totalArrivalQuantity
      totalWriteOffQuantity
      totalMovementQuantity
      operations {
        quantity
        type
        date
        warehouse {
          id
          name
          type {
            id
            name
          }
          deleted
          default
        }
        destinationWarehouse {
          id
          name
          deleted
          default
          type {
            id
            name
          }
        }
        employee {
          id
          name
          surname
          email
          phone
          tariffBlocking
          manualBlocking
        }
      }
      product {
        id
        name
        brand
        sku
        barcode
        productCategoryNew {
          id
          name
          deleted
          parentCategory {
            id
            name
            deleted
          }
        }
        price
        cost
        deleted
      }
    }
    totalCount
    currentPage
    itemsPerPage
    pageCount
  }
}
    `;

/**
 * __useGetStorageReportsQuery__
 *
 * To run a query within a React component, call `useGetStorageReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStorageReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStorageReportsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      reportType: // value for 'reportType'
 *      categoryId: // value for 'categoryId'
 *      brand: // value for 'brand'
 *      sku_barcode: // value for 'sku_barcode'
 *      warehouseId: // value for 'warehouseId'
 *      employeeId: // value for 'employeeId'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *   },
 * });
 */
export function useGetStorageReportsQuery(baseOptions: Apollo.QueryHookOptions<GetStorageReportsQuery, GetStorageReportsQueryVariables> & ({ variables: GetStorageReportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStorageReportsQuery, GetStorageReportsQueryVariables>(GetStorageReportsDocument, options);
      }
export function useGetStorageReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStorageReportsQuery, GetStorageReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStorageReportsQuery, GetStorageReportsQueryVariables>(GetStorageReportsDocument, options);
        }
export function useGetStorageReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStorageReportsQuery, GetStorageReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStorageReportsQuery, GetStorageReportsQueryVariables>(GetStorageReportsDocument, options);
        }
export type GetStorageReportsQueryHookResult = ReturnType<typeof useGetStorageReportsQuery>;
export type GetStorageReportsLazyQueryHookResult = ReturnType<typeof useGetStorageReportsLazyQuery>;
export type GetStorageReportsSuspenseQueryHookResult = ReturnType<typeof useGetStorageReportsSuspenseQuery>;
export type GetStorageReportsQueryResult = Apollo.QueryResult<GetStorageReportsQuery, GetStorageReportsQueryVariables>;
export type GetCategoriesForReportsQueryVariables = Types.Exact<{
  branchId: Types.Scalars['Int']['input'];
}>;


export type GetCategoriesForReportsQuery = { __typename?: 'Query', branchProductCategories?: Array<{ __typename?: 'ProductCategoryNewProxy', id: number, name: string, comment?: string | null, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null, company?: { __typename?: 'CompanyProxy', id: number, name: string, address: string, phone: string, website?: string | null, deleted: boolean, bookingQrLink: string, isPremiumOn: boolean, paidFeaturesPaginated: { __typename?: 'PaidFeaturePaginated', totalCount?: number | null } } | null, childCategories?: Array<{ __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null> | null } | null> | null };

export type GetBrandsSkUsBarcodesStoragesForReportsQueryVariables = Types.Exact<{
  branchId: Types.Scalars['Int']['input'];
}>;


export type GetBrandsSkUsBarcodesStoragesForReportsQuery = { __typename?: 'Query', branchBrands: Array<{ __typename?: 'ProductParametersProxy', name: string } | null>, branchSKUs: Array<{ __typename?: 'ProductParametersProxy', name: string } | null>, branchBarcodes: Array<{ __typename?: 'ProductParametersProxy', name: string } | null>, user?: { __typename?: 'UserProxy', id: number, currentBranch?: { __typename?: 'BranchProxy', storages?: Array<{ __typename?: 'BranchProxy', id: number, name: string, deleted: boolean }> | null } | null } | null };

export type GetStorageReportsQueryVariables = Types.Exact<{
  startDate: Types.Scalars['Date']['input'];
  endDate: Types.Scalars['Date']['input'];
  reportType: Types.StorageReportType;
  categoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  brand?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sku_barcode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  warehouseId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  employeeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetStorageReportsQuery = { __typename?: 'Query', storageReports?: { __typename?: 'StorageReportPaginated', totalCount?: number | null, currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, items?: Array<{ __typename?: 'StorageReportItemProxy', totalArrivalQuantity?: string | null, totalWriteOffQuantity?: string | null, totalMovementQuantity?: string | null, operations?: Array<{ __typename?: 'StorageReportItemOperationProxy', quantity: string, type: string, date?: string | null, warehouse?: { __typename?: 'StorageProxy', id: number, name: string, deleted: boolean, default: boolean, type?: { __typename?: 'StorageTypeProxy', id: number, name: string } | null } | null, destinationWarehouse?: { __typename?: 'StorageProxy', id: number, name: string, deleted: boolean, default: boolean, type?: { __typename?: 'StorageTypeProxy', id: number, name: string } | null } | null, employee?: { __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, email: string, phone: string, tariffBlocking: boolean, manualBlocking: boolean } | null }> | null, product?: { __typename?: 'ProductProxy', id: number, name: string, brand?: string | null, sku?: string | null, barcode?: string | null, price: string, cost: string, deleted: boolean, productCategoryNew?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ProductCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null } | null }> | null } | null };
