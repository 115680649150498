import React, {useState} from 'react';

interface TextInputWithAutoCompleteProps {
  placeholder: string;
  id: string;
  suggestions: string[];
  isDisabled?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const TextInputWithAutoComplete: React.FC<TextInputWithAutoCompleteProps> = ({
                                                                               placeholder,
                                                                               id,
                                                                               suggestions = [],
                                                                               isDisabled = false,
                                                                               value,
                                                                               onChange
                                                                             }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  const normalizeInputValue = (value: string): string => {
    return value && value.trim().replace(/\s+/g, ' ');
  };

  const handleBlur = () => {
    const normalizedValue = normalizeInputValue(value);
    const matchingSuggestion = suggestions.find(
      (suggestion) => suggestion.toLowerCase() === normalizedValue.toLowerCase()
    );

    onChange(matchingSuggestion || normalizedValue);

    setTimeout(() => {
      setShowSuggestions(false);
      setActiveSuggestionIndex(-1);
    }, 100);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.currentTarget.value;
    onChange(userInput);

    if (suggestions.length > 0) {
      const filtered = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(userInput.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(filtered.length > 0);
      setActiveSuggestionIndex(-1);
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    onChange(suggestion);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setActiveSuggestionIndex(-1);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (filteredSuggestions.length > 0) {
          setActiveSuggestionIndex((prevIndex) =>
            prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : prevIndex
          );
        }
        break;

      case 'ArrowUp':
        e.preventDefault();
        if (filteredSuggestions.length > 0) {
          setActiveSuggestionIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : 0
          );
        }
        break;

      case 'Enter':
        e.preventDefault();
        if (
          activeSuggestionIndex >= 0 &&
          activeSuggestionIndex < filteredSuggestions.length
        ) {
          handleSuggestionClick(filteredSuggestions[activeSuggestionIndex]);
        } else if (filteredSuggestions.length === 1) {
          handleSuggestionClick(filteredSuggestions[0]);
        }
        break;

      case 'Escape':
        e.preventDefault();
        setShowSuggestions(false);
        setActiveSuggestionIndex(-1);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <input
        type="text"
        id={id}
        placeholder={placeholder}
        className="form-control text-dark"
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        maxLength={50}
        disabled={isDisabled}
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="suggestions-list">
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              className={`${index === activeSuggestionIndex ? 'active' : ''}`}
              onMouseDown={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default TextInputWithAutoComplete;