import React from 'react';

interface RenderTotalQuantityProps {
  totalQuantity: string
}

const RenderTotalQuantity: React.FC<RenderTotalQuantityProps> = ({totalQuantity}) => {
  const match = totalQuantity && totalQuantity.match(/^(.+?)\s*(\(.+\))?$/);
  const beforeBrackets = match?.[1] || "";
  const inBrackets = match?.[2] || "";
  return (
    <div className='d-flex flex-column'>
      <span>
        {beforeBrackets}
      </span>
      {inBrackets && (
        <span className='fs-7 text-grey'>
          {inBrackets}
        </span>
      )}
    </div>
  );
};

export default RenderTotalQuantity;