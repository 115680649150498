import React, {useState} from 'react';
import {FiltersButton, InfoBanner, Spinner, TableHeader} from '@services/ui-components';
import {Col, Row} from 'react-bootstrap';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {currentBranchIdVar} from '@me-team/host/src/apollo/globalVar/state';
import ReportsFilterWrapper from './ReportsFilterWrapper';
import ReportsTable from './ReportsTable';
import {ReportsFilter} from './types';

const Reports: React.FC = () => {
  const {t} = useTranslation();
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const [showFilters, setShowFilters] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const initialValueFilter: ReportsFilter = {
    startDate: null,
    endDate: null,
    reportType: null,
    categoryId: null,
    skuBarcode: null,
    warehouseId: null,
    employeeId: null,
  }

  const [filters, setFilters] = useState<ReportsFilter>(initialValueFilter);

  const toggleFilters = () => setShowFilters(!showFilters);

  if (!currentBranchId) return <Spinner/>;

  return (
    <div className='mb-9 px-2 px-lg-0'>
      <InfoBanner>
        <p className='fs-7 mb-0'>
          {t('To view the report, select the time period, report type, and click Generate. To view detailed information, click “∨” in the line with the product.')}
        </p>
      </InfoBanner>
      <TableHeader
        title={t('Reports')}
        paths={[
          {
            path: `/warehouse/reports/branch/${currentBranchId}`,
            label: t('Warehouse accounting')
          },
          {
            path: `/warehouse/reports/branch/${currentBranchId}`,
            label: t('Reports')
          },
        ]}
        colSize={9}
        btnColSize={3}
        buttons={
          <Row className='gx-1 d-flex flex-lg-nowrap justify-content-lg-end'>
            <Col
              xs={12}
              md={6}
              lg={7}
              xxl={5}
            >
              <FiltersButton
                filter={filters} showFilters={showFilters}
                onClick={toggleFilters}
                classes="my-3 my-lg-0"
              />
            </Col>
          </Row>
        }
        showFilters={showFilters}
      >
        <ReportsFilterWrapper setFilters={setFilters}/>
      </TableHeader>
      <ReportsTable filters={filters}/>

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default Reports;