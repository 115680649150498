import {
  ColumnConfig,
  EmployeesOption,
  Storage,
  StorageReportTypeOption,
  StringOption,
  WarehouseOption
} from './types';
import {
  EmployeeBookingProxy,
  ProductParametersProxy,
  StorageReportItemProxy,
  StorageReportType
} from '@me-team/host/main/graphql/types';
import {TFunction} from 'i18next';

export const getEmployeesOptions = (employees?: EmployeeBookingProxy[]): EmployeesOption[] => {
  return employees?.map((employee) => ({
    value: employee.id,
    label: employee.name,
  })) || [];
};

const storageReportTypeLabels: Record<StorageReportType, string> = {
  [StorageReportType.Arrival]: 'Arrival Products',
  [StorageReportType.WriteOff]: 'Write-off Products',
  [StorageReportType.ArrivalWriteOff]: 'Arrival-write-off Products',
  [StorageReportType.Movement]: 'Movement Products',
};

export const getStorageReportTypeOptions = (t: TFunction): StorageReportTypeOption[] => {
  return Object.values(StorageReportType).map((type) => ({
    value: type,
    label: t(storageReportTypeLabels[type])
  })) || [];
};

export const getProductParametersOptions = (productParameters?: ProductParametersProxy[]): StringOption[] => {
  return productParameters?.map((productParameter) => ({
    value: productParameter.name,
    label: productParameter.name,
  })) || [];
};

export const getWarehousesOptions = (warehouses?: Storage[]): WarehouseOption[] => {
  return warehouses?.map((warehouse) => ({
    value: warehouse.id,
    label: warehouse.name,
    deleted: warehouse.deleted,
  })) || [];
};

export const mergeAndSortItems = (SKUs: ProductParametersProxy[], barcodes: ProductParametersProxy[]): string[] => {
  if (!SKUs || !barcodes) return [];
  return [...SKUs, ...barcodes]
    .map(item => item.name.trim())
    .filter((name, index, array) => array.indexOf(name) === index)
    .sort((a, b) => a.localeCompare(b));
};

const BASE_COLUMNS: ColumnConfig[] = [
  {key: "product", label: "Product", className: "col-3 ps-4"},
  {key: "brand", label: "Brand", className: "col-2"},
  {key: "category", label: "Category", className: "col-2"},
  {key: "sku", label: "SKU", className: "col-1"},
  {key: "barcode", label: "Barcode", className: "col-1"},
];

const REPORT_COLUMNS: Record<StorageReportType, ColumnConfig[]> = {
  [StorageReportType.Arrival]: [
    {key: "arrival", label: "Arrival", className: "col-2"},
  ],
  [StorageReportType.WriteOff]: [
    {key: "writeOff", label: "Write-off", className: "col-2"},
  ],
  [StorageReportType.ArrivalWriteOff]: [
    {key: "arrival", label: "Arrival", className: "col-1"},
    {key: "writeOff", label: "Write-off", className: "col-1"},
  ],
  [StorageReportType.Movement]: [
    {key: "movement", label: "Movement", className: "col-2"},
  ],
};

export const getColumns = (reportType: StorageReportType) => [
  ...BASE_COLUMNS,
  ...(REPORT_COLUMNS[reportType] || []),
];

export const getTotalQuantity = (totalQuantity: StorageReportItemProxy, reportType: StorageReportType): string => {
  switch (reportType) {
    case StorageReportType.Arrival:
      return totalQuantity.totalArrivalQuantity;
    case StorageReportType.WriteOff:
      return totalQuantity.totalWriteOffQuantity;
    case StorageReportType.ArrivalWriteOff:
      return totalQuantity.totalArrivalQuantity;
    case StorageReportType.Movement:
      return totalQuantity.totalMovementQuantity;
    default:
      return null;
  }
};

const COMMON_COLUMNS: ColumnConfig[] = [
  {key: "first", label: "", className: "col-1 px-4"},
  {key: "transactionDate", label: "Transaction Date", className: "col-2 ps-4"},
  {key: "quantity", label: "Quantity", className: "col-2"},
  {key: "warehouseType", label: "Warehouse Type", className: "col-2"},
  {key: "warehouse", label: "Warehouse", className: "col-2"},
  {key: "performer", label: "Performer", className: "col-2"},
  {key: "last", label: "", className: "col-1 px-4"},
];

export const OPERATION_COLUMNS: Record<StorageReportType, ColumnConfig[]> = {
  [StorageReportType.Arrival]: COMMON_COLUMNS,
  [StorageReportType.WriteOff]: COMMON_COLUMNS,
  [StorageReportType.ArrivalWriteOff]: [
    {key: "first", label: "", className: "col-1 px-4"},
    {key: "transactionDate", label: "Transaction Date", className: "col-2 ps-4"},
    {key: "transactionType", label: "Transaction Type", className: "col-2"},
    {key: "quantity", label: "Quantity", className: "col-1"},
    {key: "warehouseType", label: "Warehouse Type", className: "col-2"},
    {key: "warehouse", label: "Warehouse", className: "col-1"},
    {key: "performer", label: "Performer", className: "col-2"},
    {key: "last", label: "", className: "col-1 px-4"},
  ],
  [StorageReportType.Movement]: [
    {key: "transactionDate", label: "Transaction Date", className: "col-2 ps-4"},
    {key: "quantity", label: "Quantity", className: "col-1"},
    {key: "initialWarehouseType", label: "Warehouse Type", className: "col-2"},
    {key: "initialWarehouse", label: "Initial Warehouse", className: "col-1"},
    {key: "destinationWarehouseType", label: "Warehouse Type", className: "col-2"},
    {key: "destinationWarehouse", label: "Final Warehouse", className: "col-2"},
    {key: "performer", label: "Performer", className: "col-2 pe-4"},
  ],
};