import React, {useRef, useState} from 'react'
import {Button, Col, Container, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {Currency, ModuleInputType, ModuleProxy, PlanPriceProxy} from "@me-team/host/main/graphql/types";
import Form from 'react-bootstrap/Form';
import default_module_img from '@me-team/host/src/images/default_module_img.png'
import {useTranslation} from "react-i18next";
import styles from '../ModulesListStyles.module.scss'
import {ModulesTagName, PaymentModulePeriod} from "../../../../../utils/enums";
import {PaidInfoInterface} from "../../../../../interfaces/interfaces";
import ModulesService from "../../../../../services/ModulesService/ModulesService";
import {
    useGenerateManageUrlMutation,
    useGeneratePaymentUrlMutation
} from "@me-team/host/main/tariffPlans/graphql/tariffPlans.hooks";
import {useParams} from "react-router-dom";
import {TooltipPopover} from "@services/ui-components";
import CustomModal from "@services/ui-components/src/Modal/CustomModal";
import {useGetPaidFeaturesForModuleQuery} from "@me-team/host/main/Modules/graphql/modules.hooks";

type WarehousesModuleProps = {
    modulesList: ModuleProxy[],
    paidInfo: PaidInfoInterface
    updateModules: (companyId: number, updates: ModuleInputType[]) => Promise<void>;
}


const WarehousesModule: React.FC<WarehousesModuleProps> = (props) => {
    const {currentCompanyId} = useParams()
    const {t: translate, i18n} = useTranslation();
    const currentLanguage = i18n.language === 'en' ? '' : `${i18n.language}/`
    const WAREHOUSES_MODULE: ModuleProxy = props.modulesList.find((module: ModuleProxy) =>
        module.name === ModulesTagName.WAREHOUSES);
    const basePath: string = process.env.REACT_APP_POS_URL;
    const imageSRC: string = WAREHOUSES_MODULE.image ? `${basePath}/build/images/modules/${WAREHOUSES_MODULE.image}`
        : default_module_img;
    const checkBoxRef = useRef<HTMLDivElement>(null);
    const checkBoxInputRef = useRef<HTMLInputElement>(null);
    const [showCheckboxError, setShowCheckboxError] = useState<boolean>(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
    const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState<boolean>(false)
    const warehouseTariffInfo: PlanPriceProxy = ModulesService.warehouseTariffInfo(
        props.paidInfo.planPrice || [], WAREHOUSES_MODULE.yearlyTariffPlan, WAREHOUSES_MODULE.monthlyTariffPlan);

    const {data: paidFeaturesData, loading} = useGetPaidFeaturesForModuleQuery({
        variables: {companyId: Number(currentCompanyId)}
    })

    const handleLabelClick = (event: React.MouseEvent) => {
        event.preventDefault();
        checkBoxInputRef.current?.focus();
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e && setIsCheckboxChecked(e.target.checked);
        e.target.checked === true && setShowCheckboxError(false)
    };

    const onCloseSubscribeModalHandler = () => {
        setIsSubscribeModalOpen(false);
    }

    const onOpenSubscribeModalHandler = () => {
        setIsSubscribeModalOpen(true)
    }

    const [generatePaymentUrl] = useGeneratePaymentUrlMutation();
    const [generateManageUrl] = useGenerateManageUrlMutation();

    const onSubscribeHandler = async () => {
        if (!isCheckboxChecked) {
            setShowCheckboxError(true);
            checkBoxInputRef.current.focus();
            return
        }
        setShowCheckboxError(false);
        await generatePaymentUrl({
            fetchPolicy: 'network-only',
            variables: {
                companyId: parseInt(currentCompanyId),
                input: {plan: warehouseTariffInfo.planId, currency: Currency.Uah}
            },
            onCompleted: (data) => {
                onCloseSubscribeModalHandler()
                window.location.href = data.generatePaymentUrl;
            }
        })
    }

    const onCancelSubscribeHandler = async () => {
        await generateManageUrl({
            fetchPolicy: 'network-only',
            variables : {paidFeatureId : ModulesService.getWarehousePaidFeatures(paidFeaturesData.user.company.paidFeatures).id},
            onCompleted : (data) => {
                window.location.href = data.generateManageUrl;
            }
        })
    }

    const onToggleWarehouseHandler = async () => {
        const updates: ModuleInputType[] = [
            {id: WAREHOUSES_MODULE.id, isActive: !WAREHOUSES_MODULE.active}
        ];
        await props.updateModules(Number(currentCompanyId), updates);
    }

    if (loading) return null


    return (
        <>
            <div className={'border rounded p-4 mt-4'}>
                <Row>
                    <Col xl={2} xxl={2} className="mb-3 mb-xl-0">
                        <div className={!WAREHOUSES_MODULE.active ? 'opacity-75' : ""}>
                            <img className={`${styles.customImagesSize} d-block`} src={imageSRC}
                                 alt={WAREHOUSES_MODULE.name}/>
                        </div>
                    </Col>
                    <Col xl={5} xxl={6} className="mb-3 mb-xl-0">
                        <div className={!WAREHOUSES_MODULE.active ? 'opacity-75' : ""}>
                            <h5 className={'fw-normal mb-2 fs-24'}>{translate(WAREHOUSES_MODULE.name)}</h5>
                            <p className={'mb-0 text-grey'}>{WAREHOUSES_MODULE.description}</p>
                        </div>
                    </Col>
                    <Col xs={12} sm={6}  md={6} xl={2} xxl={2} className={'d-flex justify-content-start justify-content-sm-center d-xl-block mb-3 mb-sm-0'}>
                        <Form className={!WAREHOUSES_MODULE.active ? 'opacity-75' : ""}>
                            <ul className="list-unstyled">
                                {WAREHOUSES_MODULE.childModules.map((childModule: ModuleProxy, index: number) => (

                                    <li
                                        className={`d-flex align-items-center ${
                                            index === WAREHOUSES_MODULE.childModules.length - 1 ? '' : 'mb-3'
                                        }`}
                                        key={childModule.id}
                                    >
                                        <Form.Check
                                            type="switch"
                                            label={translate(childModule.name)}
                                            checked={childModule.active}
                                            disabled={!WAREHOUSES_MODULE.active}
                                            className="mb-0 custom-module-switch"
                                            onChange={() => {
                                            }}
                                        />
                                        {!WAREHOUSES_MODULE.active && (
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                    <Tooltip id={`tooltip-${childModule.id}`}>
                                                        {translate('Will become available after connecting the \"Record Journal\" module.')}
                                                    </Tooltip>
                                                }
                                            >
                                                <i className="bi bi-question-circle ms-2 text-grey cursor-pointer"></i>
                                            </OverlayTrigger>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </Form>
                    </Col>
                    <Col xs={12} sm={6}  md={6} xl={3} xxl={2} className={'d-flex flex-column justify-content-between align-items-center align-items-xl-start'}>
                        <Form className={'d-flex align-items-center'}>
                            <Form.Check
                                type="switch"
                                label={WAREHOUSES_MODULE.active ? translate('Enabled') : translate('Disabled')}
                                checked={WAREHOUSES_MODULE.active}
                                disabled={!WAREHOUSES_MODULE.isPaid}
                                className="mb-0 custom-module-switch"
                                onChange={onToggleWarehouseHandler}
                            />
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id={`tooltip-${WAREHOUSES_MODULE.id}`}>
                                        {translate('You can disable/enable the module functionality at any time without canceling your subscription.')}
                                    </Tooltip>
                                }
                            >
                                <i className="bi bi-question-circle ms-2 text-grey cursor-pointer"></i>
                            </OverlayTrigger>
                        </Form>
                        {(!WAREHOUSES_MODULE.isPaid && warehouseTariffInfo && props.paidInfo.currencyInfo) &&
                            <div className={'mt-3 mt-sm-0'}>
                                <h5 className={'mb-0 text-end'}>
                                    {ModulesService.getWarehouseModulePrice(warehouseTariffInfo, props.paidInfo.currencyInfo)}
                                    <span
                                        className={'ms-2'}>{translate(props.paidInfo.currencyInfo.currency.label)}</span>
                                    <span className={'text-grey fs-normal ms-2'}>
                            / {props.paidInfo.currencyInfo.period === PaymentModulePeriod.MONTHLY && translate('month')}
                                        {props.paidInfo.currencyInfo.period === PaymentModulePeriod.YEARLY && translate('year')}
                            </span>
                                </h5>
                                <Button variant={'primary'}
                                        className={'py-2 w-100 mt-3'}
                                        onClick={onOpenSubscribeModalHandler}>
                                    {translate('Connect Module')}
                                </Button>
                            </div>
                        }
                        {(WAREHOUSES_MODULE.isPaid && warehouseTariffInfo && props.paidInfo.currencyInfo) &&
                            <div className={'text-end mt-3 mt-sm-0'}>
                                <p className={'mb-0 text-grey'}>{translate('Next Payment')}</p>
                                <p className={'mb-0 text-grey'}>
                                    <span className={'fw-bold'}>
                                        <span>{ModulesService.getWarehousePaidFeatures(paidFeaturesData.user.company.paidFeatures).price} </span>
                                        {translate(ModulesService.getWarehousePaidFeatures(paidFeaturesData.user.company.paidFeatures).currency)} : </span>
                                        {ModulesService.getEndDateWarehouseSubscribe(paidFeaturesData.user.company.paidFeatures)}
                                </p>
                                <Button variant={'outline-primary'}
                                        className={'py-2 w-100 mt-3'}
                                        onClick={onCancelSubscribeHandler}>
                                    {translate('Cancel Subscription')}
                                </Button>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
            <CustomModal
                show={isSubscribeModalOpen}
                handleClose={onCloseSubscribeModalHandler}
                title={
                    <Container>
                        <Row>
                            <h5 className='m-0 p-0 fw-normal'>{translate('Registration of Subscription')}</h5>
                        </Row>
                        <Row>
                            <p className='fs-normal fw-normal text-grey p-0 m-0'>
                                <span className='me-1'>{translate('Warehouse Module')}</span>
                                ({props.paidInfo.currencyInfo.period === PaymentModulePeriod.MONTHLY && translate('Monthly Subscription')}
                                {props.paidInfo.currencyInfo.period === PaymentModulePeriod.YEARLY && translate('Annual Subscription')})
                            </p>
                        </Row>
                    </Container>
                }
                variantTitle='fs-5'
                body={
                    <Container>
                        <Row className='mb-3'>
                            <Col className='d-flex text-start p-0'>
                                <span className='fs-6 '>{translate('Payment information')}</span>
                            </Col>
                        </Row>
                        <Row className='fs-7 text-grey py-2'>
                            <Col xs={8} className='p-0 text-start'>
                                <span className='me-1'>{translate('Warehouse Module')}</span>
                                <span>({props.paidInfo.currencyInfo.period === PaymentModulePeriod.MONTHLY && translate('Monthly Subscription')}
                                    {props.paidInfo.currencyInfo.period === PaymentModulePeriod.YEARLY && translate('Annual Subscription')})</span>
                            </Col>
                            <Col xs={4} className='p-0 text-end'>
                <span className='text-dark'>
                  {`${ModulesService.getWarehouseModulePrice(warehouseTariffInfo, props.paidInfo.currencyInfo)} ${translate(props.paidInfo.currencyInfo.currency.value)}`}
                </span>
                            </Col>
                        </Row>
                        <Row>
                            <hr className='my-2'/>
                        </Row>
                        <Row className='fs-7 text-grey py-2 mb-4'>
                            <Col xs={8} className='p-0 text-start'>
                                <span className='me-1'>{translate('Total')}</span>
                            </Col>
                            <Col xs={4} className='p-0 text-end'>
                <span className='text-dark fs-6 fw-bold'>
                  {`${ModulesService.getWarehouseModulePrice(warehouseTariffInfo, props.paidInfo.currencyInfo)}`}
                </span>
                                <span
                                    className='text-dark fs-6 fw-medium ms-1'>{translate(props.paidInfo.currencyInfo.currency.label)}</span>
                            </Col>
                        </Row>
                        <Row ref={checkBoxRef}>
                            <TooltipPopover
                                placement="bottom"
                                text={
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-exclamation-square-fill text-orange me-2 fs-5"></i>
                                        <span className='fs-7 text-dark'>
                      {translate('Please check the box to agree to the User Agreement and Privacy Policy')}
                    </span>
                                    </div>
                                }
                                styleBdy='py-1 px-2'
                                show={showCheckboxError}
                                maxWidth='500px'
                                trigger='click'
                            >
                                <Form.Check
                                    id='tariffPlans-agreement-field'
                                    ref={checkBoxInputRef}
                                    className='text-start pe-0'
                                    required
                                    label={
                                        <Form.Check.Label htmlFor='tariffPlans-agreement-field'
                                                          onClick={handleLabelClick}>
                                            <Col className='fs-7'>
                                                <span>{translate('By clicking "To Payment", you accept')}</span>
                                                &nbsp;
                                                <a href={`${basePath}/${currentLanguage}terms-and-conditions`}
                                                   target="_blank"
                                                   className='text-decoration-none'
                                                >
                                                    <span>{translate('User Agreement')}</span>
                                                </a>
                                                &nbsp;
                                                <span>{translate('and')}<br/></span>
                                                <a href={`${basePath}/${currentLanguage}privacy-policy`}
                                                   className='text-decoration-none'
                                                   target="_blank"
                                                >
                                                    <span>{translate('Privacy Policy')}</span>
                                                </a>
                                            </Col>
                                        </Form.Check.Label>
                                    }
                                    onChange={handleCheckboxChange}
                                />
                            </TooltipPopover>
                        </Row>
                    </Container>
                }
                buttons={[
                    {
                        text: translate('Cancel'),
                        onClick: onCloseSubscribeModalHandler,
                        variant: 'outline-primary',
                        className: 'w-100 fw-normal',
                    },
                    {
                        text: translate('To Payment'),
                        onClick: onSubscribeHandler,
                        variant: 'primary',
                        className: 'w-100 fw-normal',
                    },
                ]}
            />
        </>
    )
}

export default React.memo(WarehousesModule)