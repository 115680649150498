/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetStatusListForRecordDocument = gql`
    query getStatusListForRecord {
  bookingStatus {
    id
    name
    colorCode
  }
}
    `;

/**
 * __useGetStatusListForRecordQuery__
 *
 * To run a query within a React component, call `useGetStatusListForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusListForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusListForRecordQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusListForRecordQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusListForRecordQuery, GetStatusListForRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusListForRecordQuery, GetStatusListForRecordQueryVariables>(GetStatusListForRecordDocument, options);
      }
export function useGetStatusListForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusListForRecordQuery, GetStatusListForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusListForRecordQuery, GetStatusListForRecordQueryVariables>(GetStatusListForRecordDocument, options);
        }
export function useGetStatusListForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetStatusListForRecordQuery, GetStatusListForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStatusListForRecordQuery, GetStatusListForRecordQueryVariables>(GetStatusListForRecordDocument, options);
        }
export type GetStatusListForRecordQueryHookResult = ReturnType<typeof useGetStatusListForRecordQuery>;
export type GetStatusListForRecordLazyQueryHookResult = ReturnType<typeof useGetStatusListForRecordLazyQuery>;
export type GetStatusListForRecordSuspenseQueryHookResult = ReturnType<typeof useGetStatusListForRecordSuspenseQuery>;
export type GetStatusListForRecordQueryResult = Apollo.QueryResult<GetStatusListForRecordQuery, GetStatusListForRecordQueryVariables>;
export const GetServiceCategoryForRecordDocument = gql`
    query getServiceCategoryForRecord($branchId: Int!, $dateTime: String, $employeeId: Int, $bookingUuid: Uuid) {
  serviceCategories(
    branchId: $branchId
    dateTime: $dateTime
    employeeId: $employeeId
    bookingUuid: $bookingUuid
  ) {
    id
    name
    deleted
    services(
      branchId: $branchId
      dateTime: $dateTime
      employeeId: $employeeId
      bookingUuid: $bookingUuid
    ) {
      id
      name
      deleted
      duration
      price
      onlineRegistration
      employeeServiceRelations(employeeId: $employeeId) {
        id
        deleted
        online
        price
        duration
      }
      serviceCategoryNew {
        id
        name
        deleted
        parentCategory {
          id
          name
          deleted
        }
      }
    }
  }
}
    `;

/**
 * __useGetServiceCategoryForRecordQuery__
 *
 * To run a query within a React component, call `useGetServiceCategoryForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategoryForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategoryForRecordQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      dateTime: // value for 'dateTime'
 *      employeeId: // value for 'employeeId'
 *      bookingUuid: // value for 'bookingUuid'
 *   },
 * });
 */
export function useGetServiceCategoryForRecordQuery(baseOptions: Apollo.QueryHookOptions<GetServiceCategoryForRecordQuery, GetServiceCategoryForRecordQueryVariables> & ({ variables: GetServiceCategoryForRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceCategoryForRecordQuery, GetServiceCategoryForRecordQueryVariables>(GetServiceCategoryForRecordDocument, options);
      }
export function useGetServiceCategoryForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceCategoryForRecordQuery, GetServiceCategoryForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceCategoryForRecordQuery, GetServiceCategoryForRecordQueryVariables>(GetServiceCategoryForRecordDocument, options);
        }
export function useGetServiceCategoryForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetServiceCategoryForRecordQuery, GetServiceCategoryForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServiceCategoryForRecordQuery, GetServiceCategoryForRecordQueryVariables>(GetServiceCategoryForRecordDocument, options);
        }
export type GetServiceCategoryForRecordQueryHookResult = ReturnType<typeof useGetServiceCategoryForRecordQuery>;
export type GetServiceCategoryForRecordLazyQueryHookResult = ReturnType<typeof useGetServiceCategoryForRecordLazyQuery>;
export type GetServiceCategoryForRecordSuspenseQueryHookResult = ReturnType<typeof useGetServiceCategoryForRecordSuspenseQuery>;
export type GetServiceCategoryForRecordQueryResult = Apollo.QueryResult<GetServiceCategoryForRecordQuery, GetServiceCategoryForRecordQueryVariables>;
export const GetEmployeesForRecordDocument = gql`
    query getEmployeesForRecord($branchId: ID!, $online: Boolean!, $dateTime: DateTime, $serviceId: ID, $bookingId: Uuid) {
  employees(
    branchId: $branchId
    online: $online
    dateTime: $dateTime
    serviceId: $serviceId
    bookingId: $bookingId
  ) {
    id
    name
    allowDefault
    reviewCount
    surname
    status {
      id
    }
    employeeServiceRelation {
      id
      online
      price
      deleted
      duration
    }
  }
}
    `;

/**
 * __useGetEmployeesForRecordQuery__
 *
 * To run a query within a React component, call `useGetEmployeesForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesForRecordQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      online: // value for 'online'
 *      dateTime: // value for 'dateTime'
 *      serviceId: // value for 'serviceId'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetEmployeesForRecordQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesForRecordQuery, GetEmployeesForRecordQueryVariables> & ({ variables: GetEmployeesForRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesForRecordQuery, GetEmployeesForRecordQueryVariables>(GetEmployeesForRecordDocument, options);
      }
export function useGetEmployeesForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesForRecordQuery, GetEmployeesForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesForRecordQuery, GetEmployeesForRecordQueryVariables>(GetEmployeesForRecordDocument, options);
        }
export function useGetEmployeesForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesForRecordQuery, GetEmployeesForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesForRecordQuery, GetEmployeesForRecordQueryVariables>(GetEmployeesForRecordDocument, options);
        }
export type GetEmployeesForRecordQueryHookResult = ReturnType<typeof useGetEmployeesForRecordQuery>;
export type GetEmployeesForRecordLazyQueryHookResult = ReturnType<typeof useGetEmployeesForRecordLazyQuery>;
export type GetEmployeesForRecordSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesForRecordSuspenseQuery>;
export type GetEmployeesForRecordQueryResult = Apollo.QueryResult<GetEmployeesForRecordQuery, GetEmployeesForRecordQueryVariables>;
export const GetDatesForRecordDocument = gql`
    query getDatesForRecord($branchId: ID!, $online: Boolean!, $employeeId: ID, $serviceId: ID, $bookingId: Uuid) {
  bookingDates(
    branchId: $branchId
    online: $online
    employeeId: $employeeId
    serviceId: $serviceId
    bookingId: $bookingId
  ) {
    start
    end
  }
}
    `;

/**
 * __useGetDatesForRecordQuery__
 *
 * To run a query within a React component, call `useGetDatesForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatesForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatesForRecordQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      online: // value for 'online'
 *      employeeId: // value for 'employeeId'
 *      serviceId: // value for 'serviceId'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetDatesForRecordQuery(baseOptions: Apollo.QueryHookOptions<GetDatesForRecordQuery, GetDatesForRecordQueryVariables> & ({ variables: GetDatesForRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatesForRecordQuery, GetDatesForRecordQueryVariables>(GetDatesForRecordDocument, options);
      }
export function useGetDatesForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatesForRecordQuery, GetDatesForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatesForRecordQuery, GetDatesForRecordQueryVariables>(GetDatesForRecordDocument, options);
        }
export function useGetDatesForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDatesForRecordQuery, GetDatesForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDatesForRecordQuery, GetDatesForRecordQueryVariables>(GetDatesForRecordDocument, options);
        }
export type GetDatesForRecordQueryHookResult = ReturnType<typeof useGetDatesForRecordQuery>;
export type GetDatesForRecordLazyQueryHookResult = ReturnType<typeof useGetDatesForRecordLazyQuery>;
export type GetDatesForRecordSuspenseQueryHookResult = ReturnType<typeof useGetDatesForRecordSuspenseQuery>;
export type GetDatesForRecordQueryResult = Apollo.QueryResult<GetDatesForRecordQuery, GetDatesForRecordQueryVariables>;
export const GetDatesTimesForRecordDocument = gql`
    query getDatesTimesForRecord($date: Date!, $branchId: ID!, $online: Boolean!, $employeeId: ID, $serviceId: ID, $bookingId: Uuid) {
  bookingTimes(
    date: $date
    branchId: $branchId
    online: $online
    employeeId: $employeeId
    serviceId: $serviceId
    bookingId: $bookingId
  ) {
    start
    end
  }
}
    `;

/**
 * __useGetDatesTimesForRecordQuery__
 *
 * To run a query within a React component, call `useGetDatesTimesForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDatesTimesForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatesTimesForRecordQuery({
 *   variables: {
 *      date: // value for 'date'
 *      branchId: // value for 'branchId'
 *      online: // value for 'online'
 *      employeeId: // value for 'employeeId'
 *      serviceId: // value for 'serviceId'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetDatesTimesForRecordQuery(baseOptions: Apollo.QueryHookOptions<GetDatesTimesForRecordQuery, GetDatesTimesForRecordQueryVariables> & ({ variables: GetDatesTimesForRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDatesTimesForRecordQuery, GetDatesTimesForRecordQueryVariables>(GetDatesTimesForRecordDocument, options);
      }
export function useGetDatesTimesForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDatesTimesForRecordQuery, GetDatesTimesForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDatesTimesForRecordQuery, GetDatesTimesForRecordQueryVariables>(GetDatesTimesForRecordDocument, options);
        }
export function useGetDatesTimesForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDatesTimesForRecordQuery, GetDatesTimesForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDatesTimesForRecordQuery, GetDatesTimesForRecordQueryVariables>(GetDatesTimesForRecordDocument, options);
        }
export type GetDatesTimesForRecordQueryHookResult = ReturnType<typeof useGetDatesTimesForRecordQuery>;
export type GetDatesTimesForRecordLazyQueryHookResult = ReturnType<typeof useGetDatesTimesForRecordLazyQuery>;
export type GetDatesTimesForRecordSuspenseQueryHookResult = ReturnType<typeof useGetDatesTimesForRecordSuspenseQuery>;
export type GetDatesTimesForRecordQueryResult = Apollo.QueryResult<GetDatesTimesForRecordQuery, GetDatesTimesForRecordQueryVariables>;
export const GetClientInfoForRecordDocument = gql`
    query getClientInfoForRecord($branchId: ID!, $searchEmail: String, $searchPhone: String) {
  client(
    branchId: $branchId
    searchEmail: $searchEmail
    searchPhone: $searchPhone
  ) {
    id
    name
    surname
    patronymic
    email
    phone
  }
}
    `;

/**
 * __useGetClientInfoForRecordQuery__
 *
 * To run a query within a React component, call `useGetClientInfoForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientInfoForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientInfoForRecordQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      searchEmail: // value for 'searchEmail'
 *      searchPhone: // value for 'searchPhone'
 *   },
 * });
 */
export function useGetClientInfoForRecordQuery(baseOptions: Apollo.QueryHookOptions<GetClientInfoForRecordQuery, GetClientInfoForRecordQueryVariables> & ({ variables: GetClientInfoForRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientInfoForRecordQuery, GetClientInfoForRecordQueryVariables>(GetClientInfoForRecordDocument, options);
      }
export function useGetClientInfoForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientInfoForRecordQuery, GetClientInfoForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientInfoForRecordQuery, GetClientInfoForRecordQueryVariables>(GetClientInfoForRecordDocument, options);
        }
export function useGetClientInfoForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClientInfoForRecordQuery, GetClientInfoForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClientInfoForRecordQuery, GetClientInfoForRecordQueryVariables>(GetClientInfoForRecordDocument, options);
        }
export type GetClientInfoForRecordQueryHookResult = ReturnType<typeof useGetClientInfoForRecordQuery>;
export type GetClientInfoForRecordLazyQueryHookResult = ReturnType<typeof useGetClientInfoForRecordLazyQuery>;
export type GetClientInfoForRecordSuspenseQueryHookResult = ReturnType<typeof useGetClientInfoForRecordSuspenseQuery>;
export type GetClientInfoForRecordQueryResult = Apollo.QueryResult<GetClientInfoForRecordQuery, GetClientInfoForRecordQueryVariables>;
export const GetBookingInfoDocument = gql`
    query getBookingInfo($uuid: Uuid!, $online: Boolean) {
  booking(uuid: $uuid, online: $online) {
    id
    uuid
    technologicalMaps {
      id
      name
    }
    service {
      id
      deleted
      name
      onlineRegistration
      serviceCategoryNew {
        id
        name
        deleted
      }
    }
    technologicalMaps {
      id
      name
      deleted
    }
    employee {
      id
      name
      surname
      email
      manualBlocking
      tariffBlocking
      phone
      employeeServiceRelations {
        id
        price
        deleted
        online
        duration
        service {
          id
          deleted
          name
          onlineRegistration
        }
      }
    }
    price
    date
    time
    endTime
    status {
      id
      name
    }
    client {
      id
      phone
      email
      name
      surname
      patronymic
    }
    notes
    notificationTime
    needClientRecall
  }
}
    `;

/**
 * __useGetBookingInfoQuery__
 *
 * To run a query within a React component, call `useGetBookingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingInfoQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      online: // value for 'online'
 *   },
 * });
 */
export function useGetBookingInfoQuery(baseOptions: Apollo.QueryHookOptions<GetBookingInfoQuery, GetBookingInfoQueryVariables> & ({ variables: GetBookingInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookingInfoQuery, GetBookingInfoQueryVariables>(GetBookingInfoDocument, options);
      }
export function useGetBookingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookingInfoQuery, GetBookingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookingInfoQuery, GetBookingInfoQueryVariables>(GetBookingInfoDocument, options);
        }
export function useGetBookingInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBookingInfoQuery, GetBookingInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBookingInfoQuery, GetBookingInfoQueryVariables>(GetBookingInfoDocument, options);
        }
export type GetBookingInfoQueryHookResult = ReturnType<typeof useGetBookingInfoQuery>;
export type GetBookingInfoLazyQueryHookResult = ReturnType<typeof useGetBookingInfoLazyQuery>;
export type GetBookingInfoSuspenseQueryHookResult = ReturnType<typeof useGetBookingInfoSuspenseQuery>;
export type GetBookingInfoQueryResult = Apollo.QueryResult<GetBookingInfoQuery, GetBookingInfoQueryVariables>;
export const CreateRecordDocument = gql`
    mutation createRecord($branchId: Int!, $input: BookingAdminInput!) {
  createAdminBooking(branchId: $branchId, input: $input) {
    id
    uuid
    needClientRecall
  }
}
    `;
export type CreateRecordMutationFn = Apollo.MutationFunction<CreateRecordMutation, CreateRecordMutationVariables>;

/**
 * __useCreateRecordMutation__
 *
 * To run a mutation, you first call `useCreateRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordMutation, { data, loading, error }] = useCreateRecordMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecordMutation, CreateRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecordMutation, CreateRecordMutationVariables>(CreateRecordDocument, options);
      }
export type CreateRecordMutationHookResult = ReturnType<typeof useCreateRecordMutation>;
export type CreateRecordMutationResult = Apollo.MutationResult<CreateRecordMutation>;
export type CreateRecordMutationOptions = Apollo.BaseMutationOptions<CreateRecordMutation, CreateRecordMutationVariables>;
export const UpdateRecordDocument = gql`
    mutation updateRecord($uuid: Uuid!, $input: BookingAdminInput!) {
  updateAdminBooking(uuid: $uuid, input: $input) {
    id
    uuid
    needClientRecall
  }
}
    `;
export type UpdateRecordMutationFn = Apollo.MutationFunction<UpdateRecordMutation, UpdateRecordMutationVariables>;

/**
 * __useUpdateRecordMutation__
 *
 * To run a mutation, you first call `useUpdateRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecordMutation, { data, loading, error }] = useUpdateRecordMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecordMutation, UpdateRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecordMutation, UpdateRecordMutationVariables>(UpdateRecordDocument, options);
      }
export type UpdateRecordMutationHookResult = ReturnType<typeof useUpdateRecordMutation>;
export type UpdateRecordMutationResult = Apollo.MutationResult<UpdateRecordMutation>;
export type UpdateRecordMutationOptions = Apollo.BaseMutationOptions<UpdateRecordMutation, UpdateRecordMutationVariables>;
export type GetStatusListForRecordQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStatusListForRecordQuery = { __typename?: 'bookingQuery', bookingStatus: Array<{ __typename?: 'BookingStatusProxy', id: number, name: string, colorCode?: string | null }> };

export type GetServiceCategoryForRecordQueryVariables = Types.Exact<{
  branchId: Types.Scalars['Int']['input'];
  dateTime?: Types.InputMaybe<Types.Scalars['String']['input']>;
  employeeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  bookingUuid?: Types.InputMaybe<Types.Scalars['Uuid']['input']>;
}>;


export type GetServiceCategoryForRecordQuery = { __typename?: 'bookingQuery', serviceCategories: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean, services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, deleted: boolean, duration?: string | null, price?: number | null, onlineRegistration: boolean, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number, deleted: boolean, online: boolean, price?: number | null, duration?: string | null }> | null, serviceCategoryNew?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean, parentCategory?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null }> | null }> };

export type GetEmployeesForRecordQueryVariables = Types.Exact<{
  branchId: Types.Scalars['ID']['input'];
  online: Types.Scalars['Boolean']['input'];
  dateTime?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  serviceId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bookingId?: Types.InputMaybe<Types.Scalars['Uuid']['input']>;
}>;


export type GetEmployeesForRecordQuery = { __typename?: 'bookingQuery', employees: Array<{ __typename?: 'EmployeeBookingProxy', id: number, name: string, allowDefault: boolean, reviewCount: number, surname: string, status?: { __typename?: 'EmployeeStatusProxy', id: number } | null, employeeServiceRelation?: { __typename?: 'EmployeeServiceRelationProxy', id: number, online: boolean, price?: number | null, deleted: boolean, duration?: string | null } | null }> };

export type GetDatesForRecordQueryVariables = Types.Exact<{
  branchId: Types.Scalars['ID']['input'];
  online: Types.Scalars['Boolean']['input'];
  employeeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  serviceId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bookingId?: Types.InputMaybe<Types.Scalars['Uuid']['input']>;
}>;


export type GetDatesForRecordQuery = { __typename?: 'bookingQuery', bookingDates: Array<{ __typename?: 'BookingDate', start: string, end: string }> };

export type GetDatesTimesForRecordQueryVariables = Types.Exact<{
  date: Types.Scalars['Date']['input'];
  branchId: Types.Scalars['ID']['input'];
  online: Types.Scalars['Boolean']['input'];
  employeeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  serviceId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  bookingId?: Types.InputMaybe<Types.Scalars['Uuid']['input']>;
}>;


export type GetDatesTimesForRecordQuery = { __typename?: 'bookingQuery', bookingTimes: Array<{ __typename?: 'BookingTime', start: string, end: string }> };

export type GetClientInfoForRecordQueryVariables = Types.Exact<{
  branchId: Types.Scalars['ID']['input'];
  searchEmail?: Types.InputMaybe<Types.Scalars['String']['input']>;
  searchPhone?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetClientInfoForRecordQuery = { __typename?: 'bookingQuery', client?: { __typename?: 'ClientProxy', id: number, name?: string | null, surname?: string | null, patronymic?: string | null, email?: string | null, phone?: string | null } | null };

export type GetBookingInfoQueryVariables = Types.Exact<{
  uuid: Types.Scalars['Uuid']['input'];
  online?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetBookingInfoQuery = { __typename?: 'bookingQuery', booking?: { __typename?: 'BookingMainProxy', id: number, uuid: any, price?: number | null, date?: string | null, time?: string | null, endTime?: string | null, notes?: string | null, notificationTime?: string | null, needClientRecall: boolean, technologicalMaps?: Array<{ __typename?: 'TechnologicalMapProxy', id: number, name: string, deleted: boolean }> | null, service?: { __typename?: 'ServiceProxy', id: number, deleted: boolean, name: string, onlineRegistration: boolean, serviceCategoryNew?: { __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null } | null, employee?: { __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, email: string, manualBlocking: boolean, tariffBlocking: boolean, phone: string, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number, price?: number | null, deleted: boolean, online: boolean, duration?: string | null, service?: { __typename?: 'ServiceProxy', id: number, deleted: boolean, name: string, onlineRegistration: boolean } | null }> | null } | null, status?: { __typename?: 'BookingStatusProxy', id: number, name: string } | null, client?: { __typename?: 'ClientProxy', id: number, phone?: string | null, email?: string | null, name?: string | null, surname?: string | null, patronymic?: string | null } | null } | null };

export type CreateRecordMutationVariables = Types.Exact<{
  branchId: Types.Scalars['Int']['input'];
  input: Types.BookingAdminInput;
}>;


export type CreateRecordMutation = { __typename?: 'bookingMutation', createAdminBooking: { __typename?: 'BookingMainProxy', id: number, uuid: any, needClientRecall: boolean } };

export type UpdateRecordMutationVariables = Types.Exact<{
  uuid: Types.Scalars['Uuid']['input'];
  input: Types.BookingAdminInput;
}>;


export type UpdateRecordMutation = { __typename?: 'bookingMutation', updateAdminBooking: { __typename?: 'BookingMainProxy', id: number, uuid: any, needClientRecall: boolean } };
