import React, {useCallback, useEffect, useState} from "react";
import {Pagination, Spinner} from "@services/ui-components";
import {ServiceComponent} from "./ServiceListComponent";
import {useTranslation} from "react-i18next";
import {Col} from 'react-bootstrap';
import {ServiceCategoryNewProxy} from '@me-team/host/main/graphql/types';
import {useGetServiceCategoriesQuery} from '@me-team/host/main/serviceCategories/graphql/serviceCategories.hooks';
import {Debounce} from "../../../../services/Debounce";
import SocketConnection from "../../../../services/SocketConnection";
import {ImportEntityType} from "../../../../utils/enums";
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";

type ServiceCategoriesListProps = {
    getCategoryList: (categoryList: ServiceCategoryNewProxy[]) => void
    reRender: boolean
    searchValue: string | null
    hasAccess: boolean
    toggleShowToast: (text: string) => void
}

export const ServiceCategoriesList: React.FC<ServiceCategoriesListProps> = (props) => {

    const {t: translate} = useTranslation();
    const [categoryListPerPage, setCategoryListPerPage] = useState<ServiceCategoryNewProxy[]>(null)
    const [categoryList, setCategoryList] = useState<ServiceCategoryNewProxy[]>(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [totalItems, setTotalItems] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const [isLoadingRefetch, setIsLoadingRefetch] = useState<boolean>(false)

    const {refetch: refetchCategoryListPerPage, loading : loadingCategoryListPerPage } = useGetServiceCategoriesQuery({
        variables: {
            id: null,
            itemsPerPage: itemsPerPage,
            serviceDeleted: false,
            categoryName: props.searchValue,
            page: currentPage
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setCategoryListPerPage(data.serviceCategories.items)
            setPageCount(parseInt(data.serviceCategories.pageCount || '0'))
            setTotalItems(data.serviceCategories.totalCount || 0)
        }
    })

    const {refetch: refetchCategoryList, loading : loadingCategoryList} = useGetServiceCategoriesQuery({
        variables: {id: null, itemsPerPage: 1000, serviceDeleted: false, categoryName: null, page: 1},
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            props.getCategoryList(data.serviceCategories.items)
            setCategoryList(data.serviceCategories.items)
        }
    })

    const refreshCategoryListPerPage = async () => {
        setIsLoadingRefetch(true);

        try {
            const [categoryPerPageResponse, categoryListResponse] = await Promise.all([
                refetchCategoryListPerPage(),
                refetchCategoryList()
            ]);

            setCategoryListPerPage(categoryPerPageResponse.data.serviceCategories.items);
            setPageCount(parseInt(categoryPerPageResponse.data.serviceCategories.pageCount || '0'));
            setTotalItems(categoryPerPageResponse.data.serviceCategories.totalCount || 0);

            setCategoryList(categoryListResponse.data.serviceCategories.items);
        } finally {
            setIsLoadingRefetch(false);
        }
    };

    useEffect(() => {
        refreshCategoryListPerPage();
    }, [props.reRender]);

    const debouncedRefetchCategories = useCallback(
        Debounce(() => {
            refreshCategoryListPerPage()
        }, 1000),
        [refreshCategoryListPerPage]
    );

    useEffect(() => {
        if (currentCompanyId) {
            new SocketConnection(`${process.env.REACT_APP_SOCKET}/?companyId=${currentCompanyId}`, (data: any) => {
                if (data.entity === ImportEntityType.SERVICE_CATEGORY_TYPE) {
                    debouncedRefetchCategories()
                }
            });
        }
    }, [currentCompanyId, debouncedRefetchCategories]);


    const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
    const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    if (loadingCategoryList || loadingCategoryListPerPage || isLoadingRefetch) {
        return <Spinner/>
    }

    return <>
        <div className="overflow-x-auto overflow-y-hidden scrollbar">
            {categoryListPerPage && categoryListPerPage.length !== 0 &&
                <div className={"d-flex table-header-background table-category-width fs-7 py-3 ps-4"}>
                    <Col xs={3}>
                        {translate('servicesPage.categoryOrSubcategory')}
                    </Col>
                    <Col xs={5}>
                        {translate('description')}
                    </Col>
                    <Col xs={2}>
                        {translate('servicesPage.serviceQty')}
                    </Col>
                    <Col xs={2}></Col>
                </div>
            }
            {categoryListPerPage && categoryList && (categoryListPerPage.map((serviceItem: ServiceCategoryNewProxy, index: number) => (
                <ServiceComponent key={index} category={serviceItem}
                                  categoryList={categoryList}
                                  refreshCategoryListPerPage={refreshCategoryListPerPage}
                                  hasAccess={props.hasAccess}
                                  toggleShowToast={props.toggleShowToast}
                />)))}
        </div>
        {categoryListPerPage && categoryListPerPage.length !== 0 &&
            <div className="d-flex justify-content-between align-items-center my-4">
                <Pagination
                    t={translate}
                    pages={Number(pageCount)}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    firstItemIndex={firstItemIndex}
                    lastItemIndex={lastItemIndex}
                    totalItems={totalItems}
                />
            </div>
        }
        {categoryListPerPage && categoryListPerPage.length === 0 &&
            <p className={"mb-0 mt-5 pt-5 text-center fs-4"}>{translate("NoDataFoundForTheGivenValues")}</p>
        }
    </>
}
