import { useCallback } from 'react';
import { useUpdateModuleRelationMutation } from '@me-team/host/main/Modules/graphql/modules.hooks';
import { ModuleInputType } from '@me-team/host/main/graphql/types';
import { ApolloQueryResult } from '@apollo/client';

export const useUpdateModulesWithRefetch = <T>(
    refetchData: () => Promise<ApolloQueryResult<T>>
) => {
    const [updateCompanyModuleRelation] = useUpdateModuleRelationMutation();

    const updateModules = useCallback(
        async (companyId: number, updates: ModuleInputType[]) => {
            for (const input of updates) {
                await updateCompanyModuleRelation({ variables: { companyId, input } });
            }
            await refetchData();
        },
        [updateCompanyModuleRelation, refetchData]
    );

    return updateModules;
};