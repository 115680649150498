import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment/moment';
import {createStaticRanges, DateRangePicker} from 'react-date-range';
import {Col, Row} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {useTranslation} from 'react-i18next';
import useOnClickOutside from '@me-team/common/src/hooks/useOnClickOutside';
import {getLocaleFromLanguage, normalizeDateToStandardFormat} from './constants';

interface DateRangeCalendarProps {
  name?: string,
  isErrorDateRangeFilter?: boolean,
  dateRangeFilter: any,
  setDateRangeFilter: (dateRangeFilter: any) => void,
  onError?: (error: any) => void,
  id?: string;
  buttonRef?:any,
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  disableValidation?: boolean;
  subLabel?: string;
}

const DateRangeCalendar: React.FC<DateRangeCalendarProps> = ({
                                                               name,
                                                               isErrorDateRangeFilter,
                                                               dateRangeFilter,
                                                               setDateRangeFilter,
                                                               onError,
                                                               buttonRef,
                                                               id,
                                                               setFieldValue,
                                                               setFieldTouched,
                                                               disableValidation = false,
                                                               subLabel = 'Period'
                                                             }) => {
  const {t, i18n} = useTranslation();
  moment.locale(i18n.language);
  const dateRangeRef = useRef(null)
  const [isValid, setIsValid] = useState(true);
  const [showSubLabel, setShowSubLabel] = useState(false);
  const EPOCH_TIME = moment.utc(0).locale('en').format('YYYY-MM-DD');
  const isEpochTime = !dateRangeFilter.endDate ||
    moment.utc(dateRangeFilter.endDate).locale('en').format('YYYY-MM-DD') === EPOCH_TIME;

  const formatDate = (date: any) => {
    return moment(date).format('YYYY-MM-DD')
  }
  const selectedLocale = getLocaleFromLanguage(i18n.language);

  useEffect(() => {
    if (onError && !disableValidation) {
      onError(!isValid);
    }
  }, [isValid, onError, disableValidation]);

  useEffect(() => {
    if (!disableValidation && isErrorDateRangeFilter) {
      setIsValid(false);
    }
  }, [isErrorDateRangeFilter, disableValidation]);

  const customStaticRanges = createStaticRanges([
    {
      label: t('Last 7 days'),
      range: () => ({
        startDate: moment().subtract(6, 'days').toDate(),
        endDate: new Date()
      })
    },
    {
      label: t('Last month'),
      range: () => ({
        startDate: moment().subtract(1, 'month').startOf('month').toDate(),
        endDate: moment().subtract(1, 'month').endOf('month').toDate()
      })
    }
  ]);

  const handleToggleCalendar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDateRangePickerOpened((prev) => !prev);
  };

  useEffect(() => {
    if (dateRangeFilter?.startDate && dateRangeFilter?.endDate) {
      setSelectionRange({
        startDate: new Date(dateRangeFilter.startDate),
        endDate: new Date(dateRangeFilter.endDate),
        key: 'selection'
      });
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [dateRangeFilter]);

  const initialSelectionRange = {
    startDate: new Date(dateRangeFilter?.startDate),
    endDate: new Date(dateRangeFilter?.endDate),
    key: 'selection',
  };

  const [selectionRange, setSelectionRange] = useState(initialSelectionRange);
  const [isDateRangePickerOpened, setIsDateRangePickerOpened] = useState(false);
  useOnClickOutside(dateRangeRef, () => setIsDateRangePickerOpened(false));
  const handleDateRangeChange = (ranges: any) => {
    const {selection: {startDate, endDate}} = ranges;
      setSelectionRange({
        startDate,
        endDate,
        key: 'selection',
      });
    setIsValid(true)
  };

  useEffect(() => {
    if (dateRangeFilter.endDate === EPOCH_TIME) {
      setShowSubLabel(false);
    }
  }, [dateRangeFilter.endDate]);

  const handleShowClick = () => {
    if (selectionRange.startDate && selectionRange.endDate) {
      const newDateRange = {
        ...dateRangeFilter,
        startDate: normalizeDateToStandardFormat(formatDate(selectionRange.startDate)),
        endDate: normalizeDateToStandardFormat(formatDate(selectionRange.endDate)),
      };
      setDateRangeFilter(newDateRange);
      if (!disableValidation) setIsValid(true);
      if (setFieldValue) setFieldValue('dateRangeFilter', newDateRange);
      if (setFieldTouched) setFieldTouched('dateRangeFilter', true, false);
      setShowSubLabel(true);
    } else if (!disableValidation) {
      setIsValid(false);
    }
    setIsDateRangePickerOpened(false);
  };

  const handleCancelClick = () => {
    setSelectionRange(initialSelectionRange);
    setIsValid(false);
    setIsDateRangePickerOpened(false);
  };

  return (
    <div
      className={`position-relative ${isValid || disableValidation ? 'px-3 border' : 'border-0'} h-100 border rounded-1`}>
      <div
        className={`h-100 ${!isValid && !disableValidation ? 'border border-danger rounded px-3' : ''}`}>
        <Button type="button"
                id={id}
                ref={buttonRef}
                onClick={handleToggleCalendar}
                className={`button d-flex fw-normal justify-content-between align-items-center w-100 fs-16 border-0 bg-white text-dark px-0 ${showSubLabel ? 'pt-3 pb-2' : 'py-6'} line-height-140`}
        >
          {
            isEpochTime
              ?
              <span className='text-grey fw-normal fs-7'>{t('Select Period')}</span>
              :
              `${moment(dateRangeFilter.startDate).format("DD.MM.YY")} - ${moment(dateRangeFilter.endDate).format("DD.MM.YY")}`
          }
          {!isValid && !disableValidation && (
            <i
              className="bi bi-exclamation-circle fs-20 position-absolute top-50 end-0 translate-middle-y text-danger p-3 pe-3 me-4 fw-bold"></i>
          )}
          <i className="bi bi-calendar4-week"></i>
        </Button>
        {showSubLabel ?
          <span className='fs-8 text-grey position-absolute top-0 left-0'>
          {t(subLabel)}
          </span> : null
        }
      </div>

      {isDateRangePickerOpened &&
        <div ref={dateRangeRef}
             className="bg-white position-absolute start-0 border border-1 rounded-1 py-2 z-3">
          <div>
            <DateRangePicker
              onChange={handleDateRangeChange}
              ranges={[selectionRange]}
              shownDate={new Date()}
              rangeColors={['#F5E7F8', '#B3AEF0']}
              months={2}
              direction="horizontal"
              staticRanges={customStaticRanges}
              inputRanges={[]}
              dateDisplayFormat="MMMM d, yyyy"
              className='date-range-picker-wrapper'
              showDateDisplay={false}
              maxDate={new Date()}
              locale={selectedLocale}
            />
            {selectionRange.startDate && selectionRange.endDate &&
              (selectionRange.startDate.getTime() !== 0 || selectionRange.endDate.getTime() !== 0) && (
                <div className="fs-7 text-dark d-flex justify-content-center flex-column flex-lg-row align-items-center justify-content-lg-end border-top px-3 py-2">
                  <small className="d-block">
                    {moment(selectionRange.startDate).format('MMMM D, YYYY')} - {moment(selectionRange.endDate).format('MMMM D, YYYY')}
                  </small>
                </div>
              )
            }
          </div>
          <div className="border-top p-3 pb-2">
            <Row className="justify-content-end g-3">
              <Col xs={12} lg={3}>
                <Button variant='outline-dark'
                        className="w-100 d-flex justify-content-center"
                        onClick={handleCancelClick}
                >
                  <small>{t('cancel')}</small>
                </Button>
              </Col>
              <Col xs={12} lg={3}>
                <Button
                  variant='outline-primary'
                  className="w-100 d-flex justify-content-center"
                  onClick={handleShowClick}
                >
                  <small>{t('Show')}</small>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      }
    </div>

  );
};

export default DateRangeCalendar;