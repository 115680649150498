import React, {useState} from 'react';
import {
  FiltersButton,
  InfoBanner,
  ScrollToTopBtn,
  Spinner,
  TableHeader
} from '@services/ui-components';
import {Col, Row} from 'react-bootstrap';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {AddProductToTechMapFilter} from '../types';
import {
  useGetOneTechMapQuery
} from '@me-team/host/main/technologicalMaps/graphql/technologicalMaps.hooks';
import ErrorService from '../../../services/ErrorService';
import AddProductToTechMapFilterWrapper from './AddProductToTechMapFilterWrapper';
import AddProductToTechMapTable from './AddProductToTechMapTable';
import UpdateRecoveryTechMap
  from '../../TechnologicalMaps/UpdateRecoveryTechMap/UpdateRecoveryTechMap';
import {ActionUpdateType} from '../../../utils/enums';
import {TechnologicalMapProxy} from '@me-team/host/main/graphql/types';

const AddProductToTechMap: React.FC = () => {
  const {t, i18n} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const {techMapId} = useParams();
  const [showFilters, setShowFilters] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [showUpdateRecoveryTechMapModal, setShowUpdateRecoveryTechMapModal] = useState(false);

  const {
    data: oneTechMapData,
    loading: oneTechMapLoading,
    refetch: oneTechMapRefetch
  } = useGetOneTechMapQuery({
    variables: {
      techMapId: +techMapId,
      companyId: +currentCompanyId,
    },
    skip: !currentCompanyId,
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });
  const techMap: TechnologicalMapProxy = oneTechMapData?.user?.company?.technologicalMaps?.technologicalMaps?.[0]
  const techMapName: string = techMap?.name

  const initialValueFilter: AddProductToTechMapFilter = {
    nameSkuBarcode: null,
    categoryId: null,
    brand: null,
  }

  const [filters, setFilters] = useState<AddProductToTechMapFilter>(initialValueFilter);

  const toggleFilters = () => setShowFilters(!showFilters);
  const toggleUpdateTechMapModal = () => setShowUpdateRecoveryTechMapModal(prevState => !prevState);

  if (oneTechMapLoading || !currentCompanyId) return <Spinner/>;

  return (
    <div className='mb-9 px-2 px-lg-0'>
      <InfoBanner>
        <p
          className='fs-7 mb-0'>{t('Click the “+” next to the product to add it to the technological card. To delete an item, click “-”.')}</p>
      </InfoBanner>
      <TableHeader
        title={`${t('Add New Product to')} ${techMapName}`}
        paths={[
          {
            path: `/warehouse/technological-maps/${currentCompanyId}/${techMapId}/product/add`,
            label: t('Warehouse accounting')
          },
          {
            path: `/warehouse/technological-maps/${currentCompanyId}`,
            label: t('List of Technological Maps')
          },
          {
            label: `${t('Products')} ${techMapName}`,
            onClick: toggleUpdateTechMapModal
          },
          {
            path: `/warehouse/technological-maps/${currentCompanyId}/${techMapId}/product/add`,
            label: `${t('Add New Product to')} ${techMapName}`
          },
        ]}
        colSize={9}
        btnColSize={3}
        buttons={
          <Row className='gx-1 w-100  d-flex flex-lg-nowrap justify-content-lg-end gap-3'>
            <Col
              xs={12}
              md={6}
              lg={5}
              xxl={4}
              className='px-0'
            >
              <FiltersButton
                filter={filters} showFilters={showFilters}
                onClick={toggleFilters}
                classes="my-3 my-lg-0"
              />
            </Col>
          </Row>
        }
        showFilters={showFilters}
      >
        <AddProductToTechMapFilterWrapper setFilters={setFilters}/>
      </TableHeader>
      <AddProductToTechMapTable filters={filters}/>
      <ScrollToTopBtn/>
      <UpdateRecoveryTechMap
        show={showUpdateRecoveryTechMapModal}
        onClose={toggleUpdateTechMapModal}
        currentAction={ActionUpdateType.Edit}
        currentUpdateTechMap={techMap}
        refetchTechMaps={oneTechMapRefetch}
      />

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default AddProductToTechMap;